import React from 'react';
import PropTypes from 'prop-types';

const RedirectLink = ({ children, url, className }) => (
    <a href={url} className={className}>
        {children}
    </a>
);

RedirectLink.propsType = {
    url: PropTypes.string.isRequired,
    className: PropTypes.string
};
export default RedirectLink;
