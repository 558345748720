/* Utils for shot event => point on (shot or heat) map transformation */
import sumBy from 'lodash/sumBy';
import { getBorderRx, getBorderRy } from '../../components/hockey-map/hockey-map';
import { ICE_RINK_SIZES } from '../../constants/ice-rink-settings';

/**
 * Icerink constants
 */
const ICERINK_BOUNDS = {
    bottom: 1500,
    top: -1500,
    left: -3000,
    right: 3000,
    width: 6000,
    height: 3000
};

const shotMapViewBox = { WIDTH: +ICE_RINK_SIZES.width, HEIGHT: +ICE_RINK_SIZES.height };

/**
 * @param {{x: number, y: number, inUserTeam: boolean, isShotForHome: boolean}} shot shot event
 * @param {number} width shotmap width
 * @param {number} height shotmap height
 * @param {boolean} oneSide if true then only half of icerink will be shown
 * @param personalShot {{}} personal shot data
 * @return {{x: number, y: number}} shot coordinates in pixels on shotmap
 */

export function toShotmapPoint(shot, { width, height, oneSide, personalShot }) {
    //todo: rename to iceRinkPoint
    const isShotForUserTeam = shot.isShotForHome === shot.inUserTeam;
    let { x, y } = shot;
    const inverse =
        (personalShot && !shot.isShotForHome) ||
        (!personalShot && ((oneSide && shot.isShotForHome) || (!oneSide && !isShotForUserTeam)));

    if (inverse) {
        x = -x;
        y = -y;
    }

    return {
        x: ((x - ICERINK_BOUNDS.left) * width) / ICERINK_BOUNDS.width,
        y: ((y - ICERINK_BOUNDS.top) * height) / ICERINK_BOUNDS.height
    };
}

export function fromShotmapPoint(shot, { width, height, oneSide, personalShot }) {
    //todo: rename to iceRinkPoint
    const isShotForUserTeam = shot.isShotForHome === shot.inUserTeam;
    let { x, y } = shot;
    const inverse =
        (personalShot && !shot.isShotForHome) ||
        (!personalShot && ((oneSide && shot.isShotForHome) || (!oneSide && !isShotForUserTeam)));

    x = (x * ICERINK_BOUNDS.width) / width + ICERINK_BOUNDS.left;
    y = (y * ICERINK_BOUNDS.height) / height + ICERINK_BOUNDS.top;

    if (inverse) {
        x = -x;
        y = -y;
    }

    return { x, y };
}

export const mousePositionToShotMap = ({ x, y }, svgRef) => {
    if (!svgRef) return {};
    const { left, top, width, height } = svgRef.current.getBoundingClientRect();
    const paddingLeft = parseInt(svgRef.current.style.paddingLeft);
    const paddingRight = parseInt(svgRef.current.style.paddingRight);
    const paddingTop = parseInt(svgRef.current.style.paddingTop);
    const paddingBottom = parseInt(svgRef.current.style.paddingBottom);

    return {
        x: ((x - left - paddingLeft) / (width - paddingLeft - paddingRight)) * shotMapViewBox.WIDTH,
        y: ((y - top - paddingTop) / (height - paddingTop - paddingBottom)) * shotMapViewBox.HEIGHT
    };
};

export const getVectorsSum = (...points) => ({
    x: sumBy(points, p => p.x),
    y: sumBy(points, p => p.y)
});

const getInvertedVector = ({ x, y }) => ({ x: -x, y: -y });

export const getVectorsSub = (a, b) => getVectorsSum(a, getInvertedVector(b));

const RX = getBorderRx(shotMapViewBox.WIDTH);
const RY = getBorderRy(shotMapViewBox.HEIGHT);

export const boundShotMapPosition = ({ x, y }) => {
    if ((x < RX || x > shotMapViewBox.WIDTH - RX) && (y < RY || y > shotMapViewBox.HEIGHT - RY)) {
        /* Coordinates of the circle's center of corner */
        const cx = x < RX ? RX : shotMapViewBox.WIDTH - RX;
        const cy = y < RY ? RY : shotMapViewBox.HEIGHT - RY;

        const r = Math.sqrt(Math.pow((x - cx) / RX, 2) + Math.pow((y - cy) / RY, 2));
        if (r > 1) {
            x = cx - (cx - x) / r;
            y = cy - (cy - y) / r;
        }
    }

    return {
        x: Math.min(Math.max(x, 0), shotMapViewBox.WIDTH),
        y: Math.min(Math.max(y, 0), shotMapViewBox.HEIGHT)
    };
};

export const getTranslationFromString = str => {
    const argsStr = str.slice(10, -1);
    const [x, y] = argsStr.split(',');
    return { x: +x, y: +y };
};

export const buildTranslation = v => `translate(${v.x},${v.y})`;
