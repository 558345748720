import styled from 'styled-components/macro';

import { mobileMediaCondition } from 'utils/helpers/device-utils';

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media ${mobileMediaCondition} {
        flex-direction: column;
    }
`;

export const ModelFooter = styled.div`
    display: flex;
    gap: 10px;
    justify-content: end;
`;
