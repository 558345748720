import { useEffect, useMemo, useState } from 'react';
import * as endpoints from 'utils/api/endpoints';
import * as expressions from 'utils/helpers/expression-utils';
import { httpMethods, useFetchData } from 'utils/hooks/use-fetch-data';
import { TableCellWithPercent } from '../report.styled-components';
import {
    AMOUNT_MEASURES,
    AmountFormatter,
    NUMBER_FORMATS,
    RelativeFormatter,
    TimeFormatter
} from 'components/number-formatter/number-formatter';
import isArray from 'lodash/isArray';
import { extractedCombineLines } from 'pages/game-center/analytics/skaters/helpers/compare-lines-helpers';
import { useGetExtraGameScoresOnLinesHook } from 'pages/game-center/analytics/skaters/hooks/compare-lines-hooks';
import { AWAY, HOME } from 'constants/team-status-type';
const { hundredths } = AMOUNT_MEASURES;
const { float2 } = NUMBER_FORMATS;

const periods = { 1: '1st period', 2: '2nd period', 3: '3rd period' };
const strengths = [
    { strength: 'PP', label: 'POWER PLAY' },
    { strength: 'PK', label: 'PENALTY KILL' },
    { strength: 'EV', label: 'EVEN STRENGTH' }
];
const extractTeams = (teamStats = []) => {
    const teams = { home: {}, away: {} };
    teamStats.forEach(res => {
        teams[res.teamStatus === HOME ? 'home' : 'away'] = res;
    });
    return teams;
};

export const useFetchShotsAndGoal = gameId => {
    const { processing, data: shotsAndGoals } = useFetchData({
        method: httpMethods.get,
        url: endpoints.getReportShotsAndGoalScored(gameId),
        initialCondition: !!gameId
    });

    const shotsAndGoalData = useMemo(() => {
        if (!shotsAndGoals) return [];

        const { home, away } = extractTeams(shotsAndGoals.teamStats);
        const periodLength = home?.teamShotsInfoPerPeriod?.map((_, i) => i + 1) || [];
        let homeStats = { shotAttempts: 0, shotsOnGoal: 0, goals: 0 };
        let awayStats = { shotAttempts: 0, shotsOnGoal: 0, goals: 0 };

        const processPeriod = period => {
            const homeData = home.teamShotsInfoPerPeriod?.find(el => el.period === period);
            const awayData = away.teamShotsInfoPerPeriod?.find(el => el.period === period);
            if (!homeData || !awayData) return null;

            homeStats.shotAttempts += homeData.shotsAndGoals.shotAttempts;
            homeStats.shotsOnGoal += homeData.shotsAndGoals.shotsOnGoal;
            homeStats.goals += homeData.shotsAndGoals.goals;

            awayStats.shotAttempts += awayData.shotsAndGoals.shotAttempts;
            awayStats.shotsOnGoal += awayData.shotsAndGoals.shotsOnGoal;
            awayStats.goals += awayData.shotsAndGoals.goals;

            return {
                label: periods[period] || `${period}th period`,
                home: `${homeData.shotsAndGoals.shotAttempts}/${homeData.shotsAndGoals.shotsOnGoal}/${
                    homeData.shotsAndGoals.goals
                }`,
                away: `${awayData.shotsAndGoals.shotAttempts}/${awayData.shotsAndGoals.shotsOnGoal}/${
                    awayData.shotsAndGoals.goals
                }`
            };
        };

        const periodStats = periodLength.map(processPeriod).filter(Boolean);
        const strengthStats = strengths
            .map(({ strength, label }) => {
                const homeData = home?.teamShotsInfoByStrength?.find(el => el.strength === strength);
                const awayData = away?.teamShotsInfoByStrength?.find(el => el.strength === strength);
                if (!homeData || !awayData) return null;
                return {
                    label,
                    home: `${homeData.shotsAndGoals.shotAttempts}/${homeData.shotsAndGoals.shotsOnGoal}/${
                        homeData.shotsAndGoals.goals
                    }`,
                    away: `${awayData.shotsAndGoals.shotAttempts}/${awayData.shotsAndGoals.shotsOnGoal}/${
                        awayData.shotsAndGoals.goals
                    }`
                };
            })
            .filter(Boolean);

        return [
            {
                label: 'Shots / On goal / Goals scored',
                home: `${homeStats.shotAttempts}/${homeStats.shotsOnGoal}/${homeStats.goals}`,
                away: `${awayStats.shotAttempts}/${awayStats.shotsOnGoal}/${awayStats.goals}`
            },
            ...periodStats,
            { label: '', home: '', away: '' },
            ...strengthStats,
            { label: '', home: '', away: '' },
            { label: 'Blocked shots', home: home?.blockedShots, away: away?.blockedShots },
            {
                label: 'Shots on goal from Danger Zone',
                home: home?.shotsOnGoalDangerZone,
                away: away?.shotsOnGoalDangerZone
            },
            { label: 'XG', home: home?.xg / 100, away: away?.xg / 100 }
        ];
    }, [shotsAndGoals]);

    return { processing, data: shotsAndGoalData };
};

export const useFetchSkaterAndGoalScored = (gameId, playerId) => {
    const { processing, data: skaterAndGoalScored } = useFetchData({
        method: httpMethods.get,
        url: endpoints.getReportSkaterAndGoalScored(gameId, playerId),
        initialCondition: !!gameId && !!playerId
    });

    const calculateTotal = (data, key, subKey) => data.reduce((sum, period) => sum + period[key][subKey], 0);

    const formatTime = value => (value ? <TimeFormatter value={value} timeFormat={NUMBER_FORMATS.timeMmSs} /> : '-');
    const formatRelative = (toi, value) => (
        <RelativeFormatter toi={toi}>
            <AmountFormatter measure={hundredths} value={value} format={float2} />
        </RelativeFormatter>
    );

    const shotsAndGoalData = useMemo(() => {
        if (!skaterAndGoalScored || !skaterAndGoalScored.skaterStats) return [];

        const {
            skaterShotsAndGoalsPerPeriod,
            skaterEntriesAndExitsPerPeriod,
            skaterChallengesPerPeriod,
            skaterShotsAndGoalsByStrengthAndPeriod,
            skaterChallenges,
            shotsAndGoals,
            entriesAndExits
        } = skaterAndGoalScored.skaterStats;

        const result = [
            {
                label: 'Time on Ice',
                total: formatTime(calculateTotal(skaterShotsAndGoalsPerPeriod, 'shotsAndGoals', 'onIceTime')),
                period1: formatTime(skaterShotsAndGoalsPerPeriod[0]?.shotsAndGoals.onIceTime),
                period2: formatTime(skaterShotsAndGoalsPerPeriod[1]?.shotsAndGoals.onIceTime),
                period3: formatTime(skaterShotsAndGoalsPerPeriod[2]?.shotsAndGoals.onIceTime)
            },
            {
                label: 'EVEN STRENGTH',
                total: formatTime(calculateTotal(skaterShotsAndGoalsByStrengthAndPeriod, 'metricsByEV', 'onIceTime')),
                period1: formatTime(skaterShotsAndGoalsByStrengthAndPeriod[0]?.metricsByEV.onIceTime),
                period2: formatTime(skaterShotsAndGoalsByStrengthAndPeriod[1]?.metricsByEV.onIceTime),
                period3: formatTime(skaterShotsAndGoalsByStrengthAndPeriod[2]?.metricsByEV.onIceTime)
            },
            {
                label: 'POWER PLAY',
                total: formatTime(calculateTotal(skaterShotsAndGoalsByStrengthAndPeriod, 'metricsByPP', 'onIceTime')),
                period1: formatTime(skaterShotsAndGoalsByStrengthAndPeriod[0]?.metricsByPP.onIceTime),
                period2: formatTime(skaterShotsAndGoalsByStrengthAndPeriod[1]?.metricsByPP.onIceTime),
                period3: formatTime(skaterShotsAndGoalsByStrengthAndPeriod[2]?.metricsByPP.onIceTime)
            },
            {
                label: 'PENALTY KILL',
                total: formatTime(calculateTotal(skaterShotsAndGoalsByStrengthAndPeriod, 'metricsByPK', 'onIceTime')),
                period1: formatTime(skaterShotsAndGoalsByStrengthAndPeriod[0]?.metricsByPK.onIceTime),
                period2: formatTime(skaterShotsAndGoalsByStrengthAndPeriod[1]?.metricsByPK.onIceTime),
                period3: formatTime(skaterShotsAndGoalsByStrengthAndPeriod[2]?.metricsByPK.onIceTime)
            },
            {
                label: '',
                total: '',
                period1: '',
                period2: '',
                period3: ''
            },
            {
                label: 'Shots / On Goal',
                total: (
                    <div>
                        {shotsAndGoals.shotAttempts}&nbsp;/&nbsp;{shotsAndGoals.shotsOnGoal}
                        &nbsp;&nbsp;{shotsAndGoals.shotsOnGoalPerc}%
                    </div>
                ),
                period1: (
                    <div>
                        {skaterShotsAndGoalsPerPeriod[0]?.shotsAndGoals.shotAttempts}&nbsp;/&nbsp;
                        {skaterShotsAndGoalsPerPeriod[0]?.shotsAndGoals.shotsOnGoal}&nbsp;&nbsp;
                        {skaterShotsAndGoalsPerPeriod[0]?.shotsAndGoals.shotsOnGoalPerc}%
                    </div>
                ),
                period2: (
                    <div>
                        {skaterShotsAndGoalsPerPeriod[1]?.shotsAndGoals.shotAttempts}&nbsp;/&nbsp;
                        {skaterShotsAndGoalsPerPeriod[1]?.shotsAndGoals.shotsOnGoal}&nbsp;&nbsp;
                        {skaterShotsAndGoalsPerPeriod[1]?.shotsAndGoals.shotsOnGoalPerc}%
                    </div>
                ),
                period3: (
                    <div>
                        {skaterShotsAndGoalsPerPeriod[2]?.shotsAndGoals.shotAttempts}&nbsp;/&nbsp;
                        {skaterShotsAndGoalsPerPeriod[2]?.shotsAndGoals.shotsOnGoal}&nbsp;&nbsp;
                        {skaterShotsAndGoalsPerPeriod[2]?.shotsAndGoals.shotsOnGoalPerc}%
                    </div>
                )
            },
            {
                label: 'Goals',
                total: shotsAndGoals.goals || '-',
                period1: skaterShotsAndGoalsPerPeriod[0]?.shotsAndGoals.goals || '-',
                period2: skaterShotsAndGoalsPerPeriod[1]?.shotsAndGoals.goals || '-',
                period3: skaterShotsAndGoalsPerPeriod[2]?.shotsAndGoals.goals || '-'
            },
            {
                label: 'Missed shots',
                total: shotsAndGoals.shotsMissed || '-',
                period1: skaterShotsAndGoalsPerPeriod[0]?.shotsAndGoals.shotsMissed || '-',
                period2: skaterShotsAndGoalsPerPeriod[1]?.shotsAndGoals.shotsMissed || '-',
                period3: skaterShotsAndGoalsPerPeriod[2]?.shotsAndGoals.shotsMissed || '-'
            },
            {
                label: 'Blocked shots',
                total: shotsAndGoals.shotsBlocked || '-',
                period1: skaterShotsAndGoalsPerPeriod[0]?.shotsAndGoals.shotsBlocked || '-',
                period2: skaterShotsAndGoalsPerPeriod[1]?.shotsAndGoals.shotsBlocked || '-',
                period3: skaterShotsAndGoalsPerPeriod[2]?.shotsAndGoals.shotsBlocked || '-'
            },
            {
                label: 'Shots on goal from Danger Zone',
                total: shotsAndGoals.shotsOnGoalDangerZone || '-',
                period1: skaterShotsAndGoalsPerPeriod[0]?.shotsAndGoals.shotsOnGoalDangerZone || '-',
                period2: skaterShotsAndGoalsPerPeriod[1]?.shotsAndGoals.shotsOnGoalDangerZone || '-',
                period3: skaterShotsAndGoalsPerPeriod[2]?.shotsAndGoals.shotsOnGoalDangerZone || '-'
            },
            {
                label: 'Assists',
                total: shotsAndGoals.assists || '-',
                period1: skaterShotsAndGoalsPerPeriod[0]?.shotsAndGoals.assists || '-',
                period2: skaterShotsAndGoalsPerPeriod[1]?.shotsAndGoals.assists || '-',
                period3: skaterShotsAndGoalsPerPeriod[2]?.shotsAndGoals.assists || '-'
            },
            {
                label: 'Points',
                total: shotsAndGoals.points || '-',
                period1: skaterShotsAndGoalsPerPeriod[0]?.shotsAndGoals.points || '-',
                period2: skaterShotsAndGoalsPerPeriod[1]?.shotsAndGoals.points || '-',
                period3: skaterShotsAndGoalsPerPeriod[2]?.shotsAndGoals.points || '-'
            },
            {
                label: '+/-',
                total: shotsAndGoals.plusMinus || '-',
                period1: skaterShotsAndGoalsPerPeriod[0]?.shotsAndGoals.plusMinus || '-',
                period2: skaterShotsAndGoalsPerPeriod[1]?.shotsAndGoals.plusMinus || '-',
                period3: skaterShotsAndGoalsPerPeriod[2]?.shotsAndGoals.plusMinus || '-'
            },
            {
                label: 'xG',
                total: formatRelative(shotsAndGoals.onIceTime, shotsAndGoals.xg),
                period1: formatRelative(
                    skaterShotsAndGoalsPerPeriod[0]?.shotsAndGoals.onIceTime,
                    skaterShotsAndGoalsPerPeriod[0]?.shotsAndGoals.xg
                ),
                period2: formatRelative(
                    skaterShotsAndGoalsPerPeriod[1]?.shotsAndGoals.onIceTime,
                    skaterShotsAndGoalsPerPeriod[1]?.shotsAndGoals.xg
                ),
                period3: formatRelative(
                    skaterShotsAndGoalsPerPeriod[2]?.shotsAndGoals.onIceTime,
                    skaterShotsAndGoalsPerPeriod[2]?.shotsAndGoals.xg
                )
            },
            {
                label: '',
                total: '',
                period1: '',
                period2: '',
                period3: ''
            },
            {
                label: 'Entries',
                total: entriesAndExits.carryIn + entriesAndExits.passIn + entriesAndExits.dumpIn,
                period1:
                    (skaterEntriesAndExitsPerPeriod[0]?.entriesAndExits?.carryIn || 0) +
                    (skaterEntriesAndExitsPerPeriod[0]?.entriesAndExits?.passIn || 0) +
                    (skaterEntriesAndExitsPerPeriod[0]?.entriesAndExits?.dumpIn || 0),
                period2:
                    (skaterEntriesAndExitsPerPeriod[1]?.entriesAndExits?.carryIn || 0) +
                    (skaterEntriesAndExitsPerPeriod[1]?.entriesAndExits?.passIn || 0) +
                    (skaterEntriesAndExitsPerPeriod[1]?.entriesAndExits?.dumpIn || 0),
                period3:
                    (skaterEntriesAndExitsPerPeriod[2]?.entriesAndExits?.carryIn || 0) +
                    (skaterEntriesAndExitsPerPeriod[2]?.entriesAndExits?.passIn || 0) +
                    (skaterEntriesAndExitsPerPeriod[2]?.entriesAndExits?.dumpIn || 0)
            },
            {
                label: 'Carry in / Pass / Dump in',
                total: (
                    <div>
                        {entriesAndExits.carryIn} / {entriesAndExits.passIn} / {entriesAndExits.dumpIn}
                    </div>
                ),
                period1: `${skaterEntriesAndExitsPerPeriod[0]?.entriesAndExits.carryIn ??
                    '-'} / ${skaterEntriesAndExitsPerPeriod[0]?.entriesAndExits.passIn ??
                    '-'} / ${skaterEntriesAndExitsPerPeriod[0]?.entriesAndExits.dumpIn ?? '-'}`,
                period2: `${skaterEntriesAndExitsPerPeriod[1]?.entriesAndExits.carryIn ??
                    '-'} / ${skaterEntriesAndExitsPerPeriod[1]?.entriesAndExits.passIn ??
                    '-'} / ${skaterEntriesAndExitsPerPeriod[1]?.entriesAndExits.dumpIn ?? '-'}`,
                period3: `${skaterEntriesAndExitsPerPeriod[2]?.entriesAndExits.carryIn ??
                    '-'} / ${skaterEntriesAndExitsPerPeriod[2]?.entriesAndExits.passIn ??
                    '-'} / ${skaterEntriesAndExitsPerPeriod[2]?.entriesAndExits.dumpIn ?? '-'} `
            },
            {
                label: '',
                total: '',
                period1: '',
                period2: '',
                period3: ''
            },
            {
                label: 'Exits',
                total: entriesAndExits.carryOut + entriesAndExits.passOut + entriesAndExits.dumpOut,
                period1:
                    (skaterEntriesAndExitsPerPeriod[0]?.entriesAndExits?.carryOut || 0) +
                    (skaterEntriesAndExitsPerPeriod[0]?.entriesAndExits?.passOut || 0) +
                    (skaterEntriesAndExitsPerPeriod[0]?.entriesAndExits?.dumpOut || 0),
                period2:
                    (skaterEntriesAndExitsPerPeriod[1]?.entriesAndExits?.carryOut || 0) +
                    (skaterEntriesAndExitsPerPeriod[1]?.entriesAndExits?.passOut || 0) +
                    (skaterEntriesAndExitsPerPeriod[1]?.entriesAndExits?.dumpOut || 0),
                period3:
                    (skaterEntriesAndExitsPerPeriod[2]?.entriesAndExits?.carryOut || 0) +
                    (skaterEntriesAndExitsPerPeriod[2]?.entriesAndExits?.passOut || 0) +
                    (skaterEntriesAndExitsPerPeriod[2]?.entriesAndExits?.dumpOut || 0)
            },
            {
                label: 'Carry out / Pass / Dump out',
                total: (
                    <div>
                        {entriesAndExits.carryOut} / {entriesAndExits.passOut} / {entriesAndExits.dumpOut}
                    </div>
                ),
                period1: `${skaterEntriesAndExitsPerPeriod[0]?.entriesAndExits.carryOut ??
                    '-'} / ${skaterEntriesAndExitsPerPeriod[0]?.entriesAndExits.passOut ??
                    '-'} / ${skaterEntriesAndExitsPerPeriod[0]?.entriesAndExits.dumpOut ?? '-'}`,
                period2: `${skaterEntriesAndExitsPerPeriod[1]?.entriesAndExits.carryOut ??
                    '-'} / ${skaterEntriesAndExitsPerPeriod[1]?.entriesAndExits.passOut ??
                    '-'} / ${skaterEntriesAndExitsPerPeriod[1]?.entriesAndExits.dumpOut ?? '-'} `,
                period3: `${skaterEntriesAndExitsPerPeriod[2]?.entriesAndExits.carryOut ??
                    '-'} / ${skaterEntriesAndExitsPerPeriod[2]?.entriesAndExits.passOut ??
                    '-'} / ${skaterEntriesAndExitsPerPeriod[2]?.entriesAndExits.dumpOut ?? '-'}`
            },
            {
                label: '',
                total: '',
                period1: '',
                period2: '',
                period3: ''
            },
            {
                label: 'Face-offs won',
                total: (
                    <div>
                        {skaterChallenges?.faceOffWon || skaterChallenges?.faceOffWonPerc ? (
                            <>
                                {skaterChallenges?.faceOffWon}&nbsp;&nbsp;{skaterChallenges?.faceOffWonPerc}%
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                ),
                period1: (
                    <div>
                        {skaterChallengesPerPeriod[0]?.skaterChallenges.faceOffWon ||
                        skaterChallengesPerPeriod[0]?.skaterChallenges.faceOffWonPerc ? (
                            <>
                                {skaterChallengesPerPeriod[0]?.skaterChallenges.faceOffWon}&nbsp;&nbsp;
                                {skaterChallengesPerPeriod[0]?.skaterChallenges.faceOffWonPerc}%
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                ),
                period2: (
                    <div>
                        {skaterChallengesPerPeriod[1]?.skaterChallenges.faceOffWon ||
                        skaterChallengesPerPeriod[1]?.skaterChallenges.faceOffWonPerc ? (
                            <>
                                {skaterChallengesPerPeriod[1]?.skaterChallenges.faceOffWon}&nbsp;&nbsp;
                                {skaterChallengesPerPeriod[1]?.skaterChallenges.faceOffWonPerc}%
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                ),
                period3: (
                    <div>
                        {skaterChallengesPerPeriod[2]?.skaterChallenges.faceOffWon ||
                        skaterChallengesPerPeriod[2]?.skaterChallenges.faceOffWonPerc ? (
                            <>
                                {skaterChallengesPerPeriod[2]?.skaterChallenges.faceOffWon}&nbsp;&nbsp;
                                {skaterChallengesPerPeriod[2]?.skaterChallenges.faceOffWonPerc}%
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                )
            },
            {
                label: 'Defensive zone',
                total: (
                    <div>
                        {skaterChallenges?.faceOffDzWon || skaterChallenges?.faceOffDzWonPerc ? (
                            <>
                                {skaterChallenges?.faceOffDzWon}&nbsp;&nbsp;{skaterChallenges?.faceOffDzWonPerc}%
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                ),
                period1: (
                    <div>
                        {skaterChallengesPerPeriod[0]?.skaterChallenges.faceOffDzWon ||
                        skaterChallengesPerPeriod[0]?.skaterChallenges.faceOffDzWonPerc ? (
                            <>
                                {skaterChallengesPerPeriod[0]?.skaterChallenges.faceOffDzWon}&nbsp;&nbsp;
                                {skaterChallengesPerPeriod[0]?.skaterChallenges.faceOffDzWonPerc}%
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                ),
                period2: (
                    <div>
                        {skaterChallengesPerPeriod[1]?.skaterChallenges.faceOffDzWon ||
                        skaterChallengesPerPeriod[1]?.skaterChallenges.faceOffDzWonPerc ? (
                            <>
                                {skaterChallengesPerPeriod[1]?.skaterChallenges.faceOffDzWon}&nbsp;&nbsp;
                                {skaterChallengesPerPeriod[1]?.skaterChallenges.faceOffDzWonPerc}%
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                ),
                period3: (
                    <div>
                        {skaterChallengesPerPeriod[2]?.skaterChallenges.faceOffDzWon ||
                        skaterChallengesPerPeriod[2]?.skaterChallenges.faceOffDzWonPerc ? (
                            <>
                                {skaterChallengesPerPeriod[2]?.skaterChallenges.faceOffDzWon}&nbsp;&nbsp;
                                {skaterChallengesPerPeriod[2]?.skaterChallenges.faceOffDzWonPerc}%
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                )
            },
            {
                label: 'Neutral zone',
                total: (
                    <div>
                        {skaterChallenges?.faceOffNzWon || skaterChallenges?.faceOffNzWonPerc ? (
                            <>
                                {skaterChallenges?.faceOffNzWon}&nbsp;&nbsp;{skaterChallenges?.faceOffNzWonPerc}%
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                ),
                period1: (
                    <div>
                        {skaterChallengesPerPeriod[0]?.skaterChallenges.faceOffNzWon ||
                        skaterChallengesPerPeriod[0]?.skaterChallenges.faceOffNzWonPerc ? (
                            <>
                                {skaterChallengesPerPeriod[0]?.skaterChallenges.faceOffNzWon}&nbsp;&nbsp;
                                {skaterChallengesPerPeriod[0]?.skaterChallenges.faceOffNzWonPerc}%
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                ),
                period2: (
                    <div>
                        {skaterChallengesPerPeriod[1]?.skaterChallenges.faceOffNzWon ||
                        skaterChallengesPerPeriod[1]?.skaterChallenges.faceOffNzWonPerc ? (
                            <>
                                {skaterChallengesPerPeriod[1]?.skaterChallenges.faceOffNzWon}&nbsp;&nbsp;
                                {skaterChallengesPerPeriod[1]?.skaterChallenges.faceOffNzWonPerc}%
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                ),
                period3: (
                    <div>
                        {skaterChallengesPerPeriod[2]?.skaterChallenges.faceOffNzWon ||
                        skaterChallengesPerPeriod[2]?.skaterChallenges.faceOffNzWonPerc ? (
                            <>
                                {skaterChallengesPerPeriod[2]?.skaterChallenges.faceOffNzWon}&nbsp;&nbsp;
                                {skaterChallengesPerPeriod[2]?.skaterChallenges.faceOffNzWonPerc}%
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                )
            },
            {
                label: 'Offensive zone',
                total: (
                    <div>
                        {skaterChallenges?.faceOffOzWon || skaterChallenges?.faceOffOzWonPerc ? (
                            <>
                                {skaterChallenges?.faceOffOzWon}&nbsp;&nbsp;{skaterChallenges?.faceOffOzWonPerc}%
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                ),
                period1: (
                    <div>
                        {skaterChallengesPerPeriod[0]?.skaterChallenges.faceOffOzWon ||
                        skaterChallengesPerPeriod[0]?.skaterChallenges.faceOffOzWonPerc ? (
                            <>
                                {skaterChallengesPerPeriod[0]?.skaterChallenges.faceOffOzWon}&nbsp;&nbsp;
                                {skaterChallengesPerPeriod[0]?.skaterChallenges.faceOffOzWonPerc}%
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                ),
                period2: (
                    <div>
                        {skaterChallengesPerPeriod[1]?.skaterChallenges.faceOffOzWon ||
                        skaterChallengesPerPeriod[1]?.skaterChallenges.faceOffOzWonPerc ? (
                            <>
                                {skaterChallengesPerPeriod[1]?.skaterChallenges.faceOffOzWon}&nbsp;&nbsp;
                                {skaterChallengesPerPeriod[1]?.skaterChallenges.faceOffOzWonPerc}%
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                ),
                period3: (
                    <div>
                        {skaterChallengesPerPeriod[2]?.skaterChallenges.faceOffOzWon ||
                        skaterChallengesPerPeriod[2]?.skaterChallenges.faceOffOzWonPerc ? (
                            <>
                                {skaterChallengesPerPeriod[2]?.skaterChallenges.faceOffOzWon}&nbsp;&nbsp;
                                {skaterChallengesPerPeriod[2]?.skaterChallenges.faceOffOzWonPerc}%
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                )
            },
            {
                label: '',
                total: '',
                period1: '',
                period2: '',
                period3: ''
            },
            {
                label: 'Hits taken',
                total: skaterChallenges?.hitsTaken || '-',
                period1: skaterChallengesPerPeriod[0]?.skaterChallenges.hitsTaken || '-',
                period2: skaterChallengesPerPeriod[1]?.skaterChallenges.hitsTaken || '-',
                period3: skaterChallengesPerPeriod[2]?.skaterChallenges.hitsTaken || '-'
            },
            {
                label: 'Hits Drawn',
                total: skaterChallenges?.hitsDrawn || '-',
                period1: skaterChallengesPerPeriod[0]?.skaterChallenges.hitsDrawn || '-',
                period2: skaterChallengesPerPeriod[1]?.skaterChallenges.hitsDrawn || '-',
                period3: skaterChallengesPerPeriod[2]?.skaterChallenges.hitsDrawn || '-'
            },
            {
                label: 'Shots blocked',
                total: shotsAndGoals?.shotsBlocked || '-',
                period1: skaterShotsAndGoalsPerPeriod[0]?.shotsAndGoals.shotsBlocked || '-',
                period2: skaterShotsAndGoalsPerPeriod[1]?.shotsAndGoals.shotsBlocked || '-',
                period3: skaterShotsAndGoalsPerPeriod[2]?.shotsAndGoals.shotsBlocked || '-'
            }
        ];

        return result;
    }, [skaterAndGoalScored]);

    return { processing, data: shotsAndGoalData, rawData: skaterAndGoalScored };
};

export const useFetchFaceOffPeriodAndStrength = gameId => {
    const { processing, data: faceOffPeriodAndStrength } = useFetchData({
        method: httpMethods.get,
        url: endpoints.getReportFaceOffByPeriodAndStrength(gameId),

        initialCondition: !!gameId
    });

    const faceOffPeriodAndStrengthData = useMemo(() => {
        const rawData = [];
        if (!faceOffPeriodAndStrength?.teamStats) {
            return rawData;
        }
        let home = {};
        let away = {};
        faceOffPeriodAndStrength?.teamStats?.forEach(res => {
            if (res.teamStatus === HOME) {
                home = res;
            } else {
                away = res;
            }
        });

        const periodLength = [];
        home?.teamFaceOffPerPeriod?.forEach((el, key) => {
            periodLength.push(key + 1);
        });

        rawData.push({
            label: `Face-offs won`,
            home: (
                <TableCellWithPercent>
                    <div>{home?.faceOffAllWon?.faceOffWonCount}</div>
                    <div>{home?.faceOffAllWon?.faceOffWonPerc}%</div>
                </TableCellWithPercent>
            ),
            away: (
                <TableCellWithPercent>
                    <div>{away?.faceOffAllWon?.faceOffWonCount}</div>
                    <div>{away?.faceOffAllWon?.faceOffWonPerc}%</div>
                </TableCellWithPercent>
            )
        });

        periodLength.forEach(element => {
            const homeTeamData = home?.teamFaceOffPerPeriod?.find(el => el.period === element);
            const awayTeamData = away?.teamFaceOffPerPeriod?.find(el => el.period === element);
            if (homeTeamData && awayTeamData) {
                rawData.push({
                    label: periods?.[element] ?? `${element}th period`,
                    home: (
                        <TableCellWithPercent>
                            <div>{homeTeamData.faceOffWon.faceOffWonCount}</div>
                            <div>{homeTeamData.faceOffWon.faceOffWonPerc}%</div>
                        </TableCellWithPercent>
                    ),
                    away: (
                        <TableCellWithPercent>
                            <div>{awayTeamData.faceOffWon.faceOffWonCount}</div>
                            <div>{awayTeamData.faceOffWon.faceOffWonPerc}%</div>
                        </TableCellWithPercent>
                    )
                });
            }
        });
        rawData.push({
            label: '',
            home: ``,
            away: ``
        });
        strengths.forEach(element => {
            const homeTeamData = home?.teamFaceOffByStrength?.find(el => el.strength === element.strength);
            const awayTeamData = away?.teamFaceOffByStrength?.find(el => el.strength === element.strength);
            if (homeTeamData && awayTeamData) {
                rawData.push({
                    label: element.label,
                    home: (
                        <TableCellWithPercent>
                            <div>{homeTeamData.faceOffWon.faceOffWonCount}</div>
                            <div>{homeTeamData.faceOffWon.faceOffWonPerc}%</div>
                        </TableCellWithPercent>
                    ),
                    away: (
                        <TableCellWithPercent>
                            <div>{awayTeamData.faceOffWon.faceOffWonCount}</div>
                            <div>{awayTeamData.faceOffWon.faceOffWonPerc}%</div>
                        </TableCellWithPercent>
                    )
                });
            }
        });

        rawData.push({
            label: '',
            home: '',
            away: ''
        });
        rawData.push({
            label: 'Defensive zone',
            home: (
                <TableCellWithPercent>
                    <div>{home?.faceOffDzWonCount}</div> <div>{home?.faceOffDzWonPerc}%</div>
                </TableCellWithPercent>
            ),
            away: (
                <TableCellWithPercent>
                    <div>{away?.faceOffDzWonCount}</div> <div>{away?.faceOffDzWonPerc}%</div>
                </TableCellWithPercent>
            )
        });
        rawData.push({
            label: 'Neutral zone',
            home: (
                <TableCellWithPercent>
                    <div>{home?.faceOffNzWonCount}</div> <div>{home?.faceOffNzWonPerc}%</div>
                </TableCellWithPercent>
            ),
            away: (
                <TableCellWithPercent>
                    <div>{away?.faceOffNzWonCount}</div> <div>{away?.faceOffNzWonPerc}%</div>
                </TableCellWithPercent>
            )
        });
        rawData.push({
            label: 'Offensive zone',
            home: (
                <TableCellWithPercent>
                    <div>{home?.faceOffOzWonCount}</div> <div>{home?.faceOffOzWonPerc}%</div>
                </TableCellWithPercent>
            ),
            away: (
                <TableCellWithPercent>
                    <div>{away?.faceOffOzWonCount}</div> <div>{away?.faceOffOzWonPerc}%</div>
                </TableCellWithPercent>
            )
        });

        return rawData;
    }, [faceOffPeriodAndStrength]);

    return { processing, data: faceOffPeriodAndStrengthData };
};

export const useFetchLeaderStats = (gameId, playersProfile) => {
    const { processing, data: leaderStats } = useFetchData({
        method: httpMethods.get,
        url: endpoints.getReportLeaderStats(gameId),
        initialCondition: !!gameId
    });

    const relevantKeys = useMemo(
        () => [
            { key: 'goals', label: 'GOALS' },
            { key: 'assists', label: 'ASSISTS' },
            { key: 'points', label: 'POINTS' },
            { key: 'shotsOnGoal', label: 'SHOTS ON GOAL' },
            { key: 'plusMinus', label: 'PLUS/MINUS' },
            { key: 'timeOnIce', label: 'TIME ON ICE' },
            { key: 'faceOffWon', label: 'FACE-OFF WON' },
            { key: 'hitsDrawn', label: 'HIT DRAWN' },
            { key: 'hitsTaken', label: 'HIT TAKEN' }
        ],
        []
    );

    const leaderStatsTableData = useMemo(() => {
        if (!leaderStats) return [];

        // Function to get player stats
        const getValuesForKey = (teamMetrics, teamId, key) => {
            if (!isArray(teamMetrics[key])) return [];

            // Create lookup maps for better performance
            const percDataMap = {
                faceOffWon: Object.fromEntries(teamMetrics.faceOffWonPerc?.map(el => [el.playerId, el.count]) || []),
                shotsOnGoal: Object.fromEntries(teamMetrics.shotsOnGoalPerc?.map(el => [el.playerId, el.count]) || [])
            };

            return teamMetrics[key]
                .map(item => {
                    const player = playersProfile?.[teamId]?.find(p => p.playerProfileId === item.playerId) || {};
                    const perc = percDataMap[key]?.[item.playerId] || '';

                    const updatedItem = { ...item, ...player, perc, key };
                    if (key === 'timeOnIce') {
                        updatedItem.count = <TimeFormatter value={updatedItem.count} />;
                    }
                    return updatedItem;
                })
                .sort((a, b) => b.count - a.count || b.jersey - a.jersey)
                .slice(0, 3);
        };

        // Construct initial result structure
        const result = relevantKeys.reduce((acc, key) => {
            acc[key.label] = { label: key.label, home: [], away: [] };
            return acc;
        }, {});

        leaderStats?.leadersMetrics?.forEach(res => {
            relevantKeys.forEach(({ key, label }) => {
                const values = getValuesForKey(res, res.teamId, key);
                if (res.teamStatus === HOME) {
                    result[label].home.push(...values);
                } else if (res.teamStatus === AWAY) {
                    result[label].away.push(...values);
                }
            });
        });

        return Object.values(result);
    }, [playersProfile, leaderStats, relevantKeys]);

    return { processing, data: leaderStatsTableData };
};

export const useFetchPlayersMetrics = (gameId, gameDetail, playersProfile) => {
    const [finalData, setFinalData] = useState({ home: [], away: [] });

    const { processing, data: playersMetrics } = useFetchData({
        method: httpMethods.post,
        url: endpoints.getReportPlayersMetrics,
        payload: {
            gameIds: [gameId]
        },
        initialCondition: !!gameId
    });

    useEffect(() => {
        if (!playersMetrics || !gameDetail?.id || !playersProfile) {
            setFinalData({ home: [], away: [] });
            return;
        }

        const homeTeamId = gameDetail?.homeTeam.id;
        const awayTeamId = gameDetail?.awayTeam.id;

        const getValuesForKey = item => {
            const homePlayer = playersProfile?.[homeTeamId]?.find(p => p.playerProfileId === item.playerId);
            const awayPlayer = playersProfile?.[awayTeamId]?.find(p => p.playerProfileId === item.playerId);

            return homePlayer
                ? { player: homePlayer, teamStatus: HOME }
                : awayPlayer
                ? { player: awayPlayer, teamStatus: AWAY }
                : null;
        };

        const home = [];
        const away = [];

        playersMetrics?.forEach(pt => {
            const data = getValuesForKey(pt);
            if (data?.teamStatus === HOME) {
                home?.push({ ...pt, ...data });
            } else if (data?.teamStatus === AWAY) {
                away?.push({ ...pt, ...data });
            }
        });

        setFinalData({ home, away });
    }, [playersMetrics, playersProfile, gameDetail]);

    return { processing, data: finalData, rawData: playersMetrics };
};

export const useFetchLineXg = (gameId, gameDetail, playersProfile) => {
    const [gameScorePayload, setGameScorePayload] = useState({ gameIds: [], playerIds: [] });
    const [gameScoreData, setGameScoreData] = useState([]);

    const { processing: islinesXGLoading, data: linesXG } = useFetchData({
        method: httpMethods.post,
        url: endpoints.linesXG,
        payload: {
            gameIds: [gameId],
            teamIds: [gameDetail?.homeTeam?.id, gameDetail?.awayTeam?.id]
        },
        initialCondition: !!gameDetail?.homeTeam?.id && !!gameDetail?.awayTeam?.id && !!gameId
    });

    useGetExtraGameScoresOnLinesHook({
        lines: { data: linesXG, processing: islinesXGLoading },
        gameScorePayload,
        setActiveTab: () => {},
        setGameScorePayload,
        setGameScoreData
    });

    const combinedLines = useMemo(() => {
        if (!linesXG || !gameScoreData) {
            return [];
        }

        return extractedCombineLines({ gameScoreData, data: linesXG, isReport: true });
    }, [linesXG, gameScoreData]);

    const linesXGData = useMemo(() => {
        if (!combinedLines || !gameDetail?.id) {
            return {
                forward: {
                    forwardHome: [],
                    forwardAway: []
                },
                defencemen: { defencemenHome: [], defencemenAway: [] }
            };
        }

        const homeTeamId = gameDetail?.homeTeam.id;
        const awayTeamId = gameDetail?.awayTeam.id;

        let forward = [];
        let defencemen = [];

        combinedLines?.forEach(res => {
            const playerData = [];

            res?.playerIds?.forEach(id => {
                const player =
                    playersProfile?.[homeTeamId]?.find(p => p.playerProfileId === id) ||
                    playersProfile?.[awayTeamId]?.find(p => p.playerProfileId === id);

                if (player) {
                    playerData.push(`${player.jersey} ${player.lastNameEn}`);
                }
            });

            if (res?.type === 'FORWARDS') {
                forward.push({
                    ...res,
                    title: playerData.join(' / ')
                });
            } else {
                defencemen.push({
                    ...res,
                    title: playerData.join(' / ')
                });
            }
        });

        const forwardHome = [];
        const forwardAway = [];
        let homeFCount = 1;
        let awayFCount = 1;

        forward.forEach(value => {
            if (value.teamId === homeTeamId) {
                forwardHome.push({ ...value, index: homeFCount });
                homeFCount += 1;
            } else if (value.teamId === awayTeamId) {
                forwardAway.push({ ...value, index: awayFCount });
                awayFCount += 1;
            }
        });

        const defencemenHome = [];
        const defencemenAway = [];

        let homeDCount = 1;
        let awayDCount = 1;
        defencemen.forEach(value => {
            if (value.teamId === homeTeamId) {
                defencemenHome.push({ ...value, index: homeDCount });
                homeDCount += 1;
            } else if (value.teamId === awayTeamId) {
                defencemenAway.push({ ...value, index: awayDCount });
                awayDCount += 1;
            }
        });

        return { forward: { forwardHome, forwardAway }, defencemen: { defencemenHome, defencemenAway } };
    }, [combinedLines, playersProfile, gameDetail]);

    return { processing: islinesXGLoading, data: linesXGData };
};

export const useFetchPlayerLines = (lineUrl, gameId, teamId, gameDetail, playersProfile) => {
    const { processing: isHomePpLinesLoading, data: playerLines } = useFetchData({
        method: httpMethods.post,
        url: lineUrl,
        payload: {
            gameIds: [gameId],
            predicate: expressions.containedIn('teamId', ...[expressions.number(teamId)])
        },
        initialCondition: !!gameDetail && !!gameDetail?.homeTeam?.id && !!gameDetail?.awayTeam?.id && !!gameId
    });

    const homePpLinesData = useMemo(() => {
        if (!playerLines || !gameDetail?.id) {
            return [];
        }

        const homeTeamId = gameDetail?.homeTeam.id;
        const awayTeamId = gameDetail?.awayTeam.id;

        return playerLines?.lines?.map((res, key) => {
            const playerData = [];

            res?.playerIds?.forEach(id => {
                const player =
                    playersProfile?.[homeTeamId]?.find(p => p.playerProfileId === id) ||
                    playersProfile?.[awayTeamId]?.find(p => p.playerProfileId === id);

                if (player) {
                    playerData.push(`${player.jersey} ${player.lastNameEn}`);
                }
            });

            return {
                ...res,
                id: key + 1,
                linePresentation: playerData.join(' / ')
            };
        });
    }, [playerLines, playersProfile, gameDetail]);

    return { processing: isHomePpLinesLoading, data: homePpLinesData };
};
