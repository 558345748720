import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { ICE_RINK_SIZES } from '../../../constants/ice-rink-settings';

import ShotLocation from './shot-location';
import PlayerChooser from 'widgets/components/PlayerChooser';

const HOME = 'HOME';

/**
 * Shot map component
 * @param shots
 * @param hasPlayerFilter
 * @param rest
 * @return {JSX.Element}
 * @constructor
 */
const ShotLocationMapTab = ({ shots, hasPlayerFilter, ...rest }) => {
    const preparedShots = useMemo(() => {
        if (!shots?.data || !Array.isArray(shots?.data)) {
            return shots;
        }

        return {
            ...shots,
            data: shots.data.map(shot => {
                const { inUserTeam, eventTeamStatus } = shot;

                if (!inUserTeam) {
                    return {
                        ...shot,
                        inUserTeam: eventTeamStatus === HOME
                    };
                }

                return shot;
            })
        };
    }, [shots]);

    if (!hasPlayerFilter) {
        return <ShotLocation {...rest} shots={preparedShots} />;
    }

    return (
        <PlayerChooser
            filterFn={ids => {
                if (!shots?.data || !Array.isArray(shots?.data)) {
                    return shots;
                }

                if (ids.length) {
                    return {
                        ...shots,
                        data: shots.data
                            .filter(shot => shot.gamePlayerId === ids[0]) // Filter shots where playerId matches selectedIds
                            .map(shot => {
                                const { inUserTeam, eventTeamStatus } = shot;

                                if (typeof inUserTeam !== 'boolean') {
                                    return {
                                        ...shot,
                                        inUserTeam: eventTeamStatus === HOME
                                    };
                                }
                                return shot;
                            })
                    };
                } else {
                    return preparedShots;
                }
            }}
            filterPropsName="shots"
        >
            <ShotLocation {...rest} />
        </PlayerChooser>
    );
};

ShotLocationMapTab.defaultProps = {
    width: ICE_RINK_SIZES.width,
    height: ICE_RINK_SIZES.height
};

ShotLocationMapTab.propTypes = {
    hasPlayerFilter: PropTypes.bool,
    shots: PropTypes.object.isRequired
};

export default ShotLocationMapTab;
