/**
 * this type means that game was ended in overtime (without shootout)
 */
export const OT = 'OT';

/**
 * this type means that game was ended in shootout
 */
export const SO = 'SO';

/**
 * this type means that game was ended in regular time
 */
export const REGULAR = 'REGULAR';
