export const FACE_OFF_CIRCLE_RADIUS = 450;

const dzTop = 'dzTop';
const dzBottom = 'dzBottom';
const nzTopLeft = 'nzTopLeft';
const nzBottomLeft = 'nzBottomLeft';
const center = 'center';
const nzTopRight = 'nzTopRight';
const nzBottomRight = 'nzBottomRight';
const ozTop = 'ozTop';
const ozBottom = 'ozBottom';

export const faceOffZones = [
    dzTop,
    dzBottom,
    nzTopLeft,
    nzBottomLeft,
    center,
    nzTopRight,
    nzBottomRight,
    ozTop,
    ozBottom
];

export const faceOffCoordinates = {
    [dzTop]: {
        x: 110,
        y: 85,
        point: dzTop,
        centeredX: -2000,
        centeredY: -700
    },
    [dzBottom]: {
        x: 110,
        y: 225,
        point: dzBottom,
        centeredX: -2000,
        centeredY: 700
    },
    [nzTopLeft]: {
        x: 275,
        y: 85,
        point: nzTopLeft,
        centeredX: -564,
        centeredY: -700
    },
    [nzBottomLeft]: {
        x: 275,
        y: 225,
        point: nzBottomLeft,
        centeredX: -564,
        centeredY: 700
    },
    [center]: {
        x: 365,
        y: 155,
        point: center,
        centeredX: 0,
        centeredY: 0
    },
    [nzTopRight]: {
        x: 455,
        y: 85,
        point: nzTopRight,
        centeredX: 564,
        centeredY: -700
    },
    [nzBottomRight]: {
        x: 455,
        y: 225,
        point: nzBottomRight,
        centeredX: 564,
        centeredY: 700
    },
    [ozTop]: {
        x: 620,
        y: 85,
        point: ozTop,
        centeredX: 2000,
        centeredY: -700
    },
    [ozBottom]: {
        x: 620,
        y: 225,
        point: ozBottom,
        centeredX: 2000,
        centeredY: 700
    }
};

export const faceOffPointsAsArray = Object.values(faceOffCoordinates).map(el => el.point);

export const checkPointInCircle = ({ pointX, pointY, circle0x, circle0y, circleRadius }) =>
    Math.pow(pointX - circle0x, 2) + Math.pow(pointY - circle0y, 2) <= Math.pow(circleRadius, 2);

export const faceOffFilters = {
    [dzTop]: event =>
        checkPointInCircle({
            pointX: event.x,
            pointY: event.y,
            circle0x: faceOffCoordinates.dzTop.centeredX,
            circle0y: faceOffCoordinates.dzTop.centeredY,
            circleRadius: FACE_OFF_CIRCLE_RADIUS
        }),
    [dzBottom]: event =>
        checkPointInCircle({
            pointX: event.x,
            pointY: event.y,
            circle0x: faceOffCoordinates.dzBottom.centeredX,
            circle0y: faceOffCoordinates.dzBottom.centeredY,
            circleRadius: FACE_OFF_CIRCLE_RADIUS
        }),
    [nzTopLeft]: event =>
        checkPointInCircle({
            pointX: event.x,
            pointY: event.y,
            circle0x: faceOffCoordinates.nzTopLeft.centeredX,
            circle0y: faceOffCoordinates.nzTopLeft.centeredY,
            circleRadius: FACE_OFF_CIRCLE_RADIUS
        }),
    [nzBottomLeft]: event =>
        checkPointInCircle({
            pointX: event.x,
            pointY: event.y,
            circle0x: faceOffCoordinates.nzBottomLeft.centeredX,
            circle0y: faceOffCoordinates.nzBottomLeft.centeredY,
            circleRadius: FACE_OFF_CIRCLE_RADIUS
        }),
    [center]: event =>
        checkPointInCircle({
            pointX: event.x,
            pointY: event.y,
            circle0x: faceOffCoordinates.center.centeredX,
            circle0y: faceOffCoordinates.center.centeredY,
            circleRadius: FACE_OFF_CIRCLE_RADIUS
        }),
    [nzTopRight]: event =>
        checkPointInCircle({
            pointX: event.x,
            pointY: event.y,
            circle0x: faceOffCoordinates.nzTopRight.centeredX,
            circle0y: faceOffCoordinates.nzTopRight.centeredY,
            circleRadius: FACE_OFF_CIRCLE_RADIUS
        }),
    [nzBottomRight]: event =>
        checkPointInCircle({
            pointX: event.x,
            pointY: event.y,
            circle0x: faceOffCoordinates.nzBottomRight.centeredX,
            circle0y: faceOffCoordinates.nzBottomRight.centeredY,
            circleRadius: FACE_OFF_CIRCLE_RADIUS
        }),
    [ozTop]: event =>
        checkPointInCircle({
            pointX: event.x,
            pointY: event.y,
            circle0x: faceOffCoordinates.ozTop.centeredX,
            circle0y: faceOffCoordinates.ozTop.centeredY,
            circleRadius: FACE_OFF_CIRCLE_RADIUS
        }),
    [ozBottom]: event =>
        checkPointInCircle({
            pointX: event.x,
            pointY: event.y,
            circle0x: faceOffCoordinates.ozBottom.centeredX,
            circle0y: faceOffCoordinates.ozBottom.centeredY,
            circleRadius: FACE_OFF_CIRCLE_RADIUS
        })
};
