import { eventTypesMap } from './event-types-map';
import { faceOffFilters } from '../../../constants/face-offs-config';

const faceOffs = {
    face_off_won: {
        videoPlayerEventType: 'face_off_won',
        group: 'SINGLE_FACE_OFF_WON',
        type: eventTypesMap.events,
        filterFunction: event => event.eventType === 'FACE_OFF' && event.faceOffLossPlayerId !== event.gamePlayerId
    },

    face_off_lost: {
        videoPlayerEventType: 'face_off_lost',
        group: 'SINGLE_FACE_OFF_LOST',
        type: eventTypesMap.events_face_off_lost,
        filterFunction: event => {
            if (!event) {
                return false;
            }
            return event.eventType === 'FACE_OFF';
        }
    },

    face_off_won_dzTop: {
        videoPlayerEventType: 'face_off_won_dzTop',
        group: 'SINGLE_FACE_OFF_WON',
        type: eventTypesMap.events,
        filterFunction: event => event.eventType === 'FACE_OFF' && faceOffFilters.dzTop(event)
    },

    face_off_lost_dzTop: {
        videoPlayerEventType: 'face_off_lost_dzTop',
        group: 'SINGLE_FACE_OFF_LOST',
        type: eventTypesMap.events_face_off_lost,
        filterFunction: event => event.eventType === 'FACE_OFF' && faceOffFilters.dzTop(event)
    },

    face_off_won_dzBottom: {
        videoPlayerEventType: 'face_off_won_dzBottom',
        group: 'SINGLE_FACE_OFF_WON',
        type: eventTypesMap.events,
        filterFunction: event => event.eventType === 'FACE_OFF' && faceOffFilters.dzBottom(event)
    },

    face_off_lost_dzBottom: {
        videoPlayerEventType: 'face_off_lost_dzBottom',
        group: 'SINGLE_FACE_OFF_LOST',
        type: eventTypesMap.events_face_off_lost,
        filterFunction: event => event.eventType === 'FACE_OFF' && faceOffFilters.dzBottom(event)
    },

    face_off_won_nzTopLeft: {
        videoPlayerEventType: 'face_off_won_nzTopLeft',
        group: 'SINGLE_FACE_OFF_WON',
        type: eventTypesMap.events,
        filterFunction: event => event.eventType === 'FACE_OFF' && faceOffFilters.nzTopLeft(event)
    },

    face_off_lost_nzTopLeft: {
        videoPlayerEventType: 'face_off_lost_nzTopLeft',
        group: 'SINGLE_FACE_OFF_LOST',
        type: eventTypesMap.events_face_off_lost,
        filterFunction: event => event.eventType === 'FACE_OFF' && faceOffFilters.nzTopLeft(event)
    },

    face_off_won_nzBottomLeft: {
        videoPlayerEventType: 'face_off_won_nzBottomLeft',
        group: 'SINGLE_FACE_OFF_WON',
        type: eventTypesMap.events,
        filterFunction: event => event.eventType === 'FACE_OFF' && faceOffFilters.nzBottomLeft(event)
    },

    face_off_lost_nzBottomLeft: {
        videoPlayerEventType: 'face_off_lost_nzBottomLeft',
        group: 'SINGLE_FACE_OFF_LOST',
        type: eventTypesMap.events_face_off_lost,
        filterFunction: event => event.eventType === 'FACE_OFF' && faceOffFilters.nzBottomLeft(event)
    },

    face_off_won_center: {
        videoPlayerEventType: 'face_off_won_center',
        group: 'SINGLE_FACE_OFF_WON',
        type: eventTypesMap.events,
        filterFunction: event => event.eventType === 'FACE_OFF' && faceOffFilters.center(event)
    },

    face_off_lost_center: {
        videoPlayerEventType: 'face_off_lost_center',
        group: 'SINGLE_FACE_OFF_LOST',
        type: eventTypesMap.events_face_off_lost,
        filterFunction: event => event.eventType === 'FACE_OFF' && faceOffFilters.center(event)
    },

    face_off_won_nzTopRight: {
        videoPlayerEventType: 'face_off_won_nzTopRight',
        group: 'SINGLE_FACE_OFF_WON',
        type: eventTypesMap.events,
        filterFunction: event => event.eventType === 'FACE_OFF' && faceOffFilters.nzTopRight(event)
    },

    face_off_lost_nzTopRight: {
        videoPlayerEventType: 'face_off_lost_nzTopRight',
        group: 'SINGLE_FACE_OFF_LOST',
        type: eventTypesMap.events_face_off_lost,
        filterFunction: event => event.eventType === 'FACE_OFF' && faceOffFilters.nzTopRight(event)
    },

    face_off_won_nzBottomRight: {
        videoPlayerEventType: 'face_off_won_nzBottomRight',
        group: 'SINGLE_FACE_OFF_WON',
        type: eventTypesMap.events,
        filterFunction: event => event.eventType === 'FACE_OFF' && faceOffFilters.nzBottomRight(event)
    },

    face_off_lost_nzBottomRight: {
        videoPlayerEventType: 'face_off_lost_nzBottomRight',
        group: 'SINGLE_FACE_OFF_LOST',
        type: eventTypesMap.events_face_off_lost,
        filterFunction: event => event.eventType === 'FACE_OFF' && faceOffFilters.nzBottomRight(event)
    },

    face_off_won_ozTop: {
        videoPlayerEventType: 'face_off_won_ozTop',
        group: 'SINGLE_FACE_OFF_WON',
        type: eventTypesMap.events,
        filterFunction: event => event.eventType === 'FACE_OFF' && faceOffFilters.ozTop(event)
    },

    face_off_lost_ozTop: {
        videoPlayerEventType: 'face_off_lost_ozTop',
        group: 'SINGLE_FACE_OFF_LOST',
        type: eventTypesMap.events_face_off_lost,
        filterFunction: event => event.eventType === 'FACE_OFF' && faceOffFilters.ozTop(event)
    },

    face_off_won_ozBottom: {
        videoPlayerEventType: 'face_off_won_ozBottom',
        group: 'SINGLE_FACE_OFF_WON',
        type: eventTypesMap.events,
        filterFunction: event => event.eventType === 'FACE_OFF' && faceOffFilters.ozBottom(event)
    },

    face_off_lost_ozBottom: {
        videoPlayerEventType: 'face_off_lost_ozBottom',
        group: 'SINGLE_FACE_OFF_LOST',
        type: eventTypesMap.events_face_off_lost,
        filterFunction: event => event.eventType === 'FACE_OFF' && faceOffFilters.ozBottom(event)
    }
};

const recoveries = {
    recoveries: {
        videoPlayerEventType: 'recoveries',
        group: 'RECOVERIES',
        type: eventTypesMap.events,
        filterFunction: event =>
            event.recovery && (event.eventType !== 'CHECK_BODY' && event.eventType !== 'CHECK_STICK')
    },

    recoveries_oz: {
        videoPlayerEventType: 'recoveries_oz',
        group: 'RECOVERIES_OZ',
        type: eventTypesMap.events,
        filterFunction: event => event.recovery && event.zoneType === 'OFFENSIVE'
    },

    recoveries_dz: {
        videoPlayerEventType: 'recoveries_dz',
        group: 'RECOVERIES_DZ',
        type: eventTypesMap.events,
        filterFunction: event => event.recovery && event.zoneType === 'DEFENSIVE'
    },

    recoveries_nz: {
        videoPlayerEventType: 'recoveries_nz',
        group: 'RECOVERIES_NZ',
        type: eventTypesMap.events,
        filterFunction: event => event.recovery && event.zoneType === 'NEUTRAL'
    },

    puck_recoveries: {
        videoPlayerEventType: 'puck_recoveries',
        group: 'PUCK_RECOVERIES',
        type: eventTypesMap.events,
        filterFunction: event => event.recovery
    }
};

const shots = {
    shots_with_rebound: {
        videoPlayerEventType: 'shots_with_rebound',
        group: 'SHOTS_WITH_REBOUND',
        type: eventTypesMap.events,
        filterFunction: event => event.shotWithRebound
    },
    shots_missed: {
        videoPlayerEventType: 'shots_missed',
        group: 'SHOTS_MISSED',
        type: eventTypesMap.events,
        filterFunction: event =>
            event.eventType.indexOf('SHOT_ATTEMPT_WIDE_') >= 0 ||
            event.eventType.indexOf('SHOT_ATTEMPT_OVER_THE_NET') >= 0 ||
            event.eventType.indexOf('SHOT_ATTEMPT_DEFLECTED') >= 0
    },

    shots_attempts: {
        videoPlayerEventType: 'shots_attempts',
        group: 'SHOTS_ATTEMPTS',
        type: eventTypesMap.events,
        filterFunction: event => event.eventType.indexOf('SHOT_ATTEMPT_') >= 0
    },
    shots_on_goal: {
        videoPlayerEventType: 'shots_on_goal',
        group: 'SHOTS_ON_GOAL',
        type: eventTypesMap.events,
        filterFunction: event => !!event.shotOnGoal
    },
    shots_blocked: {
        videoPlayerEventType: 'shots_blocked',
        group: 'SHOTS_BLOCKED',
        type: eventTypesMap.events,
        filterFunction: event => event.eventType === 'SHOT_ATTEMPT_BLOCKED'
    }
};

const rebounds = {
    rebound_recoveries: {
        videoPlayerEventType: 'rebound_recoveries',
        group: 'REBOUND_RECOVERIES',
        type: eventTypesMap.events,
        filterFunction: event => event.reboundRecoveries
    },

    rebound_recoveries_oz: {
        videoPlayerEventType: 'rebound_recoveries_oz',
        group: 'REBOUND_RECOVERIES_OZ',
        type: eventTypesMap.events,
        filterFunction: event => event.reboundRecoveries && event.zoneType === 'OFFENSIVE'
    },

    rebound_recoveries_dz: {
        videoPlayerEventType: 'rebound_recoveries_dz',
        group: 'REBOUND_RECOVERIES_DZ',
        type: eventTypesMap.events,
        filterFunction: event => event.reboundRecoveries && event.zoneType === 'DEFENSIVE'
    }
};

export const metricsToGroupMap = {
    ...faceOffs,
    ...recoveries,
    ...shots,
    ...rebounds,

    primary_assist: {
        videoPlayerEventType: 'primary_assist',
        group: 'PRIMARY_ASSIST',
        type: eventTypesMap.events,
        filterFunction: event => event.realAssistType === 'REAL_ASSIST_1'
    },
    secondary_assist: {
        videoPlayerEventType: 'secondary_assist',
        group: 'SECONDARY_ASSIST',
        type: eventTypesMap.events,
        filterFunction: event => event.realAssistType === 'REAL_ASSIST_2'
    },
    goals: {
        videoPlayerEventType: 'goals',
        group: 'PLAYER_GOALS',
        type: eventTypesMap.events,
        filterFunction: event => !!event.goal
    },
    goals_after_rebounds: {
        videoPlayerEventType: 'goals_after_rebounds',
        group: 'GOALS_AFTER_REBOUNDS',
        type: eventTypesMap.events,
        filterFunction: event => event.goal && event.reboundRecovery
    },

    scoring_chances: {
        videoPlayerEventType: 'scoring_chances',
        group: 'SCORING_CHANCES',
        type: eventTypesMap.events,
        filterFunction: event => !!event.danger
    },

    saves: {
        videoPlayerEventType: 'saves',
        group: 'SAVES',
        type: eventTypesMap.events,
        filterFunction: event => !!event && event.eventType.indexOf('SAVE') >= 0
    },

    'goals, primary_assist, secondary_assist': {
        videoPlayerEventType: 'goals, primary_assist, secondary_assist',
        group: 'PTS',
        type: eventTypesMap.events,
        filterFunction: event =>
            !!event.goal || event.realAssistType === 'REAL_ASSIST_1' || event.realAssistType === 'REAL_ASSIST_2'
    },

    individual_shifts: {
        videoPlayerEventType: 'individual_shifts',
        group: 'INDIVIDUAL_SHIFTS',
        type: eventTypesMap.shifts,
        filterFunction: shift => shift
    },

    whole_shifts: {
        videoPlayerEventType: 'whole_shifts',
        group: 'WHOLE_SHIFTS',
        type: eventTypesMap.shifts,
        filterFunction: shift => shift
    },
    power_play: {
        videoPlayerEventType: 'power_play',
        group: 'POWER_PLAY',
        type: eventTypesMap.power_play,
        filterFunction: powerPlay => powerPlay
    },
    penalty_kill: {
        videoPlayerEventType: 'penalty_kill',
        group: 'PENALTY_KILL',
        type: eventTypesMap.penalty_kill,
        filterFunction: penaltyKills => penaltyKills
    },

    goaltenders_goals: {
        videoPlayerEventType: 'goaltenders_goals',
        group: 'GOALTENDERS_GOALS',
        type: eventTypesMap.events,
        filterFunction: event => event
    },

    goaltenders_score_attempts: {
        videoPlayerEventType: 'goaltenders_score_attempts',
        group: 'GOALTENDERS_SCORE_ATTEMPTS',
        type: eventTypesMap.events,
        filterFunction: event => event
    },

    goaltenders_shots_on_goal: {
        videoPlayerEventType: 'goaltenders_shots_on_goal',
        group: 'GOALTENDERS_SHOTS_ON_GOAL',
        type: eventTypesMap.events,
        filterFunction: event => event
    },
    goaltenders_shots_with_rebound: {
        videoPlayerEventType: 'goaltenders_shots_with_rebound',
        group: 'GOALTENDERS_SHOTS_WITH_REBOUND',
        type: eventTypesMap.events,
        filterFunction: event => event
    }
};

export const extraMetricsToGroupMap = {
    face_off: {
        videoPlayerEventType: 'face_off',
        group: 'FACE_OFF',
        type: eventTypesMap.events,
        filterFunction: event => event.eventType === 'FACE_OFF'
    }
};
