import React from 'react';
import sectionStyles from './section.module.css';
import logoImg from './../tpe_logo_new.svg';

const ReportSectionHeader = () => (
    <div className={sectionStyles.header}>
        <div className={sectionStyles.logoWrapper}>
            <img alt="" src={logoImg} height={38} />
        </div>
    </div>
);

export default ReportSectionHeader;
