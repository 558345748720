import React, { Component } from 'react';
import isFunction from 'lodash/isFunction';
import Pagination from './pagination';
import chunk from 'lodash/chunk';
import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';

class FrontPaginator extends Component {
    state = { pageNumber: 0, pageDataChunks: [] };

    goToPage = debounce(pageNumber => {
        this.setState({ pageNumber });
    }, 250);

    getChunks = createSelector(
        props => props.data,
        props => props.pageSize,
        chunk
    );

    getPageContent = createSelector(
        (_, state) => state.pageDataChunks,
        (_, state) => state.pageNumber,
        (chunks, pageNumber) => {
            return chunks[pageNumber] || [];
        }
    );

    componentDidMount() {
        this.setState({ pageDataChunks: this.getChunks(this.props) });
    }

    componentDidUpdate(prevProps, prevState) {
        const { startFromFirstPage } = this.props;

        if (!isEqual(prevProps.data, this.props.data)) {
            const chunks = this.getChunks(this.props);

            if (!chunks[this.state.pageNumber]) {
                this.setState({ pageNumber: !!startFromFirstPage ? 0 : chunks.length - 1, pageDataChunks: chunks });
            } else {
                this.setState({ pageDataChunks: chunks });
            }
        }
    }

    renderController = () => (
        <Pagination
            number={this.state.pageNumber}
            totalPages={this.state.pageDataChunks.length}
            setPageNumber={this.goToPage}
            processing={false}
        />
    );

    render() {
        const { children } = this.props;
        const { pageDataChunks, pageNumber } = this.state;

        if (!isFunction(children)) {
            throw new Error('FrontPagination component expect exact one function children');
        }

        return children({ data: pageDataChunks[pageNumber] || [], renderController: this.renderController });
    }
}

FrontPaginator.defaultProps = {
    pageSize: 30
};

export default FrontPaginator;
