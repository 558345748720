import { createSelector } from 'reselect';
import { teamIdsSelector } from 'selectors/teams/teams-selector';

import { extractEventsByParams } from '../utils';

export const faceOffEventsSelector = createSelector(
    (state, props) => state.widgetsStats.events,
    teamIdsSelector,

    (state, props) => state?.statsFilter?.gameIds,
    (events, teamIds, gameIds) => {
        if (!teamIds?.[0] || !gameIds?.length) {
            // eslint-disable-next-line
            console.error('Not ready data yet.');

            return { home: [], away: [] };
        }

        const teamId = teamIds[0];

        const { home: homeTeamWonEvents = [], away: awayTeamWonEvents = [] } = extractEventsByParams({
            events,
            teamId,
            filteredType: 'FACE_OFF',
            gameIds
        });

        return { home: homeTeamWonEvents, away: awayTeamWonEvents };
    }
);
