import React, { PureComponent } from 'react';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import DataTable from './data-table';
import FrontPaginator from '../pagination/front-paginator';
import Pagination from '../pagination/pagination';
import Loading from '../loading/loading';
export { placements } from './data-table';

function sortData(columns, data, sortedColumn, context) {
    const { key, sortOrder } = sortedColumn;
    const column = columns.find((col, colNum) => (col.key || `${colNum}`) === key);
    if (!column) return null;
    const comparator = column.comparator || column.render;
    const sortedData = orderBy(data, (item, i, placement) => comparator(item, i, placement, context), sortOrder);
    const sortTeamTopOfList = sortBy(sortedData, [obj => (obj.jersey === '-' ? 0 : 1)]);
    return sortTeamTopOfList;
}

class StatefulDataTable extends PureComponent {
    constructor(props) {
        super(props);

        const state = {};

        if (props.data && props.columns && props.defaultOrder) {
            state.sortedData = sortData(props.columns, props.data, props.defaultOrder, props.tableContext);
        }

        if (props.defaultOrder) {
            state.sortedColumn = props.defaultOrder;
        }

        this.state = state;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const sortedColumn = this.state.sortedColumn;
        if (nextProps.data === this.props.data || !sortedColumn) return;
        const { columns, data } = nextProps;
        this.setState({
            sortedData: sortData(columns, data, sortedColumn, this.props.tableContext),
            sortedColumn
        });
    }

    headerClick = columnKey => {
        const { sortedColumn, sortedData } = this.state;
        const { columns, data, pageProps } = this.props;
        if (pageProps) {
            const columnPath = columns[columnKey].path;
            pageProps.togglePropertyOrder(columnPath);
        }
        const isAscending = sortedColumn && sortedColumn.key === columnKey && sortedColumn.sortOrder === 'asc';
        const sortOrder = isAscending ? 'desc' : 'asc';
        const nextSortedColumn = { key: columnKey, sortOrder };
        this.setState({
            sortedData: sortData(columns, sortedData || data, nextSortedColumn, this.props.tableContext),
            sortedColumn: nextSortedColumn
        });
    };

    render() {
        const {
            data,
            highlightRules,
            pageSize: frontPageSize,
            pageProps,
            startFromFirstPage,
            processing,
            ...attrs
        } = this.props;
        const { sortedData, sortedColumn } = this.state;
        const visibleData = sortedData || data;

        const renderDataTable = data =>
            processing ? (
                <Loading placeholder />
            ) : (
                <DataTable
                    highlightRules={highlightRules}
                    {...attrs}
                    data={data}
                    sortedColumn={sortedColumn}
                    onHeaderClick={this.headerClick}
                />
            );

        if (frontPageSize) {
            return (
                <FrontPaginator data={visibleData} pageSize={frontPageSize} startFromFirstPage={startFromFirstPage}>
                    {({ data: pageData, renderController }) => (
                        <>
                            {renderDataTable(pageData)}
                            {renderController()}
                        </>
                    )}
                </FrontPaginator>
            );
        }

        return (
            <>
                {renderDataTable(visibleData)}
                {pageProps && (
                    <Pagination
                        number={pageProps.page}
                        totalPages={pageProps.totalPages}
                        setPageNumber={pageProps.goToPage}
                    />
                )}
            </>
        );
    }
}

export default StatefulDataTable;
