import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { hasAuthKey, setupAuthKey } from 'utils/api/auth-utils';
import { isAnyAdmin, isUserClient } from 'selectors/user/user-roles-selector';

import TeamNewReport from './team-new-report';

const TeamNewReportContainer = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const gameId = searchParams.get('gameIds');
    const teamId = searchParams.get('teamIds');
    const token = searchParams.get('token');
    const isView = searchParams.get('view') === 'render';

    const [isProcessing, setProcessing] = useState(true);

    const isClient = useSelector(isUserClient);
    const isAdmin = useSelector(isAnyAdmin);

    useEffect(() => {
        if (isClient || isAdmin) {
            setProcessing(false);

            return;
        }

        if (hasAuthKey()) {
            setProcessing(false);

            return;
        }

        if (!token) {
            return;
        }

        setupAuthKey(token);

        setProcessing(false);
    }, [token, isClient, isAdmin]);

    if (isProcessing) {
        return null;
    }

    return <TeamNewReport gameId={gameId} teamId={teamId} isView={isView} />;
};

export default TeamNewReportContainer;
