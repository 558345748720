import React, { useCallback, useState, useMemo } from 'react';
import styles from '../face-off-map.module.css';
import { getFontSize } from '../helpers/face-off-map-helpers';
import FormattedMessage from '../../../components/formatted-message';

export const FaceOffSummary = ({ handleShowFromTotal, data }) => {
    const { good, equality, rate, win, lose } = data;

    const [chosen, setChosen] = useState(false);

    const statClass = useMemo(
        () =>
            win === 0 && lose === 0
                ? styles.empty
                : chosen
                ? styles.chosenFaceOffStat
                : good
                ? styles.goodFaceOffStat
                : equality
                ? styles.equalityFaceOffStat
                : styles.badFaceOffStat,
        [win, lose, data]
    );

    const fontSize = getFontSize(win, lose);

    const isEmpty = win === 0 && lose === 0;

    const handleMouseEnter = () => {
        if (isEmpty) {
            return;
        }
        setChosen(true);
    };

    const handleMouseLeave = () => {
        if (isEmpty) {
            return;
        }
        setChosen(false);
    };

    const handleCLick = useCallback(() => {
        if (win === 0 && lose === 0) {
            return;
        }

        handleShowFromTotal();
    }, [handleShowFromTotal, win, lose]);

    return (
        <g
            transform="translate(320,0)"
            onClick={handleCLick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={styles.hover}
        >
            <rect className={statClass} width="90" height="70" rx="4" ry="4" />
            <text x="45" y="17" className={styles.totalTitle}>
                <FormattedMessage svg id="iceRinkChart.zones.summaryTitle" />
            </text>
            <text x="45" y="40" className={styles.rate}>
                {isFinite(rate) ? `${parseInt(rate * 100)}%` : '-'}
            </text>
            <text x="40" y="60" className={styles.successCount} fontSize={fontSize}>
                {win}
            </text>
            <text x="45" y="60" className={styles.slash} fontSize={fontSize}>
                /
            </text>
            <text x="50" y="60" className={styles.failCount} fontSize={fontSize}>
                {lose}
            </text>
        </g>
    );
};
