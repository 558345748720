/* eslint-disable eqeqeq */
import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import HockeyMap from 'components/hockey-map/hockey-map';
import Icon from 'components/icon';
import { metricsToGroupMap } from 'components/video-player-widgets/constants/metrics-to-group-maps';
import { faceOffPointsAsArray } from 'constants/face-offs-config';
import { ICE_RINK_SIZES } from 'constants/ice-rink-settings';

import { ZoneTitle } from './components/zone-title';
import { FaceoffCircle } from './components/face-off-circle';
import { FaceOffSummary } from './components/face-off-summary';
import st from './face-off-map.module.css';
import FilterSelect from '../../components/filter-select/filter-select';
import Button from '../../components/button/button';

const FACE_OFF_LOST = metricsToGroupMap.face_off_lost.videoPlayerEventType;
const FACE_OFF_WON = metricsToGroupMap.face_off_won.videoPlayerEventType;

/**
 * Will render face-off map with optional controls
 * @param data
 * @param processing
 * @param faceOffProps
 * @param hasPlayerFilter
 * @param allPlayers
 * @param onPlayersSelect
 * @returns {JSX.Element}
 * @constructor
 */
const FaceOffMap = ({
    data,
    processing,
    faceOffProps,
    hasPlayerFilter = false,
    allPlayers = [],
    onPlayersSelect = _val => {
        // eslint-disable-next-line
        console.error('Not implemented.');
    },
    onModal
}) => {
    const handleShowFromTotal = useCallback(() => {
        const { onVideoClick } = faceOffProps;

        onVideoClick?.(null, [FACE_OFF_LOST, FACE_OFF_WON]);
        onModal?.(true);
    }, [faceOffProps, onModal]);

    const handleShowFromZone = useCallback(
        point => {
            if (!point || !faceOffPointsAsArray.includes(point)) {
                return;
            }

            const { onVideoClick } = faceOffProps;

            if (!onVideoClick) {
                return;
            }

            const groupsForOpening = [];

            if (metricsToGroupMap[`face_off_lost_${point}`]) {
                groupsForOpening.push(metricsToGroupMap[`face_off_lost_${point}`].videoPlayerEventType);
            } else {
                //todo remove
                groupsForOpening.push(FACE_OFF_LOST);
            }

            if (metricsToGroupMap[`face_off_won_${point}`]) {
                groupsForOpening.push(metricsToGroupMap[`face_off_won_${point}`].videoPlayerEventType);
            } else {
                //todo remove
                groupsForOpening.push(FACE_OFF_WON);
            }

            onModal?.(true);
            onVideoClick(null, groupsForOpening);
        },
        [faceOffProps, onModal]
    );

    const [selectedValues, setSelectedValue] = useState([]);

    const handleSearchFilterChange = useCallback(
        (_filterName, filterData = []) => {
            const ids = filterData?.map(({ gamePlayerId } = {}) => gamePlayerId).filter(Boolean);

            const filteredIds = ids.filter(id => !selectedValues?.some(el => el === id));
            const filteredSearchValues = selectedValues.filter(id => !ids?.some(el => el === id));

            if (!filteredIds.length && filteredSearchValues > 0) {
                setSelectedValue([]);
                onPlayersSelect([]);
                return;
            }

            if (filteredIds.length) {
                setSelectedValue(filteredIds);
                onPlayersSelect(filteredIds);
            }
        },
        [selectedValues, onPlayersSelect]
    );

    const handleValuePresentation = useCallback(
        values => {
            if (values.length === 0 || values.length === allPlayers.length) {
                return 'All players';
            }

            if (values.length === 1) {
                const { profile } = allPlayers?.find(el => values.some(id => id === el.gamePlayerId)) || {};

                const { lastname, lastNameEn } = profile || {};

                return lastname || lastNameEn || '-';
            }

            if (values.length > 1) {
                return `${values.length} players`;
            }

            return '-';
        },
        [allPlayers]
    );

    const { faceoffPoints, faceoffTotal } = data || {};

    if (processing || !faceoffPoints || !faceoffTotal) {
        return <Icon type="spinner" pulse />;
    }

    return (
        <div className={st.root}>
            {hasPlayerFilter && (
                <div className={st.filterPanel}>
                    <div className={st.filterWrap}>
                        <FilterSelect
                            single
                            filterName={'faceOffMapPlayers'}
                            values={selectedValues}
                            options={allPlayers}
                            valuesPresentation={handleValuePresentation}
                            optionPresentation={({ profile, gamePlayerId }) => {
                                const { lastname, firstname, firstNameEn, lastNameEn } = profile || {};

                                return (
                                    <div
                                        className={cn({
                                            [st.selected]: selectedValues.some(id => id === gamePlayerId)
                                        })}
                                    >{`${lastname || lastNameEn} ${firstname || firstNameEn}`}</div>
                                );
                            }}
                            onChange={handleSearchFilterChange}
                        />

                        {selectedValues?.length > 0 && (
                            <Button
                                onClick={() => {
                                    setSelectedValue([]);
                                    onPlayersSelect([]);
                                    onModal?.(false);
                                }}
                            >
                                Clear
                            </Button>
                        )}
                    </div>
                </div>
            )}

            <HockeyMap maxHeight={ICE_RINK_SIZES.height} maxWidth={ICE_RINK_SIZES.width}>
                <FaceOffSummary handleShowFromTotal={handleShowFromTotal} data={faceoffTotal} />
                {faceoffPoints.map((f, fKey) => {
                    const { win, lose } = f;
                    const isEmpty = win === 0 && lose === 0;

                    return (
                        <FaceoffCircle
                            handleShowFromZone={handleShowFromZone}
                            isEmpty={isEmpty}
                            data={f}
                            key={`fKey${fKey}`}
                        />
                    );
                })}
                <ZoneTitle title="DZ" x="30" y="155" anchor="middle" rotate="-90" />
                <ZoneTitle title="OZ" x="700" y="155" anchor="middle" rotate="90" />
            </HockeyMap>
        </div>
    );
};

export default FaceOffMap;
