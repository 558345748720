import React, { useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import Button from 'components/button/button';
import FilterSelect from 'components/filter-select/filter-select';
import { allGamePlayersWithProfilesSelector } from 'containers/widgets-stats/selectors/game-players';

import st from './player-chooser.module.css';

const PlayerChooser = ({
    children,
    filterFn = _ids => {
        throw Error('Not implemented');
    },
    filterPropsName = 'data'
}) => {
    const [selectedValues, setSelectedValue] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);

    const allGamePlayers = useSelector(allGamePlayersWithProfilesSelector);

    const sortedAllPlayers = useMemo(() => {
        return [...(allGamePlayers?.data || [])].sort((el1, el2) => {
            if (!el1?.profile || !el2?.profile) {
                return 0;
            }

            if (el1.profile.lastname && el2.profile.lastname) {
                return el1.profile.lastname > el2.profile.lastname
                    ? 1
                    : el1.profile.lastname < el2.profile.lastname
                    ? -1
                    : 0;
            }

            return 0;
        });
    }, [allGamePlayers]);

    const handleValuePresentation = useCallback(
        values => {
            if (!values.length || values.length === sortedAllPlayers.length) {
                return 'All players';
            }

            if (values.length === 1) {
                const { profile } = sortedAllPlayers?.find(el => values.some(id => id === el.gamePlayerId)) || {};

                const { lastname, lastNameEn } = profile || {};

                return lastname || lastNameEn || '-';
            }

            if (values.length > 1) {
                return `${values.length} players`;
            }

            return '-';
        },
        [sortedAllPlayers]
    );

    const handlePlayersSelect = useCallback(e => {
        setSelectedIds(e);
    }, []);

    const handleSearchFilterChange = useCallback(
        (_filterName, filterData = []) => {
            const ids = filterData?.map(({ gamePlayerId } = {}) => gamePlayerId);

            const filteredIds = ids.filter(id => !selectedValues?.some(el => el === id));
            const filteredSearchValues = selectedValues.filter(id => !ids?.some(el => el === id));

            if (!filteredIds.length && filteredSearchValues > 0) {
                setSelectedValue([]);
                handlePlayersSelect([]);

                return;
            }

            if (filteredIds.length) {
                setSelectedValue(filteredIds);
                handlePlayersSelect(filteredIds);
            }
        },
        [selectedValues, handlePlayersSelect]
    );

    const optionPresentation = useCallback(
        ({ profile, gamePlayerId }) => {
            const { lastname, firstname, firstNameEn, lastNameEn } = profile || {};

            const isSelected = selectedValues.some(id => id === gamePlayerId);

            return (
                <div
                    className={cn({
                        [st.selected]: isSelected
                    })}
                >{`${lastname || lastNameEn} ${firstname || firstNameEn}`}</div>
            );
        },
        [selectedValues]
    );

    const handleFilter = useCallback(() => filterFn(selectedIds), [filterFn, selectedIds]);

    const handleClear = useCallback(() => {
        setSelectedValue([]);
        handlePlayersSelect([]);
    }, []);

    return (
        <>
            <div className={st.filterPanel}>
                <div className={st.filterWrap}>
                    <FilterSelect
                        single
                        filterName={'faceOffMapPlayers'}
                        values={selectedValues}
                        options={sortedAllPlayers}
                        valuesPresentation={handleValuePresentation}
                        optionPresentation={optionPresentation}
                        onChange={handleSearchFilterChange}
                    />

                    {!!selectedValues?.length && <Button onClick={handleClear}>Clear</Button>}
                </div>
            </div>
            {React.cloneElement(children, { [filterPropsName]: handleFilter() })}
        </>
    );
};

export default PlayerChooser;
