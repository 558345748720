import styled from 'styled-components/macro';

import Button from 'components/button/button';
import { mobileMediaCondition } from 'utils/helpers/device-utils';

export const FormContainer = styled.div`
    display: flex;
    gap: 16px;

    @media ${mobileMediaCondition} {
        flex-direction: column;
    }
`;

export const WithControlButtonWrap = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    width: 100%;
    min-width: 300px;
`;

export const ButtonControl = styled(Button)`
    position: absolute;
    top: 5%;
    right: 16px;

    min-width: 5em;

    color: #ccc;

    background: transparent;

    &:hover,
    &:focus {
        background: transparent;
    }

    &:active {
        color: var(--uiPrimaryColor);
    }
`;

export const FieldErrorWrapper = styled.div`
    width: 100%;
    min-height: 36px;
`;

export const FieldError = styled.span`
    color: #a94442;
    font-size: 12px;
`;

export const FieldContent = styled.div`
    display: flex;
    flex-direction: column;
`;
