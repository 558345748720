import isFunction from 'lodash/isFunction';
/**
 * @param {Array} array list of items
 * @param {Object} fieldToCollector field to collector
 *
 * @return {Object} fields to collected values
 */

export function collect(array, fieldToCollector) {
    const aggregated = {};
    Object.keys(fieldToCollector)?.forEach(field => (aggregated[field] = fieldToCollector[field].initialValue));

    array?.forEach(item => {
        Object.keys(fieldToCollector)?.forEach(
            field => (aggregated[field] = fieldToCollector[field].reduce(aggregated[field], item))
        );
    });
    Object.keys(fieldToCollector)?.forEach(field => {
        const afterFunction = fieldToCollector[field].after;
        if (!isFunction(afterFunction)) return;
        aggregated[field] = afterFunction(aggregated[field]);
    });
    return aggregated;
}
/**
 * @param {Array} array list of items
 * @param {Function} keySelector item to key function, using in grouping
 * @param {Object} fieldToCollector field to collector
 *
 * @return {Object} key to collected fields
 */

export function groupBy(array, keySelector, fieldToCollector) {
    const keyToValues = {};
    array.forEach(item => {
        const key = keySelector(item);
        const values = keyToValues[key];

        if (values) {
            values.push(item);
        } else {
            keyToValues[key] = [item];
        }
    });
    const aggregated = {};
    Object.keys(keyToValues).forEach(key => {
        aggregated[key] = collect(keyToValues[key], fieldToCollector);
    });
    return aggregated;
}
