export const SECOND_DS = 10;
export const MINUTE_DS = 60 * SECOND_DS;
export const HOUR_DS = 60 * MINUTE_DS;
export const DAY_DS = 24 * HOUR_DS;

const checkOnNumberWrongData = valueToCheck => {
    return !valueToCheck || typeof valueToCheck !== 'number';
};

/**
 * Transform integer value to two digit format
 * @param {number} int
 * @return {string|string}
 */
export const twoDigitFormat = int => {
    if (checkOnNumberWrongData(int)) {
        return `00`;
    }
    return int < 10 ? `0${int}` : int.toString();
};

/**
 * Transform hours and minutes from number values to string two digit format
 * @param {number} hours
 * @param {number} minutes
 * @return {string}
 */
export const toHhMm = ({ hours, minutes }) => {
    if (checkOnNumberWrongData(hours) || checkOnNumberWrongData(minutes)) {
        return `00:00`;
    }
    return `${twoDigitFormat(hours)}:${twoDigitFormat(minutes)}`;
};

/**
 * Transform date to string for ui
 * @param {number} day
 * @param {number} month
 * @param {number} year
 * @return {string}
 */
export const toDate = ({ day, month, year }) => {
    if (checkOnNumberWrongData(month + 1) || checkOnNumberWrongData(day)) {
        return `____-__-__`;
    }
    return `${year}-${twoDigitFormat(month + 1)}-${twoDigitFormat(day)}`;
};

/**
 * @param time integer number of deciseconds
 * @param {Object} options time presentation options
 * @param {string} options.pattern presentation pattern
 * @return {string} time presentation according to pattern (default mm:ss pattern)
 * @throws exception if can not recognize pattern
 */
export const transformTime = (time, options = {}) => {
    const { pattern = 'm:ss' } = options;
    const formattedTime = time || 0;

    switch (pattern) {
        case 'm:ss':
            return transformTimeMSs(formattedTime);
        case 'mm:ss':
            return transformTimeMmSs(formattedTime);
        case 'hh:mm:ss.z':
            return transformTimeHhMmSsZ(formattedTime);
        case 'mm:ss.z':
            return transformTimeMmSsZ(formattedTime);
        case 'hh:mm:ss.z00':
            return `${transformTimeHhMmSsZ(formattedTime)}00`;
        default:
            throw new Error(`Can not recognize pattern="${pattern}"`);
    }
};

const transformTimeMSs = time => {
    const seconds = Math.floor((time % MINUTE_DS) / SECOND_DS);
    const minutes = Math.floor(time / MINUTE_DS);
    return `${minutes}:${twoDigitFormat(seconds)}`;
};

const transformTimeMmSs = time => {
    const seconds = Math.floor((time % MINUTE_DS) / SECOND_DS);
    const minutes = Math.floor(time / MINUTE_DS);
    return `${twoDigitFormat(minutes)}:${twoDigitFormat(seconds)}`;
};

const transformTimeHhMmSsZ = time => {
    const { hours, minutes, seconds } = convertFromDs(time);
    return `${twoDigitFormat(hours)}:${twoDigitFormat(minutes)}:${twoDigitFormat(seconds)}`;
};

const transformTimeMmSsZ = time => {
    const { hours, minutes, seconds } = convertFromDs(time);

    if (minutes < 1 && hours < 1) {
        return `${twoDigitFormat(seconds)}`;
    }

    if (hours < 1 && minutes < 60) {
        return `${twoDigitFormat(minutes)}:${twoDigitFormat(seconds)}`;
    }

    return `${+twoDigitFormat(minutes) + 60 * twoDigitFormat(hours)}:${twoDigitFormat(seconds)}`;
};

/**
 * Time object in format hh:mm:ss.z
 * @typedef {Object} Time
 * @property {number} hours integer number of hours
 * @property {number} minutes integer number of minutes
 * @property {number} seconds number of seconds with 1 sign after ','
 */

/**
 * @param {number} decaSeconds integer number of deca-seconds
 * @return {Time} time from decaseconds (1s = 10ds)
 */
export const convertFromDs = decaSeconds => {
    const hours = Math.floor(decaSeconds / HOUR_DS);
    const minutes = Math.floor(decaSeconds / MINUTE_DS) % 60;
    const seconds = (Math.floor(decaSeconds) % MINUTE_DS) / SECOND_DS;
    return { hours, minutes, seconds };
};

/**
 * @param {Object} param time
 * @param {number} param.hours integer number of hours
 * @param {number} param.minutes integer number of minutes
 * @param {number} param.seconds seconds number of seconds with 1 sign after ','
 * @return {number} Number of deciseconds
 */
export const convertToDs = ({ hours, minutes, seconds }) => {
    return hours * HOUR_DS + minutes * MINUTE_DS + seconds * SECOND_DS;
};

const offsetToTimeZone = offset => {
    const sign = -Math.sign(offset);
    const fullMinutes = Math.abs(offset);
    const hours = Math.floor(fullMinutes / 60);
    const minutes = fullMinutes % 60;

    return `${sign < 0 ? '-' : '+'}${twoDigitFormat(hours)}:${twoDigitFormat(minutes)}`;
};

export const getCurrentTimeZone = () => offsetToTimeZone(new Date().getTimezoneOffset());

const transformIsoToUtc = isoString => new Date(isoString).toISOString().replace('Z', '');

export const transformUtcToLocale = (utcStr, timeZone = getCurrentTimeZone()) =>
    utcStr && transformIsoToUtc(utcStr.replace('Z', '') + inverseTimeZone(timeZone)).replace('Z', '');

export const transformLocaleToUtc = (localeIsoStr, timeZone = getCurrentTimeZone()) =>
    localeIsoStr && transformIsoToUtc(localeIsoStr.replace('Z', '') + timeZone).replace('Z', '');

const inverseTimeZone = timeZone => {
    const sign = timeZone[0];
    const abs = timeZone.slice(1);

    return (sign === '-' ? '+' : '-') + abs;
};

export const getLocaleIsoString = () => transformUtcToLocale(new Date().toISOString());

export const getLocaleDateString = () => getLocaleIsoString().split('T')[0];
export const getLocaleTimeString = () => getLocaleIsoString().split('T')[1];

export const getIsoStringDate = date => date.split('T')[0];
export const getIsoStringTime = date => date.split('T')[1];

export const toUtcDate = isoDateString => {
    return new Date(isoDateString + 'Z');
};

export const formatDateMmDd = (date, { timeZone } = {}) => {
    const options = { month: 'numeric', day: 'numeric', timeZone };
    return date.toLocaleString('ru', options);
};

export const formatTimeHhMm = (date, { timeZone } = {}) => {
    const options = { hour: 'numeric', minute: 'numeric', timeZone };
    return date.toLocaleString('ru', options);
};

export const formatFullDateTime = (date, { timeZone } = {}) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', timeZone };
    const formattedDate = date
        .toLocaleString('ru', options)
        .split('.')
        .reverse()
        .join('-');

    return `${formattedDate} ${formatTimeHhMm(date, { timeZone })}`;
};

export const getDifference = (dateTime1, dateTime2) => {
    const milliseconds1 = dateTime1 instanceof Date ? dateTime1 : new Date(dateTime1.split('Z')[0] + 'Z').getTime();
    const milliseconds2 = dateTime2 instanceof Date ? dateTime2 : new Date(dateTime2.split('Z')[0] + 'Z').getTime();

    return milliseconds2 - milliseconds1;
};

export const calculateAge = bornString => {
    const today = new Date();
    const birthDate = new Date(bornString);
    const y = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        return y - 1;
    }
    return y;
};

export const CONVERT_TIME_TO_MINUTES = 600;

export const formatSeasonDate = seasonDate => {
    const date = new Date(seasonDate);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
};

export const checkIsDateInRange = checkDate => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // getMonth() is 0-based, so add 1
    const currentDay = currentDate.getDate();
    const currentYear = currentDate.getFullYear();

    let seasonStartYear, seasonEndYear;

    // Check if the current date is between 1st August and 31st December
    if (currentMonth > 8 || (currentMonth === 8 && currentDay >= 1)) {
        seasonStartYear = currentYear;
        seasonEndYear = currentYear + 1;
    } else {
        seasonStartYear = currentYear - 1;
        seasonEndYear = currentYear;
    }

    // Create the date range
    const startDate = new Date(`${seasonStartYear}-08-01`); // 01.08.startYear
    const endDate = new Date(`${seasonEndYear}-07-31`); // 31.07.endYear
    const compareDate = new Date(checkDate);

    return checkDate && compareDate >= startDate && compareDate <= endDate;
};
