import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Range } from 'rc-slider';

import styles from './input-range-box.module.css';

const numericRegex = /^[1-9][0-9]*$/;
const isNumericString = value => numericRegex.test(value);

class TextRange extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        value: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
        range: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired
    };

    static getDerivedStateFromProps(props) {
        return {
            lowValue: props.value[0],
            topValue: props.value[1],
            lowInvalid: false,
            topInvalid: false
        };
    }

    isValid(value) {
        return isFinite(value) && value >= this.props.range[0] && value <= this.props.range[1];
    }

    isConsistent(lowValue, topValue) {
        return lowValue <= topValue;
    }

    onTopChange = ({ target }) => {
        if (!isNumericString(target.value)) return;
        const value = +target.value;
        if (!this.isValid(value)) {
            this.setState({ topInvalid: true, topValue: value });
        } else if (!this.isConsistent(this.state.lowValue, value)) {
            this.setState({ topInvalid: true, lowInvalid: true, topValue: value });
        } else {
            this.props.onChange([this.state.lowValue, value]);
        }
    };

    onLowChange = ({ target }) => {
        if (!isNumericString(target.value)) return;
        const value = +target.value;
        if (!this.isValid(value)) {
            this.setState({ lowInvalid: true, lowValue: value });
        } else if (!this.isConsistent(value, this.state.topValue)) {
            this.setState({ topInvalid: true, lowInvalid: true, lowValue: value });
        } else {
            this.props.onChange([value, this.state.topValue]);
        }
    };

    render() {
        const { lowValue, topValue, lowInvalid, topInvalid } = this.state;
        return (
            <div className={styles.header}>
                <input
                    className={cx(styles.value, { [styles.invalidInput]: lowInvalid })}
                    value={lowValue}
                    onChange={this.onLowChange}
                />
                —
                <input
                    className={cx(styles.value, { [styles.invalidInput]: topInvalid })}
                    value={topValue}
                    onChange={this.onTopChange}
                />
            </div>
        );
    }
}

export default class InputRangeBox extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        value: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
        range: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
        title: PropTypes.any
    };

    render() {
        const { value, range, title, allowCross = true, isFullWidth = false, onChange } = this.props;
        return (
            <div className={cx(styles.root, isFullWidth ? styles.isRangeFullWidth : '')}>
                {title && <div className={styles.title}>{title}</div>}
                <TextRange value={value} onChange={onChange} range={range} />
                <Range value={value} onChange={onChange} min={range[0]} max={range[1]} allowCross={allowCross} />
            </div>
        );
    }
}
