// order make sense add legacy bootstrap styles before others
import 'entry/legacy.css';
import 'entry/global.css';
import 'entry/fonts.module.css';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import store from './entry/store';
import Routes from './entry/routes';
import fullscreenPolyfill from './utils/polyfills/fullscreen-polyfill';
import { isDevelopment } from './utils/helpers/envirioment-utils';

const Root = ({ store }) => {
    return (
        <Provider store={store}>
            <Router>
                <Routes />
            </Router>
        </Provider>
    );
};

const makeRender = Root => render(<Root store={store} />, document.getElementById('root'));

/**
 * Common application initialization procedures.
 * Initializes polyfills and indexedDatabase if it is necessary.
 */

function commonInit() {
    fullscreenPolyfill();
}

/**
 *
 * Initializes application in development mode.
 * Starts development mode plugin with app.
 * @param Root - application root react component
 * @param makeRender - application renderer
 * @param store - application redux store
 */

function developmentInit(Root, makeRender, store) {
    commonInit();

    const DevTestPlugin = require('./utils/plugins/dev-test-plugin').default;

    const devTestPlugin = new DevTestPlugin(store);
    const DevRoot = devTestPlugin.createReactDOMDevRoot(Root);
    makeRender(DevRoot);
    window.tools = devTestPlugin.tools;
}

/**
 * Initializes application in production mode.
 * @param Root - application root react component
 * @param makeRender - application renderer
 */

function productionInit(Root, makeRender) {
    commonInit();
    makeRender(Root);
}

if (!isDevelopment()) {
    productionInit(Root, makeRender);
} else {
    developmentInit(Root, makeRender, store);
}
