/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PREMIUM, PREMIUM_PLUS } from 'containers/product-initializer/products';
import { actions as productActions } from 'containers/product-initializer/product-module';

import GamePreview from 'widgets/game-preview/game-preview';
import ShotLocationMapTab from 'widgets/shot-map/shot-location/shot-location-tab';
import ReportFaceOffsByGames from 'widgets/shot-map/face-offs-by-games/report-face-offs-by-games';

import { initializedReportStatsSelector, reportFullShotsSelector, reportGameSelector } from './report-selector';
import {
    BlocksTitle,
    GameSummaryBlock,
    GameSummaryContainer,
    GameSummaryDate,
    GameSummaryShotsBlock
} from './report.styled-components';
import logoImg from './tpe_logo_new.svg';

import WidgetsStatExecutor from '../../containers/widgets-stats/graph-executor';
import { actions } from '../../containers/stats-filter/module';

import { actions as seasonActions } from '../../containers/stats-filter/season-module';

import styles from './sections/section.module.css';
const DangerZones = React.lazy(() => import('widgets/shot-map/danger-zones/danger-zones'));

export const REPORT_NODE_IDS = [
    'gamePlayers',
    'filteredShots',
    'shots',
    'events',
    'info',
    'gamePlayers',
    'playerProfiles',
    'possessionPlayers',
    'skatingPlayers',
    'skatersShots',
    'passes',
    'avgRestTime',
    'entries',
    'periods',
    'homeTeamLeft',
    'keeperToi'
];
const statsCount = REPORT_NODE_IDS.length;

class ShotsSummary extends Component {
    timeOutPrint;
    nextFrameTimeOut;

    componentDidMount() {
        if (this.props.initialized) {
            return;
        }
        const searchParams = new URLSearchParams(this.props.location.search);
        const gameType = searchParams.get('gameType');
        if (!gameType) {
            this.props.initializeProduct(PREMIUM_PLUS);
        }
        this.props.initializeGames(PREMIUM);
        this.props.initializeSeasons();
    }

    componentWillUnmount() {
        clearTimeout(this.timeOutPrint);
        clearTimeout(this.nextFrameTimeOut);
    }

    filterSections(sections, sectionNames) {
        const sectionNamesSet = new Set(sectionNames);
        return sections.filter(section => {
            if (section.aggregated) {
                section.innerSections.filter(inner => sectionNamesSet.has(inner));
                return true;
            } else return sectionNamesSet.has(section.name);
        });
    }

    componentDidUpdate() {
        const { initialized, processing, initializedStats, gameDetail, markComponentReady } = this.props;
        const canBeRendered = initialized && !processing && gameDetail;
        if (initializedStats === statsCount && canBeRendered) {
            //be sure that page fully rendered

            this.timeOutPrint = setTimeout(() => {
                markComponentReady(true);
            }, 2000);
        }
    }

    //todo move sections structure to separate selector
    render() {
        const { initialized, processing, gameDetail, seasons, shots, playerId } = this.props;
        const canBeRendered = initialized && !processing && gameDetail;
        const currentSeason = gameDetail?.seasonId && seasons?.[gameDetail.seasonId];
        const filteredShots =
            playerId && shots?.data?.length
                ? { ...shots, data: shots?.data?.filter(s => `${s.playerId}` === `${playerId}`) }
                : shots;

        const alwaysTrue = () => true;

        return (
            <div>
                <WidgetsStatExecutor nodeIds={REPORT_NODE_IDS} />
                {canBeRendered && (
                    <div>
                        <GameSummaryContainer>
                            <img alt="" src={logoImg} height={38} />
                            <GameSummaryBlock>
                                <GamePreview game={gameDetail} isReport />
                            </GameSummaryBlock>
                            <GameSummaryDate>
                                <div>{currentSeason?.nameEn ?? ''}</div>
                                <div>{gameDetail?.date ?? ''}</div>
                            </GameSummaryDate>
                        </GameSummaryContainer>
                        <GameSummaryShotsBlock isPlayerReport={!!playerId}>
                            <div>
                                <BlocksTitle isCenter>Shots on goal by zones</BlocksTitle>
                                <ShotLocationMapTab
                                    isReport
                                    headless
                                    className={styles.shotMapInner}
                                    filter={alwaysTrue}
                                    shots={filteredShots}
                                    gameIds={[gameDetail.id]}
                                    teamIds={[gameDetail.homeTeamId]}
                                >
                                    {!!playerId && <DangerZones />}
                                </ShotLocationMapTab>
                            </div>

                            <div>
                                <BlocksTitle isCenter>Face-Offs by zones</BlocksTitle>
                                <ReportFaceOffsByGames
                                    headless
                                    isOpenFaceOffs
                                    gameIds={[gameDetail.id]}
                                    hasPlayerFilter={false}
                                    filter={alwaysTrue}
                                    playerId={playerId}
                                />
                            </div>
                        </GameSummaryShotsBlock>
                    </div>
                )}
            </div>
        );
    }
}

ShotsSummary = withRouter(
    connect(
        state => ({
            game: reportGameSelector(state),
            shots: reportFullShotsSelector(state, true),
            seasons: state.entities.seasons,
            initialized: state.statsFilter.initialized,
            processing: state.statsFilter.processing,
            initializedStats: initializedReportStatsSelector(state)
        }),
        {
            initializeGames: actions.initializeGames,
            initializeSeasons: seasonActions.initializeAllSeasons,
            initializeProduct: productActions.initializeProduct
        }
    )(ShotsSummary)
);
export { ShotsSummary as default };
