import { getFaceOffTotal, getPoints } from './selector-utils';
import { faceOffCoordinates } from '../../../../constants/face-offs-config';

export const getFaceOffMapPoints = ({ home, away }) => {
    const { points } = getPoints({ home, away });

    const faceoffPoints = Object.entries(points).map(([point, result]) => {
        const { win, lose } = result;

        const rate = lose === 0 && win === 0 ? 0 : win / (lose + win);
        const good = rate > 0.5;
        const equality = rate === 0.5;

        return { ...result, ...faceOffCoordinates[point], good, equality, rate, point };
    });

    const faceoffTotal = getFaceOffTotal({ home, away });

    return {
        data: {
            faceoffTotal,
            faceoffPoints,
            availableIds: [...home.map(el => el.id), ...away.map(el => el.id)]
        }
    };
};

export const getAvailableByPlayersFaceOffs = (faceOffEvents = [], gamePlayerIds) =>
    faceOffEvents.filter(({ faceOffLossPlayerId, gamePlayerId }) =>
        gamePlayerIds.some(el => el === faceOffLossPlayerId || el === gamePlayerId)
    );
