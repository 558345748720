import { useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { apiBuilder } from '../../../../../utils/api/common-requests/common-requests';
import { gameScoreV2 } from '../../../../../utils/api/endpoints';

export const useGetExtraGameScoresOnLinesHook = ({
    lines,
    gameScorePayload,
    setActiveTab,
    setGameScorePayload,
    setGameScoreData,
    defaultTab = 0
}) => {
    /*Unmount component effect*/
    useEffect(() => {
        return () => {
            setActiveTab(defaultTab);
            setGameScorePayload({ gameIds: [], playerIds: [] });
            setGameScoreData([]);
        };
    }, []);

    /*Get game score request payload*/
    useEffect(() => {
        if (!lines || !lines.data || lines.data.length === 0) {
            return;
        }

        //Find unique game ids and player ids for request
        const playerIdsCollection = [];
        const gameIdsCollection = [];

        for (const line of lines.data) {
            const { playerIds, stat } = line;
            const { gameId } = stat;

            for (const playerId of playerIds) {
                if (playerIdsCollection.indexOf(playerId) < 0) {
                    playerIdsCollection.push(playerId);
                }
            }

            if (gameIdsCollection.indexOf(gameId) < 0) {
                gameIdsCollection.push(gameId);
            }
        }

        const payloadCandidate = { gameIds: [...gameIdsCollection], playerIds: [...playerIdsCollection] };

        if (!isEqual(payloadCandidate, gameScorePayload)) {
            setGameScoreData([]);
            setGameScorePayload(payloadCandidate);
        }
    }, [lines, gameScorePayload]);

    /*Fetch data front game score v2 endpoint*/
    useEffect(() => {
        if (!gameScorePayload || gameScorePayload.gameIds.length === 0) {
            return;
        }

        //Use only game ids for work with lines
        apiBuilder()
            .post(gameScoreV2, { gameIds: [...gameScorePayload.gameIds] })
            .then(({ data }) => {
                setGameScoreData(data);
            })
            .catch(e => {
                /*eslint-disable*/
                console.error(e);
                /*eslint-enable*/
            });
    }, [gameScorePayload]);
};
