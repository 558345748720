export const games = '/api/games';

export const gamesAll = '/api/games/all';

export const teams = '/api/teams';
export const teamsClient = '/api/teams/client';
export const teamsForSeason = seasonId => `/api/seasons/${seasonId}/teams`;
export const addTeams = seasonId => `/api/seasons/${seasonId}/func/add-teams`;
export const removeTeam = (seasonId, teamId) => `/api/seasons/${seasonId}/teams/${teamId}`;
export const teamsSearch = '/api/teams/search';
export const teamsClientSearch = searchName => `${teamsClient}/search?name=${searchName}`;
export const teamLastGameClientSearch = id => `${teamsClient}/lastGame?teamId=${id}`;
export const teamsClientSeasonsOnTeam = teamId => `${teamsClient}/seasons/?teamId=${teamId}`;
export const teamsClientOpponents = `${teamsClient}/opponents`;
export const teamsClientGames = `${teamsClient}/games`;

export const game = gameId => `${games}/${gameId}`;
export const gameRoster = gameId => game(gameId) + '/roster';
export const gameParts = gameId => `${game(gameId)}/parts`;
export const gamePartsLite = gameId => `${game(gameId)}/lite/parts`;
export const statsProduceJob = gameId => game(gameId) + '/job/stats/produce';
export const gameVideoStitchJob = gameId => game(gameId) + '/job/video/stitch';
export const gameVideoCustomStitchJob = gameId => game(gameId) + '/job/video/stitch/custom';
export const gameSkatersCompare = gameId => `${game(gameId)}/score/sub-lines`;
export const gameStats = gameId => `${game(gameId)}/stats`;

export const gamesFunc = '/api/game-funcs/find-scout-games';

export const gameFiles = gameId => game(gameId) + '/files';
export const calibrationFile = gameId => gameFiles(gameId) + '/calibration';
export const shiftFile = gameId => gameFiles(gameId) + '/shifts';
export const coordinatesFile = gameId => gameFiles(gameId) + '/coordinates';
export const playByPlayFile = gameId => gameFiles(gameId) + '/play-by-play';
export const faceOffFile = gameId => gameFiles(gameId) + '/face-off';
export const faceOffXmlFile = gameId => `/files/games/${gameId}/files/face-off-players`;

export const team = teamId => `${teams}/${teamId}`;
export const teamRoster = teamId => `${teams}/${teamId}/roster`;
export const teamPlayer = (teamId, playerId) => `${teamRoster(teamId)}/${playerId}`;
export const teamPlayers = teamId => `/api/teams/${teamId}/players`;

// Players
export const players = '/api/players';
export const player = playerId => `${players}/${playerId}`;
export const extractPlayersInGames = '/api/func/extract-players-by-game-ids';
export const playerProfiles = `${players}/profiles/extract`;
export const eliteProspectTeamPlayers = (teamId, eliteProspectTeamId) =>
    `/api/teams/${teamId}/prospects/${eliteProspectTeamId}`;
export const loadTeamPlayersFromFile = teamId => `/api/teams/${teamId}/prospects/func/load-from-file`;
export const playerStatsSearch = '/api/players/stats/func/search';
export const playerSummaryInfo = '/api/dashboard/summary/players/info';
export const favoritePlayersStats = '/api/scout/stats/players/favorites';
export const scoutPlayerMetrics = '/api/scout/stats/player-metrics';
export const scoutPlayerStat = '/api/scout/stats/player-stat';
export const scoutPlayersPresets = '/api/scout/metric-presets';
export const scoutPlayersPresetsConfig = '/api/scout/metric-presets/config';
export const scoutSearchPlayers = '/api/scout/search/players';

export const scoutSearchPlayersByName = name => `${scoutSearchPlayers}/profiles?name=${name}`;

export const filesGameInfoUrl = '/files/games/playerInfo';

export const lastGamePlayerInfo = playerId => `${players}/lastGame?playerId=${playerId}`;

// Video Player Settings
export const GetVideoPlayerRangeSettings = '/api/users/videoPlayer/rangeSettings';
export const UpdateVideoPlayerRangeSettings = '/api/users/videoPlayer/rangeSettings';

// Leagues
export const leagues = '/api/leagues';
export const league = leagueId => `${leagues}/${leagueId}`;
export const leaguesSearch = `${leagues}/search`;

// Clients
export const clients = '/api/clients';
export const clientsSearch = '/api/clients/search';
export const client = clientId => `${clients}/${clientId}`;

// Client games
export const liteGames = clientId => `${client(clientId)}/lite-games`;
export const proGames = clientId => `${client(clientId)}/pro-games`;
export const bySeasonsGames = clientId => `${client(clientId)}/seasons`;

// Users
export const users = '/api/users';
export const usersSearch = `${users}/search`;
export const user = userId => `${users}/${userId}`;
export const switchUserToPermanent = userId => `${user(userId)}/func/switch-to-permanent`;
export const adminAuthKeyTPE = `${users}/tpe`;

// Client users
export const clientUsers = '/api/clients/users';
export const clientUsersSearch = '/api/clients/users/search';
export const clientUser = clientUserId => `${clientUsers}/${clientUserId}`;
export const clientUserCaches = `/api/clients/client-user-caches`;

// Welcome email
export const welcomeEmail = userId => `/api/users/${userId}/welcome-email`;

// Client reports
export const clientReportsSearch = '/api/report-purchases/func/search';
export const clientReportUpload = userAccountId =>
    `/api/user-purchased-reports/func/uploadPlayerReport/${userAccountId}`;

// User contracts
export const contracts = '/api/contracts';
export const contractsSearch = '/api/contracts/search';
export const contract = contractId => `${contracts}/${contractId}`;
export const contractsFulfill = `${contracts}/func/fulfill`;
export const deals = '/api/deals/';
export const dealsSearch = '/api/deals/func/search';
export const dealTeams = dealId => `/api/deals/func/change-teams/${dealId}`;
export const dealValues = dealId => `/api/deals/${dealId}`;
export const dealsComments = '/api/deals/comments';
export const getContractByDeal = '/api/deals/func/getContractDataByDeal';
export const dealRegisterContract = '/api/deals/func/registerContract';

// Scout contracts
export const scoutContracts = '/api/scout/contracts';
export const scoutContractsSearch = '/api/scout/contracts/search';
export const scoutContract = scoutContractId => `${scoutContracts}/${scoutContractId}`;

// Favorite teams
export const favoriteTeams = userId => `/api/users/${userId}/favorite-teams`;
export const favoriteTeam = userId => teamId => `${favoriteTeams(userId)}/${teamId}`;

// Favorite items
export const favoriteItems = '/api/favoriteItems';
export const favoriteItemsSearch = '/api/favoriteItems/search';
export const favoriteItem = itemId => `/api/favoriteItems/${itemId}`;

export const favoriteItemAdminActionByUserId = userId => `${favoriteItems}/admin/?userAccountId=${userId}`;
export const favoriteItemIdAdminActionByUserId = (userId, id) =>
    `${favoriteItems}/admin/${id}/?userAccountId=${userId}`;

// Schedule games
export const scheduledGamesSearch = '/api/scheduled-games/func/search';
export const scheduledGames = '/api/scheduled-games';
export const scheduledGame = gameId => `${scheduledGames}/${gameId}`;
export const cancelScheduledGame = gameId => `${scheduledGames}/${gameId}/func/cancel`;
export const publishScheduledGame = gameId => `${scheduledGame(gameId)}/func/publish`;
export const scheduledGamesComments = gameId => `/api/scheduling/games/${gameId}/comments`;
export const purgeScheduledGame = gameId => `/api/cdn/purgeByGameId?gameId=${gameId}`;
export const scheduledGameCommentAttachments = (gameId, commentId) =>
    `${scheduledGamesComments(gameId)}/${commentId}/attachments`;
export const scheduledGamesUploadVideo = gameId => `/api/scheduled-games/${gameId}/lite/func/upload-video`;
export const scheduledGamesUploadVideoFromPath = gameId =>
    `/api/scheduled-games/${gameId}/lite/func/upload-video-from-path`;
export const scheduledGamesLiteVideo = `${scheduledGames}/func/lite-video`;
export const scheduledCompleteVerification = gameId => `/api/scheduled-games/${gameId}/func/complete-verification`;
export const scheduledMarkAsUnderReview = gameId => `/api/scheduled-games/${gameId}/func/under-review`;
export const scheduledUnmarkAsUnderReview = gameId => `/api/scheduled-games/${gameId}/func/under-review-revert`;

export const cancelScheduleGame = '/api/claims/func/cancel-scheduled-game-claim';
export const cancelRequestedGame = '/api/claims/func/cancel-requested-game-claim';

export const scheduledClaimsClients = gameId => `/api/scheduled-games/${gameId}/claims/clients`;
export const requestedClaimsClients = gameId => `/api/requested-games/${gameId}/claims/clients`;

export const scheduleGameDnp = `/api/scheduled-games/dnp`;
export const requestedGameDnp = `/api/scheduling/requested-games/dnp`;

export const scheduleGamesPriority = `${scheduledGames}/priority`;

// Stripe api
export const stripeSession = `/api/deals/payment/session`;
export const stripeUserSession = accountId => `/api/user-payments/users/${accountId}/payment-sessions`;

// Client balance api
export const clientBalance = clientId => `/api/deals/balance/client/${clientId}`;
export const playerBalance = `/api/user-payments/balance`;
export const playerBalanceInfo = `/api/user-payments/users/payment-info`;
export const userGames = `/api/user-games`;

// WorkShifts api
export const workShifts = `/api/work-shifts`;
export const workShiftsDelete = id => `/api/work-shifts/${id}`;
export const workShiftsSearch = `${workShifts}/func/search`;
export const workShiftsStates = `${workShifts}/func/get-states`;
export const workShift = shiftId => `${workShifts}/${shiftId}`;
export const workShiftsUsersUpdate = shiftId => `${workShifts}/${shiftId}/users`;
export const workShiftStatesUpdate = `${workShifts}/state`;

export const requestedGames = '/api/scheduling/requested-games';
export const requestedGamesComments = gameId => `${requestedGames}/${gameId}/comments`;
export const requestedGamesUploadProtocol = gameId => `${requestedGames}/${gameId}/lite/func/upload-protocol`;
export const requestedGamesUploadVideo = gameId => `${requestedGames}/${gameId}/lite/func/upload-video`;

export const schedulingRequestedGameComment = ({ gameId }) => `/api/scheduling/requested-games/${gameId}/comments`;
export const schedulingDefaultGameComment = ({ gameId }) => `/api/scheduling/games/${gameId}/comments`;

export const createFromRequested = '/api/scheduled-games/func/create-from-requested';

export const scheduledGamesUploadProtocol = gameId => `/api/scheduled-games/${gameId}/func/upload-protocol`;

export const fromFlashScoreGames = '/api/scheduling/func/import/from-flash-score';

// Errors
export const errorsReport = '/public/oops-error';

// Auth
export const login = '/api/login';
export const logout = '/api/logout';
export const loginAs = '/api/login-as';
export const signUp = '/api/users/func/sign-up';
export const resendConfirmationEmail = '/api/users/func/resend-confirmation-email';
export const confirmEmailAndLogin = '/api/users/func/confirm-email-and-log-in';
export const emailUpdate = email => `/api/users/func/update-email?email=${email}`;
export const confirmEmail = token => `/public/func/confirm-email?token=${token}`;

// Password
export const passwordSet = `/public/users/set-password`;
export const changePassword = `/api/users/change-password`;

// User
export const userProfile = '/api/users/me';

// Dashboard
const dashboard = '/api/dashboard';
export const dashboardSummary = `${dashboard}/summary`;
export const dashboardSummaryInfo = `${dashboardSummary}/info`;
export const teamsInfo = `${dashboardSummary}/teams/info`;
export const dashboardSummaryShots = `${dashboardSummary}/shots`;
export const dashboardSkatersShots = `${dashboard}/skaters/shots`;

// Statistic
const statistic = `${dashboard}/stat`;
export const statisticPassing = `${statistic}/passes`;

export const periods = `${games}/coordinates/timeline`;

export const timeLine = `/api/games/coordinates/timeline`;

//Collectors
export const collectorUserProfile = '/api/collector/users/me';
export const collectorUsers = '/api/collector/users';
export const collectorGames = '/api/collector/games';
export const collectorGame = gameId => `/api/collector/games/${gameId}`;
export const collectorGamePartsAll = '/api/games/parts/assigned';
export const collectorGameParts = gameId => `/api/games/${gameId}/parts/assigned`;
export const collectorGamePart = (gameId, partNumber) => `/api/games/${gameId}/parts/assigned/${partNumber} `;

export const gameStorage = gameId => `/api/games/${gameId}/files`;
export const gameStorageStash = gameId => `/api/games/${gameId}/files/stash`;

export const collectorDeleteFile = fileId => `/api/games/files/${fileId}`;
export const createFromScoutGame = '/api/games/lite/func/create-from-scout-game';
export const cropScoutVideo = gameId => `/api/games/${gameId}/job/video/stitch/lite`;
export const scoutGamesCropVideo = scoutGameId => `/api/scout-games/${scoutGameId}/crop-params`;

export const filesGamesShifts = gameId => `/files/games/files/shifts?gameId=${gameId}`;
export const fileGamesPlayByPlay = gameId => `/files/games/files/play-by-play/?gameId=${gameId}`;

export const playersProfiles = '/api/players/profiles';

export const loadInfoFromEp = '/api/players/loadInfoFromEp';
export const profileByName = '/api/players/profileByName';

export const tpeDateOfSend = playerProfileId => `/api/players/tpeDateOfSend/${playerProfileId}`;

//Possession
export const possessionPlayers = `${games}/possession/players`;
export const possessionTeams = `${games}/possession/teams`;

//Goal-Keepers
export const keeperToi = '/api/games/goaltenders/toi';
export const leagueStats = seasonId => `/api/leagues/${seasonId}`;

//Skating
export const skatingTeam = '/api/games/skating/plot/teams';
export const skatingPlayers = '/api/games/skating/stats/players';
export const skatingPlayersList = '/api/games/skating/plot/players';

//XG
//export const linesXG = `/api/games/lines_xg`;
export const linesXG = '/api/games/lines_stats';
export const fullLinesXG = '/api/games/full_lines';

export const filteredShots = '/api/games/filtered_events/shots';

export const eventsSingle = gameId => `/api/games/${gameId}/events`;

export const events = '/api/games/filtered_events';

export const homeTeamLeft = '/api/games/periods';

export const entries = '/api/stats/zone-changes';

//Game score
export const gameScore = '/api/games/score/players';
export const gameScoreV2 = '/api/games/game-score-v2';

// Season endpoints
export const seasons = '/api/seasons';
export const seasonsSearch = '/api/seasons/func/search';
export const seasonsClient = '/api/seasons/client';
export const season = seasonId => `/api/seasons/${seasonId}`;
export const clientSeasons = '/api/seasons/clients';

export const video = gameId => `https://icebergvideo.blob.core.windows.net/video/${gameId}.mp4`;

export const shiftsStatsSingle = gameId => `/api/games/${gameId}/shift-stats`;
export const shiftStats = '/api/shift-stats';
export const rawShifts = gameId => `/api/admin/games/${gameId}/enriched-raw-shifts`;
export const calculateShift = gameId => `/api/admin/games/${gameId}/calculate-shift`;
export const shifts = gameId => `/api/admin/games/${gameId}/shifts`;
export const liteShiftChanges = gameId => `/api/admin/games/${gameId}/shift-changes`;

export const gamePlayersByGameId = gameId => `/api/games/${gameId}/players`;

export const gameStrengthChangeTimes = gameId => `/api/games/${gameId}/strengths`;

export const ppStats = '/api/stats/pp';
export const gamesPps = '/api/stats/pp/info';

export const pkStats = '/api/stats/pk';
export const gamesPks = '/api/stats/pk/info';

export const avgRestTime = '/api/stats/avg-rest-time';

export const storage = gameIds => `/api/ice-player/coord-links?gameIds=${gameIds}`;

export const icePlayerCoordLinkUrl = '/api/ice-player/coord-links';

//Favorites
export const getFavorites = '/api/playlists/games';
export const getScoutFavorites = '/api/playlists/scout-games';
export const postFavorites = '/api/playlists';
export const deleteFavorites = id => `/api/playlists/${id}`;

export const scoutGames = '/api/scout-games';
export const scoutGamesSearch = '/api/scout-games/search';
export const scoutGame = scoutGameId => `${scoutGames}/${scoutGameId}`;
export const scoutGameVideoFile = scoutGameId => `${scoutGames}/${scoutGameId}/func/upload-video`;
export const scoutGameStitchPeriods = scoutGameId => `${scoutGames}/${scoutGameId}/func/video-stitch`;
export const scoutGameDeleteVideo = scoutGameId => `${scoutGames}/${scoutGameId}/func/delete-video`;
export const scoutGameCopyFromPurgatory = `${scoutGames}/func/upload-video`;
export const scoutGameUpdateVideoLinks = `${scoutGames}/func/update-links`;
export const clientGameVideos = '/api/scout/client-game-videos';
export const clientGamesSearch = '/api/client-games/search';

const playerStats = playerId => `/api/stat/players/${playerId}`;
export const faceoffsZones = playerId => `${playerStats(playerId)}/face-off-stats`;
export const playerGamesStats = playerId => `${playerStats(playerId)}/game-stats`;
export const playerQuantiles = playerId => `${playerStats(playerId)}/quantiles`;
export const playerQuantilesMultipleLeagues = `/api/stat/players/quantiles`;
export const leagueAvgQuantiles = '/api/stat/players/quantiles/average';

export const playersMetricsTotal = '/api/stat/players/metrics/total';
export const playersMetrics = '/api/stat/players/metrics';
export const filesMetrics = gameId => `/api/games/${gameId}/files/metrics`;

export const postGameReport = '/api/game-reports';

export const gameReportsStatus = gameId => `/api/${gameId}/reports`;

export const playersSearch = '/api/players/func/search';

export const playersSeasons = '/api/players/func/playerSeasonGames';

export const countries = '/api/country-funcs/find-with-players';

export const gameFaceOffs = gameId => `/api/v2/admin/games/${gameId}/face-offs`;
export const gameEvents = gameId => `/api/v2/admin/games/${gameId}/events`;
export const playByPlay = gameId => `/api/v2/admin/games/${gameId}/play-by-play`;
export const playByPlays = gameId => `/api/play-by-plays/${gameId}`;
export const playByPlaysChanges = gameId => `/api/play-by-plays/${gameId}/changes`;
export const jersey = gameId => `/api/v2/admin/games/${gameId}/jersey`;
export const linesByEvent = `/api/games/lines`;

export const recalculateGameFast = gameId => `/api/func/recalculate-game-fast/${gameId}`;
export const recalculateGames = '/api/func/recalculate-games';
export const gameCalculateStatus = gameId => `/api/games/${gameId}/latest-task-status`;
export const initialGameCalculate = gameId => `/api/func/submit-calculation-task/${gameId}`;

export const cvGameTokens = '/api/cv-game-tokens';
export const uploadCvAutosaveData = cvGameTokens + '/func/finish-game-data-labeling';

const requestedGameClientVideo = '/api/clients/file-uploading/requested-games';
const scheduledGameClientVideo = '/api/clients/file-uploading/scheduled-games';

export const acquireRequestedGameSas = gameId => `${requestedGameClientVideo}/${gameId}/func/acquire-sas`;
export const acquireScheduledGameSas = gameId => `${scheduledGameClientVideo}/${gameId}/func/acquire-sas`;
export const acquireNFTGameSas = gameId => `${nftPrefix}/file-uploading/${gameId}/func/acquire-sas`;
export const readRequestedGameFiles = gameId => `${requestedGameClientVideo}/${gameId}/func/read`;
export const readScheduledGameFiles = gameId => `${scheduledGameClientVideo}/${gameId}/func/read`;
export const completeRequestedGameFilesUpload = gameId => `${requestedGameClientVideo}/${gameId}/func/complete`;
export const completeScheduledGameFilesUpload = gameId => `${scheduledGameClientVideo}/${gameId}/func/complete`;

export const acceptSupportRequest = '/api/support/func/accept-request';

export const scoutPlayerGames = playerId => `/api/players/${playerId}/games`;
export const scoutPlayerGamesSearch = `/api/players/games`;
export const scoutGamesPlayersSearch = `/api/games/players`;

const withAgrPlayerGamePrefix = apiEntity => `/api/agr/player-game/${apiEntity}`;
export const agrPlayerGameLeagues = withAgrPlayerGamePrefix(`leagues`);
export const agrPlayerGameSeasons = withAgrPlayerGamePrefix(`seasons`);
export const agrPlayerGameTeams = withAgrPlayerGamePrefix(`teams`);
export const agrPlayerGameGames = withAgrPlayerGamePrefix(`games`);

export const scoutLeagues = '/api/scout/leagues';
export const scoutSeasons = '/api/scout/seasons';
export const scoutTeams = '/api/scout/teams';
export const scoutFilteredGames = '/api/scout/games';

export const scoutLeaguesAvailableReports = '/api/scout/player-categories/reports/leagues';
export const scoutSeasonsAvailableReports = '/api/scout/player-categories/reports/seasons';
export const scoutTeamsAvailableReports = '/api/scout/player-categories/reports/teams';

export const scoutGamesByGameId = gameId => `/api/scout-games/${gameId}`;

export const playerGames = `/api/players/games`;

export const playerGamesSmall = `/api/games/players/smallAll`;

export const scoutPlayerStats = '/api/scout/stats/players';
export const scoutLeagueStats = '/api/scout/stats/players/func/collect-variability-measures-and-percentiles';

export const customPlaylists = '/api/playlists';
export const customPlaylist = playlistId => `${customPlaylists}/${playlistId}`;
export const customPlaylistReorder = playlistId => `${customPlaylists}/${playlistId}/position`;
export const customPlaylistItemReorder = playlistItemId => `${customPlaylists}/items/${playlistItemId}`;
export const customPlaylistItemSwap = (playlistItem1, playlistItem2) =>
    `${customPlaylists}/items/swap/${playlistItem1}/${playlistItem2}`;
export const customPublicPlaylists = '/public';
export const customPlaylistPublicGames = playlistId => `${customPublicPlaylists}/${playlistId}/games`;
export const customPlaylistsItems = playlistId => `${customPlaylists}/${playlistId}/items`;
export const customPlaylistGames = playlistId => `${customPlaylists}/${playlistId}/games`;
export const customPlaylistsItem = itemId => `${customPlaylists}/items/${itemId}`;
export const copyCustomPlaylist = `${customPlaylists}/items/func/copy`;
export const moveCustomPlaylist = `${customPlaylists}/items/func/move`;

export const mediaPlayerShortLinks = '/api/short-links';
export const mediaPlayerShortLink = shortLink => `${mediaPlayerShortLinks}/${shortLink}`;

export const videoTimecodes = '/api/games/func/find-video-player-timecodes';

export const acceptLicense = '/api/users/func/accept-license-agreement';
export const isLicenseAgreementAccepted = '/api/users/func/is-license-agreement-accepted';

/// NAHL
export const nahlPlayerProfile = '/api/players/nahl-player';
export const uploadPlayerPhoto = '/api/players/func/upload-player-photo';

export const gridPresets = '/api/common/grid';

//SCOUT
export const scoutUserCategories = '/api/scout/user-categories';
export const scoutPlayerCategories = '/api/scout/player-categories';
export const scoutPlayerChat = '/api/scout/player-chat';
export const scoutRooms = '/api/scout/room';
export const allRooms = '/api/scout/room/all';
export const scoutParticipantRoom = '/api/scout/room-participant';
export const scoutParticipantRoomAccount = '/api/scout/room-participant/account';
export const playerProfile = '/api/players/profile';
export const scoutReports = '/api/scout/player-categories/reports';
export const scoutPlayerGamesList = '/api/players/client/games';
export const scoutPlayerOpponents = '/api/players/client/opponents';
export const scoutPlayerSeasons = id => `/api/players/client/seasons?playerId=${id}`;

// RinkNet
export const rinkNetGetGoalieStats = id => `/api/rinknet/get-goalie-stats?rinknetPlayerId=${id}`;
export const rinkNetGetSkaterStats = id => `/api/rinknet/get-skater-stats?rinknetPlayerId=${id}`;
export const rinkNetGetSeasons = seasonIds => `/api/rinknet/get-seasons?${seasonIds}`;
export const rinkNetPlayersByIds = ids => `/api/rinknet/get-players?${ids}`;

export const allCountries = '/api/countries';
export const cities = '/api/city';

export const eventSchedule = '/api/scout/event-schedule';
export const gameSchedule = '/api/scout/game-schedule';

export const scoutPlayerContracts = '/api/players/contracts';

//todo remove old clipService
//const clipService_01 = 'https://video-gluer.azurewebsites.net';
//const clipService_02 = 'https://video-gluer-v2.azurewebsites.net';
//const clipService_03 = 'https://video-gluer.westeurope.cloudapp.azure.com';

const clipServiceUrl = 'https://video-gluer-5us.eastus.cloudapp.azure.com';
const vipClipServiceUrl = 'https://video-gluer-4us.westus3.cloudapp.azure.com';

export const clipService = isVIP => (isVIP ? `${vipClipServiceUrl}/clip` : `${clipServiceUrl}/clip`);
export const clipServiceTaskStatus = (taskId, isVIP) =>
    isVIP ? `${vipClipServiceUrl}/status-task/${taskId}` : `${clipServiceUrl}/status-task/${taskId}`;

export const fileProtocolsInfo = `${scheduledGamesSearch}/filesInfo`;

//Player public encrypt decrypt API
export const publicEncrypt = '/public/playerId/encrypt';
export const publicDecrypt = '/public/playerId/decrypt';

/* NFT endpoints */

const nftPrefix = `/api/nft`;
export const nftGameInfoByGameIdURL = gameId => `${nftPrefix}/gameInfo?gameId=${gameId}`;
export const nftGamesSearch = `${nftPrefix}/func/search`;
export const nftGameMomentsByGame = gameId => `${nftPrefix}/gameMoments?gameId=${gameId}`;
export const nftGameMoment = `${nftPrefix}/game-moment`;
export const nftGameEventMoment = `${nftPrefix}/event-moment`;
export const nftGameMomentComments = gameMomentId => `${nftPrefix}/comment/gameMomentId/?gameMomentId=${gameMomentId}`;
export const nftGameMomentComment = `${nftPrefix}/comment`;

const CLOUD_VIDEO_URL = 'https://openshot.azurefd.net';

export const generateCloudProject = `${CLOUD_VIDEO_URL}/projects/`;
export const getCloudURLProjectId = id => `${generateCloudProject}${id}/`;
export const cloudProjectFiles = `${CLOUD_VIDEO_URL}/files/`;
export const cloudProjectClips = `${CLOUD_VIDEO_URL}/clips/`;
export const cloudProjectLoad = id => `${getCloudURLProjectId(id)}load/`;
export const cloudProjectMerge = id => `${getCloudURLProjectId(id)}merge/`;
export const cloudProjectExport = `${CLOUD_VIDEO_URL}/exports/`;
export const cloudProjectExportId = id => `${CLOUD_VIDEO_URL}/exports/${id}/`;
export const cloudProjectExportIdDownload = id => `${CLOUD_VIDEO_URL}/exports/${id}/download/`;
export const cloudProjectExportIdStorage = id => `${CLOUD_VIDEO_URL}/exports/${id}/storage/`;

// Recent Search API
export const recentSearch = `/api/recentSearches`;

// AWS Chime API
export const getMeetingInfo = id => `/api/aws/chime/getMeeting?meetingId=${id}`;
export const getFinishMeeting = id => `/api/aws/chime/finishMeeting?meetingId=${id}`;
export const createMeeting = id => `/api/aws/chime/createMeeting?externalMeetingId=${id}`;
export const createAttendee = (meetingId, externalUserId) =>
    `/api/aws/chime/createAttendee?meetingId=${meetingId}&externalUserId=${externalUserId}`;
export const sendMeetingInvite = `/api/aws/chime/sendMeetingInvite`;

// AWS Chime Chat API
export const AWS_CHIME_CHAT_BASE_URL = 'arn:aws:chime:us-east-1:906328608266:app-instance';
export const createAppInstance = appName => `/api/aws/chime/createAppInstance?name=${appName}`;
export const createAppInstanceUser = (appInstance, userId, userName) =>
    `/api/aws/chime/createAppInstanceUser?appInstanceArn=${appInstance}&appInstanceUserId=${userId}&name=${userName}`;
export const createChatChannel = (appInstance, userInstance, channelName) =>
    `/api/aws/chime/createChannel?appInstanceArn=${appInstance}&chimeBearer=${userInstance}&name=${channelName}`;
export const createChannelMembership = (channelArn, memberInstance, userInstance) =>
    `/api/aws/chime/createChannelMembership?channelArn=${channelArn}&memberArn=${memberInstance}&chimeBearer=${userInstance}`;
export const listChannelMessages = (channelArn, userInstance) =>
    `/api/aws/chime/listChannelMessages?channelArn=${channelArn}&chimeBearer=${userInstance}`;
export const sendChannelMessage = `/api/aws/chime/SendChannelMessage`;

/* Release note endpoint */
export const getLastReleases = `/api/release/get-last-releases`;

/* Reports endpoint */
export const getReportGameDetails = id => `/api/games/${id}`;
export const getReportShotsAndGoalScored = id => `/api/game-reports/game/data/shots-and-goals-scored?gameId=${id}`;
export const getReportFaceOffSummary = (gameId, teamId) =>
    `/public/api/game-reports/game/data/face-off-summary?gameId=${gameId}&teamId=${teamId}`;
export const getReportSkaterAndGoalScored = (gameId, playerId) =>
    `/api/game-reports/game/data/skater-shots-and-goals-scored?gameId=${gameId}&playerId=${playerId}`;
export const getReportFaceOffByPeriodAndStrength = id =>
    `/api/game-reports/game/data/face-off-by-period-and-strength?gameId=${id}`;
export const getReportPlayersMetrics =
    '/api/stat/players/metrics?metric=goals&metric=primary_assists&metric=secondary_assists&metric=plus_minus&metric=toi&metric=shifts&metric=shot_attempts&metric=shots_on_goal&metric=shots_on_goal_danger_zone&metric=shots_blocked&metric=blocked_shots_by&metric=hit_taken&metric=hit_drawn&metric=face_off_won&metric=face_off_lost&metric=on_ice_xg_for&metric=on_ice_xg_against&metric=on_ice_xg_for_20&metric=on_ice_xg_against_20&metric=xg&metric=on_ice_xg_20&metric=scoring_chances&metric=shots_missed';
export const getReportLeaderStats = id => `/api/game-reports/game/data/leaders-stats?gameId=${id}`;
export const getReportPlayersProfile = id => `/public/api/game-reports/game/data/player-profiles?gameId=${id}`;
