import styled from 'styled-components/macro';

export const HockeyMapWrapper = styled('div')`
    display: flex;
    flex-direction: column;
`;
export const HockeyMapSpecialFilters = styled('div')`
    display: flex;
    flex-direction: row;
    gap: 4px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 28px;
    margin: 10px auto 10px;
`;
export const SpecialFilterControl = styled('span')`
    color: ${props => (props.isActive ? 'var(--uiPrimaryColorActive)' : 'var(--optionsDisabledColor)')};
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;

    text-align: center;

    cursor: pointer;

    user-select: none;
`;
