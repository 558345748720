export const SPECIAL_FILTERS_IS_AVAILABLE = true;
export const JERSEYS_SWITCH_IS_VISIBLE = true;

export const GOAL = 'GOAL';
export const SHOT_ON_GOAL = 'SHOT_ON_GOAL';
export const BLOCKED = 'BLOCKED';
export const DEFLECTED = 'DEFLECTED';
export const MISS = 'MISS';
export const JERSEYS = 'JERSEYS';

export const specialFilterTabs = [
    {
        name: GOAL,
        filterFunc: shot => !!shot.goal
    },
    {
        name: SHOT_ON_GOAL,
        filterFunc: shot => !!shot.shotOnGoal && !shot.goal
    },
    {
        name: BLOCKED,
        filterFunc: shot => shot.eventType === 'SHOT_ATTEMPT_BLOCKED'
    },
    {
        name: DEFLECTED,
        filterFunc: shot => shot.eventType.indexOf('SHOT_ATTEMPT_DEFLECTED') >= 0
    },
    {
        name: MISS,

        filterFunc: shot => !shot.shotOnGoal
    }
];
