import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import Table, { Th, Tr, Td } from './index';
import { matchPath, withRouter } from 'react-router-dom';
import styles from './table.module.css';
import styled from 'styled-components/macro';

const StyledHeader = styled.div`
    ${({ filter }) => (filter ? 'height: 40px' : null)}
`;

const columnPropTypes = PropTypes.shape({
    /*Header label to render*/
    header: PropTypes.node.isRequired,

    /*Column row render function*/
    render: PropTypes.func.isRequired,

    /*Column react key prop for optimization reasons*/
    key: PropTypes.string,

    /*Mark column as sortable*/
    sortable: PropTypes.bool,

    /*Hide column*/
    hidden: PropTypes.bool,

    /*Specific column comparator*/
    comparator: PropTypes.func,

    /*Header cell properties*/
    headerProps: PropTypes.object,

    /*Cell property*/
    props: PropTypes.object
});

const propTypes = {
    /*Array of table columns*/
    columns: PropTypes.arrayOf(columnPropTypes).isRequired,
    data: PropTypes.array,
    footerData: PropTypes.array,
    headerData: PropTypes.array,

    /*Active sorted column with column key and sort order*/
    sortedColumn: PropTypes.shape({
        key: PropTypes.string,
        sortOrder: PropTypes.string
    }),

    /*Row function to generate react key prop for optimization reasons*/
    accessor: PropTypes.func,

    /*Header click handler*/
    onHeaderClick: PropTypes.func,

    /*Special property for redux data table implementation*/
    dataSelector: PropTypes.any,

    /*Row click handler*/
    onRowClick: PropTypes.func,

    /*Mouse over handler*/
    onRowMouseOver: PropTypes.func,

    /*Mouse leave handler*/
    onRowMouseLeave: PropTypes.func,

    /*Special optional property which injected in column render function*/
    tableContext: PropTypes.any,

    /*Row properties function*/
    rowProps: PropTypes.func,

    highlightRules: PropTypes.any
};
const BODY = 'BODY';
const HEADER = 'HEADER';
const FOOTER = 'FOOTER';
export const placements = {
    BODY,
    HEADER,
    FOOTER
};
const nonBreakSpace = new RegExp(String.fromCharCode(160), 'g');

function formatTime(str) {
    /* if (str.indexOf(':') === -1) return str;
    const [minutes, seconds] = str.split(':').map(d => parseFloat(d));
    return (minutes + seconds / 60).toFixed(2);*/
    return str;
}

function format(string) {
    const trimmed = string.trim().replace(nonBreakSpace, ' ');
    const timed = formatTime(trimmed);

    if (!isNaN(parseFloat(timed))) {
        return timed.replace(/\./g, ',');
    }

    return timed;
}

class DataTable extends PureComponent {
    headerRef = React.createRef();

    state = { bottomTableHeader: false };

    static convert(table) {
        const ths = table.children[0].children[0].children;

        const trs = table.children[1].children;
        const rows = [
            [...ths].map(t => format(t.textContent)),
            ...[...trs].map(tr => [...tr.children].map(td => format(td.textContent)))
        ];
        return rows.map(d => d.join(';')).join('\n');
    }

    static downloadFile(name, str) {
        const link = document.createElement('a');
        link.download = `${name}.csv`;
        const blob = new Blob([str], {
            type: 'text/plain'
        });
        link.href = window.URL.createObjectURL(blob);
        link.click();
    }

    renderHeaderColumn(column, colNum) {
        if (!column) {
            return null;
        }

        const { key = `${colNum}`, sortable = true, hidden = false, header, filter, headerProps } = column;

        if (hidden) {
            return null;
        }

        const { sortedColumn, onHeaderClick } = this.props;
        const sortOrder = sortedColumn && sortedColumn.key === key ? sortedColumn.sortOrder : null;
        const sortBasedProps = sortable
            ? {
                  onClick: onHeaderClick?.bind(null, key)
              }
            : {};
        return (
            <Th fixedWidth align="center" {...headerProps} key={key} sorted={sortOrder}>
                <StyledHeader {...sortBasedProps} filter={filter}>
                    {header}
                    {sortOrder === 'desc' && <span>&#9660;</span>}
                    {sortOrder === 'asc' && <span>&#9650;</span>}
                </StyledHeader>
                {filter}
            </Th>
        );
    }

    renderRowColumn(column, row, colNum, rowNum, placement, highlightColor) {
        if (!column) {
            return null;
        }

        const { tableContext } = this.props;
        const { key = `${colNum}`, hidden = false, render, props } = column;
        if (hidden) {
            return null;
        }

        const highLightStyle = highlightColor && { color: highlightColor, transition: 'color 1s' };
        return (
            <Td align="center" {...props} key={key} color={highlightColor} style={highLightStyle}>
                {(render && render(row, rowNum, placement, tableContext)) || '-'}
            </Td>
        );
    }

    rowRefs = {};

    setRowRef = key => this.rowRefs[key] || (this.rowRefs[key] = React.createRef());

    scrollTo = key => {
        const rowNode = this.rowRefs[key] && this.rowRefs[key].current;
        rowNode &&
            rowNode.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
    };

    renderRow(row, rowNum, placement) {
        const {
            accessor,
            onRowClick,
            onRowMouseOver,
            onRowMouseLeave,
            rowProps,
            tableContext,
            columns,
            highlightRules
        } = this.props;
        const rowKey = accessor ? accessor(row, placement) : `${rowNum}`;
        const onClick = onRowClick ? onRowClick.bind(this, row) : null;
        const onMouseOver = onRowMouseOver ? onRowMouseOver.bind(this, row) : null;
        const onMouseLeave = onRowMouseLeave ? onRowMouseLeave.bind(this, row) : null;
        const props = rowProps ? rowProps(row, tableContext) : null;

        return (
            <Tr
                innerRef={this.setRowRef(rowKey)}
                {...props}
                key={rowKey}
                onClick={onClick}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
            >
                {columns.map((column, colNum) => {
                    const passedRule =
                        highlightRules && highlightRules.find(rule => rule.predicate(row, rowNum, colNum));
                    const highlightColor = passedRule && passedRule.color;

                    return this.renderRowColumn(column, row, colNum, rowNum, placement, highlightColor);
                })}
            </Tr>
        );
    }

    onScroll = () => {
        const { top } = this.headerRef.current.getBoundingClientRect();

        if (top < 30) {
            this.setState({ bottomTableHeader: true });
        } else {
            this.setState({ bottomTableHeader: false });
        }
    };

    componentDidMount() {
        const { doubleHeader } = this.props;
        doubleHeader && window.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
        const { doubleHeader } = this.props;
        doubleHeader && window.removeEventListener('scroll', this.onScroll);
    }

    render() {
        const { bottomTableHeader } = this.state;

        const tableProps = omit(this.props, Object.keys(propTypes));
        const { columns, data = [], footerData = [], headerData = [] } = this.props;
        return (
            <Table {...tableProps}>
                <thead ref={this.headerRef}>
                    {headerData.map((row, index) => this.renderRow(row, index, HEADER))}
                    <tr>{columns.map((column, index) => this.renderHeaderColumn(column, index))}</tr>
                </thead>
                <tbody>{data.map((row, index) => this.renderRow(row, index, BODY))}</tbody>
                <tfoot>{footerData.map((row, index) => this.renderRow(row, index, FOOTER))}</tfoot>
                {bottomTableHeader && (
                    <tfoot>
                        {headerData.map((row, index) => this.renderRow(row, index, HEADER))}
                        <tr>{columns.map((column, index) => this.renderHeaderColumn(column, index))}</tr>
                    </tfoot>
                )}
            </Table>
        );
    }
}

export class TableLoader {
    setName(name) {
        this.name = name;
    }

    setTable(table) {
        this.table = table;
    }

    download = () => {
        DataTable.downloadFile(this.name, DataTable.convert(this.table));
    };
}

class CSVLinkComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.tableLoader = new TableLoader();
        this.tableLoader.setName(props.name || 'table');
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.tableLoader.setName(props.name);
    }

    setTable = table => {
        this.tableLoader.setTable(table);
    };
    download = () => {
        this.tableLoader.download();
    };

    render() {
        if (
            matchPath(this.props.location.pathname, {
                path: '/lite'
            })
        )
            return null;
        return (
            <div className={styles.csv} onClick={this.download}>
                CSV
            </div>
        );
    }
}

DataTable.propTypes = propTypes;

class CSVLink extends PureComponent {
    render() {
        const { innerRef, ...rest } = this.props;
        return <CSVLinkComponent ref={innerRef} {...rest} />;
    }
}

CSVLink = withRouter(CSVLink);
export { CSVLink };
export default DataTable;
