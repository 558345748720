/**
 * this mean that team is home team
 */
export const HOME = 'HOME';

/**
 * this mean that team is visitor team
 */
export const AWAY = 'AWAY';

/**
 * this mean that team isn't home or visitor team (used in events with non-player events like `WHISTLE`)
 */
export const NONE = 'NONE';
