const FOR_STROKE = 'transparent';
const AGAINST_STROKE = 'transparent';
const FOR_GOAL_STROKE = '#0b21fd';
const AGAINST_GOAL_STROKE = '#ff1233';
const FOR_GOAL_STROKE_ONE_SIDE = 'rgba(11,33,253,0.75)';
const AGAINST_GOAL_STROKE_ONE_SIDE = 'rgba(255,18,51,0.75)';
const HOME = 'HOME';

export const getStStroke = (shot, oneSide) => {
    const { goal, inUserTeam, eventTeamStatus } = shot;

    const inUserTeamValue = typeof inUserTeam === 'boolean' ? inUserTeam : eventTeamStatus === HOME;

    if (!goal) {
        return !!inUserTeamValue ? FOR_STROKE : AGAINST_STROKE;
    }

    if (!!oneSide) {
        return !!inUserTeamValue ? FOR_GOAL_STROKE : AGAINST_GOAL_STROKE;
    }

    return !!inUserTeamValue ? FOR_GOAL_STROKE_ONE_SIDE : AGAINST_GOAL_STROKE_ONE_SIDE;
};

export const getFilteredGamesByShot = (shots, shotId, games) => {
    const selectedShot = shots?.data?.length && shots?.data?.find(shot => shot.id === shotId);

    return games?.data?.filter(game => game.id === selectedShot?.gameId);
};
