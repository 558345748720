import * as teamStatusType from '../constants/team-status-type';
import * as winType from '../constants/win-type';
/**
 * `true` if game has OT, otherwise `false`
 */

export const hasOvertime = game => {
    return Boolean(game.otScore);
};
/**
 * @return {{teamStatus: String, type: String}} OT winner team status and win type.
 * If game ended in regular type returns `NONE` in `teamStatus`
 */

export const getOvertimeWinner = game => {
    if (!hasOvertime(game))
        return {
            teamStatus: teamStatusType.NONE
        };
    const teamStatus = game.homeScore > game.awayScore ? teamStatusType.HOME : teamStatusType.AWAY;
    const type = game.shootOutsScore ? winType.SO : winType.OT;
    return {
        teamStatus,
        type
    };
};
