import styled, { css } from 'styled-components/macro';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CloseIcon from 'components/svg-icons/close';

export const modalAlignments = {
    CENTER: 'center',
    RIGHT: 'right'
};

const disableWindowScroll = () => {
    document.body.style.overflow = 'hidden';
};

const enableWindowScroll = () => {
    document.body.style.overflow = 'auto';
};

const baseCenterContentCss = css`
    flex-shrink: 0;
    padding: 20px;

    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
`;

const baseRightContentCss = css`
    width: 400px;
    height: 100%;
    padding: 61px 24px;

    box-shadow: -3px 0 4px rgba(0, 0, 0, 0.25);
`;

const StyledCloseIcon = styled(CloseIcon)`
    display: block;
    margin: 0 0 0 auto;

    cursor: pointer;
`;

const Container = styled.div`
    background: var(--backgroundColor);
    
    ${props => (props.alignment === modalAlignments.CENTER ? baseCenterContentCss : '')}
    ${props => (props.alignment === modalAlignments.RIGHT ? baseRightContentCss : '')}
    
    ${props => props.contentCss}
`;

class ModalAdapter extends Component {
    componentDidMount() {
        const { disableScroll, isOpen } = this.props;
        isOpen && disableScroll && disableWindowScroll();
    }

    componentWillUnmount() {
        this.props.disableScroll && enableWindowScroll();
    }

    componentDidUpdate(prevProps) {
        const wasOpen = prevProps.isOpen;
        const isOpen = this.props.isOpen;

        if (wasOpen !== isOpen) {
            if (wasOpen) {
                enableWindowScroll();
            } else {
                this.props.disableScroll && disableWindowScroll();
            }
        }
    }

    render() {
        const {
            className,
            children,
            onAfterOpen,
            onRequestClose,
            disableScroll,
            contentCss,
            alignment,
            innerRef,
            closeButton,
            ...props
        } = this.props;

        return (
            <ReactModal
                portalClassName={className}
                className="rm-content"
                overlayClassName="rm-overlay"
                onAfterOpen={onAfterOpen}
                onRequestClose={onRequestClose}
                {...props}
            >
                <Container ref={innerRef} contentCss={contentCss} alignment={alignment}>
                    {closeButton && <StyledCloseIcon onClick={onRequestClose} />}
                    {children}
                </Container>
            </ReactModal>
        );
    }
}

const overlayCss = css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    z-index: 12000;

    background-color: rgba(0, 0, 0, 0.5);

    ${props => props.overlayCss || ''}
`;

const centerAnchorCss = css`
    position: fixed;
    top: 50%;
    left: 50%;

    width: 0;
    height: 0;
`;

const rightAnchorCss = css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;
`;

const contentCss = css`
    z-index: 1001;

    display: flex;
    justify-content: center;
    align-items: center;

    ${props => (props.alignment === modalAlignments.RIGHT ? rightAnchorCss : '')}
    ${props => (props.alignment === modalAlignments.CENTER ? centerAnchorCss : '')}
`;

const StyledModal = styled(ModalAdapter)`
    & .rm-overlay {
        ${overlayCss}
    }

    & .rm-content {
        ${contentCss}
    }
`;

StyledModal.propTypes = {
    disableScroll: PropTypes.bool,
    alignment: PropTypes.oneOf(Object.values(modalAlignments)),
    isOpen: PropTypes.bool
};

StyledModal.defaultProps = {
    disableScroll: false,
    alignment: modalAlignments.CENTER,
    isOpen: false
};

export default StyledModal;
