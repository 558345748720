import { Component } from 'react';
import { denormalize } from 'normalizr';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import queryHoc from './query-hoc';
import { notificationTypes } from '../../redux/modules/notifications';
import { DEFAULT_FAILURE_NOTIFICATION, DEFAULT_SUCCESS_NOTIFICATION } from './constants';
import { createSelector } from 'reselect';

class QueryComponent extends Component {
    componentDidMount() {
        this.fetchQuery();
    }

    componentDidUpdate(prevProps) {
        const { processing, error, variables, query, notify, onLoading } = this.props;

        if (prevProps.processing && !processing) {
            if (error) {
                const { onFailure, notifyOnFailure = true, failureNotification } = this.props;
                if (notifyOnFailure || (failureNotification && notifyOnFailure !== false)) {
                    const message = failureNotification
                        ? isFunction(failureNotification)
                            ? failureNotification(error)
                            : failureNotification
                        : DEFAULT_FAILURE_NOTIFICATION;
                    notify(message, notificationTypes.ERROR);
                }
                onFailure && onFailure(error);
            } else {
                const { onSuccess, data, notifyOnSuccess, successNotification } = this.props;
                if (notifyOnSuccess || (successNotification && notifyOnSuccess !== false)) {
                    const message = successNotification
                        ? isFunction(successNotification)
                            ? successNotification(data)
                            : successNotification
                        : DEFAULT_SUCCESS_NOTIFICATION;
                    notify(message, notificationTypes.SUCCESS);
                }
                onSuccess && onSuccess(data);
            }
        }

        !prevProps.processing && processing && onLoading && onLoading();

        if (
            prevProps.query !== query ||
            (prevProps.variables !== variables &&
                (!this.props.shouldFetchQuery ||
                    this.props.shouldFetchQuery(prevProps.variables, this.props.variables)))
        ) {
            if (prevProps.variables !== variables) {
                if (this.props.page && this.props.page !== 0) {
                    this.props.goToPage(0);
                } else {
                    this.fetchQuery();
                }
            } else {
                this.fetchQuery();
            }
        }
    }

    componentWillUnmount() {
        const { queryId, cancel } = this.props;
        cancel(queryId);
    }

    fetchQuery = () => {
        const { queryId, request } = this.props;
        request({ queryId, payload: this.buildCompleteQuery() });
    };

    buildCompleteQuery() {
        const { query, variables } = this.props;
        return isFunction(query) ? query(variables) : query;
    }

    getCompletedData = createSelector(
        props => props.data,
        props => props.entities,
        (data, entities) => {
            const { schema } = this.buildCompleteQuery();
            return isNil(schema) ? data : denormalize(data, schema, entities);
        }
    );

    render() {
        const { processing = true, error, children } = this.props;

        if (!isFunction(children)) {
            throw new Error('Query component expect exact one function children');
        }

        const completeData = this.getCompletedData(this.props);

        return children({ processing, data: completeData, error, repeatQuery: this.fetchQuery });
    }
}

const Query = queryHoc(QueryComponent);

export default Query;
