import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { actions } from './module';

function mapStateToProps(state) {
    return {
        filter: state.statsFilter,
        seasonInitialized: state.statsFilterSeason.initialized
    };
}

class WidgetsStatExecutor extends PureComponent {
    componentDidMount() {
        const { filter, nodeIds, seasonInitialized, fetchNodes } = this.props;
        if (filter.initialized && !filter.processing && seasonInitialized) fetchNodes(nodeIds);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { filter, nodeIds, seasonInitialized, fetchNodes } = nextProps;
        if (filter.initialized && !filter.processing && seasonInitialized) fetchNodes(nodeIds);
    }

    componentWillUnmount() {
        this.props.cancel();
    }

    render() {
        return null;
    }
}

WidgetsStatExecutor = connect(
    mapStateToProps,
    actions
)(WidgetsStatExecutor);
export { WidgetsStatExecutor as default };
