import { makePrefixAdder } from '../utils/type-utils';

const addPrefixTo = makePrefixAdder('VIDEO_CONFIG');
const RE_FETCH = addPrefixTo('RE_FETCH');

export const reFetchVideoConfig = payload => {
    return { type: RE_FETCH, payload: payload };
};

const defaultState = {
    isVideoConfigNeedToFetch: true
};

export function reducer(state = defaultState, action) {
    switch (action.type) {
        case RE_FETCH:
            return {
                isVideoConfigNeedToFetch: action.payload
            };
        default:
            return state;
    }
}

export const videoConfigSelector = state => state.videoConfig.isVideoConfigNeedToFetch;

export const actions = {
    reFetchVideoConfig
};
