import React from 'react';
import DataTable from 'components/table-with-tooltip';
import { ReportSection, ReportPpPkBlock, BlocksTitle, TeamTitle } from '../report.styled-components';

const TeamLinesSection = ({
    team,
    logo,
    color,
    defenseLines,
    forwardLines,
    ppLines,
    pkLines,
    lineXgColumns,
    ppColumns,
    pkColumns
}) => (
    <ReportSection>
        <BlocksTitle isCenter>
            <TeamTitle color={color}>{team ?? 'TEAM'}</TeamTitle>

            <img src={logo} width={30} alt={team ?? 'TEAM'} />
        </BlocksTitle>
        <div className="row">
            <div className="col-6">
                <ReportPpPkBlock>
                    <BlocksTitle>Defenses lines</BlocksTitle>
                    {defenseLines && <DataTable className="reportTable" data={defenseLines} columns={lineXgColumns} />}
                </ReportPpPkBlock>
                <ReportPpPkBlock>
                    <BlocksTitle>Forwards lines</BlocksTitle>
                    {forwardLines && <DataTable className="reportTable" data={forwardLines} columns={lineXgColumns} />}
                </ReportPpPkBlock>
            </div>
            <div className="col-6">
                <ReportPpPkBlock>
                    <BlocksTitle>Power players lines</BlocksTitle>
                    {ppLines && <DataTable className="reportTable" data={ppLines} columns={ppColumns} />}
                </ReportPpPkBlock>
                <ReportPpPkBlock>
                    <BlocksTitle>Penalty killers lines stats</BlocksTitle>
                    {pkLines && <DataTable className="reportTable" data={pkLines} columns={pkColumns} />}
                </ReportPpPkBlock>
            </div>
        </div>
    </ReportSection>
);

export default TeamLinesSection;
