import { roles } from '../../utils/permissions/role-utils';

export const hasUserSpecifiedRole = (state, ...roles) => {
    const userRoles = state?.user?.account?.authorities || [];

    return roles.some(role => userRoles.includes(role));
};

export const isUserClient = state => hasUserSpecifiedRole(state, roles.CLIENT);
export const isUserAnalyst = state => hasUserSpecifiedRole(state, roles.ANALYST);
export const isUserAdmin = state => hasUserSpecifiedRole(state, roles.ADMIN);
export const isModerator = state => hasUserSpecifiedRole(state, roles.MODERATOR);
export const isVerifier = state => hasUserSpecifiedRole(state, roles.VERIFIER);
export const isAnyAdmin = state =>
    hasUserSpecifiedRole(
        state,
        roles.ADMIN,
        roles.SUPPORT_ADMIN,
        roles.PRODUCTION_ADMIN,
        roles.VERIFIER_ADMIN,
        roles.PRO_ADMIN,
        roles.LITE_ADMIN,
        roles.SCOUT_ADMIN
    );
