import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { hasTemporaryKey } from '../../utils/api/auth-utils';
import { isUserClient } from '../../selectors/user/user-roles-selector';
import * as endpoints from '../../utils/api/endpoints';
import Query from '../../containers/rest-query/query';
import Loading from '../../components/loading/loading';

const isLicenseSignedQuery = {
    method: 'GET',
    url: endpoints.isLicenseAgreementAccepted
};

const LicenseRequiredRoute = ({ needLicense, withToken = false, component: Component, ...rest }) => {
    if (!needLicense || withToken) {
        return <Route {...rest} component={Component} />;
    }

    return (
        <Query query={isLicenseSignedQuery}>
            {({ data, processing }) =>
                processing ? (
                    <Loading />
                ) : !data ? (
                    <Redirect to={{ pathname: '/license', state: { from: window.location.pathname } }} />
                ) : (
                    <Route {...rest} component={Component} />
                )
            }
        </Query>
    );
};

export default connect(state => ({
    needLicense: !hasTemporaryKey() && isUserClient(state)
}))(LicenseRequiredRoute);
