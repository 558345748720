import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import FormattedMessage from '../../components/formatted-message';
import styles from './player-stat-content.module.css';
import PlayerIcon from '../../components/svg-icons/players';
import { connect } from 'react-redux';
import { actions as icePlayerActions } from '../../widgets/ice-player/ice-player-container/module';
import { againstPic } from '../../constants/logos';

const Header = ({ player, isSingleTeamView }) => {
    const playerNameClasses = cx(styles.playerName, {
        [styles.teamPlayer]: player.inUserTeam,
        [styles.opponentPlayer]: !player.inUserTeam
    });
    const { profile } = player;

    return (
        <div className={styles.header}>
            <PlayerIcon {...player} className={styles.playerIcon} height="24" width="24" />
            &nbsp;
            {!!profile && (
                <FormattedMessage id="entities.player.fullName" values={player.profile} className={playerNameClasses} />
            )}
            {isSingleTeamView === false && (
                <img
                    alt=""
                    src={player.teamLogo || againstPic}
                    height="24px"
                    width="24px"
                    style={{ marginLeft: 'auto' }}
                />
            )}
        </div>
    );
};

const StatValue = ({ children, to }) => {
    const isLink = !!to;
    const Tag = isLink ? Link : 'span';
    return (
        <Tag className={cx(styles.rowValue, { [styles.rowLinkValue]: isLink })} to={to}>
            {children} {isLink && ' >'}
        </Tag>
    );
};

const Stat = ({ stat }) => {
    return (
        <div className={styles.row}>
            <span className={styles.rowHeader}>{stat.header}:&nbsp;</span>
            <StatValue {...stat.valueProps}>{stat.value}</StatValue>
        </div>
    );
};

const Footer = ({ disabled = false, onClick }) => {
    return (
        <div className={cx(styles.footer, { [styles.footerDisabled]: disabled })} onClick={disabled ? null : onClick}>
            <FormattedMessage id="tooltip.video" />
            &nbsp;
        </div>
    );
};

const PlayerStatContent = ({
    player,
    event,
    stats = [],
    watchable,
    watchDisabled,
    popUpIcePlayer,
    isSingleTeamView,
    customHeader,
    customFooter,
    onVideoClick
}) => {
    const isWithHeader = !!player;
    const contentClasses = cx(styles.content, {
        [styles.headerPadding]: !isWithHeader,
        [styles.footerPadding]: !watchable
    });

    return (
        <div className={contentClasses}>
            {customHeader}
            {isWithHeader && <Header player={player} isSingleTeamView={isSingleTeamView} />}
            {stats.map((stat, key) => (
                <Stat key={key} stat={stat} />
            ))}
            {!!(watchable && !!(event.shotId || event.id)) && (
                <Footer
                    disabled={watchDisabled}
                    onClick={(onVideoClick || popUpIcePlayer).bind(
                        null,
                        event.shotId || event.id,
                        event.group,
                        event.playerId,
                        player && player.profile
                    )}
                />
            )}
            {customFooter}
        </div>
    );
};

const statPropType = {
    /*Statistics parameter name to display*/
    header: PropTypes.node.isRequired,
    /*Statistics parameter value to display*/
    value: PropTypes.node.isRequired,
    /*Statistics parameter value props (for example: { to: '/dashboard/home' })*/
    valueProps: PropTypes.object
};

PlayerStatContent.propsType = {
    /*array of tooltip stats*/
    stats: PropTypes.arrayOf(statPropType),
    /*fat player entity with position, jersey and profile*/
    player: PropTypes.object,
    /*if true then add footer with animation/video link*/
    watchable: PropTypes.bool,
    /*if true then animation/video link disabled*/
    watchDisabled: PropTypes.bool
};

export default connect(
    null,
    icePlayerActions
)(PlayerStatContent);
