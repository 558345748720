import React, { useCallback, useMemo, useState } from 'react';
import styles from '../face-off-map.module.css';
import { getFontSize } from '../helpers/face-off-map-helpers';

export const FaceoffCircle = ({ isEmpty, handleShowFromZone, data }) => {
    const { point, good, equality, rate, win, lose, x, y } = data;

    const [chosen, setChosen] = useState(false);

    const statClass = useMemo(
        () =>
            isEmpty
                ? styles.empty
                : chosen
                ? styles.chosenFaceOffStat
                : good
                ? styles.goodFaceOffStat
                : equality
                ? styles.equalityFaceOffStat
                : styles.badFaceOffStat,
        [isEmpty, data]
    );

    const fontSize = getFontSize(win, lose);

    const handleMouseEnter = () => {
        if (isEmpty) {
            return;
        }
        setChosen(true);
    };

    const handleMouseLeave = () => {
        if (isEmpty) {
            return;
        }
        setChosen(false);
    };

    const handleCircleClick = useCallback(() => {
        setChosen(false);
        if (isEmpty) {
            return;
        }

        handleShowFromZone?.(point);
    }, [handleShowFromZone, point, isEmpty]);

    return (
        <g
            transform={`translate(${x},${y})`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleCircleClick}
            className={styles.hover}
        >
            <circle className={statClass} r={37} />
            <text y="-5" className={styles.rate}>
                {isFinite(rate) ? `${parseInt(rate * 100)}%` : '-'}
            </text>
            <text x="-5" y="15" className={styles.successCount} fontSize={fontSize}>
                {win}
            </text>
            <text y="15" className={styles.slash} fontSize={fontSize}>
                /
            </text>
            <text x="5" y="15" className={styles.failCount} fontSize={fontSize}>
                {lose}
            </text>
        </g>
    );
};
