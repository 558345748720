export default class TooltipController {
    static defaultMarker = 'tooltipElement';

    constructor() {
        this.activeIndex = null;
        this.fixed = false;
        this.marker = TooltipController.defaultMarker;
    }

    setRenderTooltip(renderTooltip) {
        this.renderTooltip = renderTooltip;
    }

    setShowTooltip(showTooltip) {
        this.showTooltip = showTooltip;
    }

    setHideTooltip(hideTooltip) {
        this.hideTooltip = hideTooltip;
    }

    setMarker(marker) {
        this.marker = marker;
    }

    mouseEnterHandler = (d, i, event) => {
        if (!('ontouchstart' in window)) {
            if (i !== this.activeIndex) {
                if (this.activeIndex !== null) this.hideTooltip(`#${this.marker}-${this.activeIndex}`);
                this.activeIndex = i;
                this.fixed = false;
                this.showTooltip(`#${this.marker}-${i}`, event.currentTarget, this.renderTooltip(d));
            }
        }
    };

    mouseLeaveHandler = (d, i) => {
        if (!('ontouchstart' in window)) {
            if (!this.fixed && this.activeIndex === i) {
                this.activeIndex = null;
                this.hideTooltip(`#${this.marker}-${i}`);
            }
        }
    };

    unmountTooltip = () => {
        if (this.activeIndex !== null) this.hideTooltip(`#${this.marker}-${this.activeIndex}`);
        this.activeIndex = null;
        this.fixed = null;
    };

    mouseClickHandler = (d, i, event) => {
        event && event.stopPropagation();
        if (d === null && i === null) {
            this.fixed = false;
            this.hideTooltip(`#${this.marker}-${this.activeIndex}`);
            this.activeIndex = null;
        }
        if (i !== this.activeIndex) {
            if (this.activeIndex !== null) this.hideTooltip(`#${this.marker}-${this.activeIndex}`);
            this.activeIndex = i;
            this.showTooltip(`#${this.marker}-${i}`, event.currentTarget, this.renderTooltip(d));
            if (!this.fixed) this.fixed = true;
        } else {
            this.fixed = !this.fixed;
        }
    };
}
