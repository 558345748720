import {
    AmountFormatter,
    FracFormatter,
    NUMBER_FORMATS,
    numberComparator,
    QuantityFormatter,
    rel,
    RelativeFormatter,
    strictDivision,
    TimeFormatter
} from 'components/number-formatter/number-formatter';
import { percentage } from 'utils/helpers/number-utils';
import { TableCell, TableGameTitle } from '../report.styled-components';
import FormattedMessage from 'components/formatted-message';
const { float1 } = NUMBER_FORMATS;

export const HOME_TEAM_COLOR = '#3a66ab';
export const AWAY_TEAM_COLOR = '#c55267';

export const ReportShotGoalColumns = gameDetail => [
    {
        header: '',
        render: item => <TableCell isHeading>{item.label}</TableCell>,
        comparator: item => item?.label
    },
    {
        header: gameDetail?.homeTeam?.logo ? (
            <TableGameTitle>
                <img src={gameDetail?.homeTeam?.logo} width={20} alt="HOME" />
                <TableCell color={HOME_TEAM_COLOR}>{gameDetail?.homeTeam?.acronymEn ?? ''}</TableCell>
            </TableGameTitle>
        ) : (
            'HOME'
        ),
        render: item => <TableCell color={HOME_TEAM_COLOR}>{item?.home}</TableCell>,
        comparator: item => item?.home
    },
    {
        header: gameDetail?.awayTeam?.logo ? (
            <TableGameTitle>
                <img src={gameDetail?.awayTeam?.logo} width={20} alt="AWAY" />
                <TableCell color={AWAY_TEAM_COLOR}>{gameDetail?.awayTeam?.acronymEn ?? ''}</TableCell>
            </TableGameTitle>
        ) : (
            'AWAY'
        ),
        render: item => <TableCell color={AWAY_TEAM_COLOR}>{item?.away}</TableCell>,
        comparator: item => item?.away
    }
];

export const ReportLeaderStatColumns = isHomeTeam => [
    {
        header: '',
        render: item => <TableCell>{item.jersey}</TableCell>,
        comparator: item => item?.jersey
    },
    {
        header: '',
        render: item => (
            <TableCell color={isHomeTeam ? '#3a66ab' : '#c55267'}>
                {item?.firstNameEn ?? ''} {item?.lastNameEn ?? ''}
            </TableCell>
        ),
        comparator: item => item?.firstNameEn
    },
    {
        header: '',
        render: item => <TableCell>{item.count}</TableCell>,
        comparator: item => item?.count
    },
    {
        header: '',
        render: item => <TableCell>{item?.perc ? `${item?.perc}%` : ''}</TableCell>,
        comparator: item => item?.perc
    }
];

export const ReportPlayerMetricColumns = isHomeTeam => [
    {
        header: '#',
        render: item => <TableCell>{item.player?.jersey}</TableCell>,
        comparator: item => item?.player?.jersey
    },
    {
        header: 'Players',
        render: item => (
            <TableCell color={isHomeTeam ? '#3a66ab' : '#c55267'}>
                {item?.player?.firstNameEn ?? ''} {item?.player?.lastNameEn ?? ''}
            </TableCell>
        ),
        comparator: item => item?.player?.firstNameEn
    },
    {
        header: 'Goals',
        render: item => <TableCell>{item?.metrics?.goals}</TableCell>,
        comparator: item => item?.metrics?.goals
    },
    {
        header: 'Assist',
        render: item => <TableCell>{item?.metrics?.primary_assists + item?.metrics?.secondary_assists}</TableCell>,
        comparator: item => item?.metrics?.primary_assists + item?.metrics?.secondary_assists
    },
    {
        header: 'Points',
        render: item => (
            <TableCell>
                {item?.metrics?.goals + item?.metrics?.primary_assists + item?.metrics?.secondary_assists}
            </TableCell>
        ),
        comparator: item =>
            item?.metrics?.primary_assists + item?.metrics?.primary_assists + item?.metrics?.secondary_assists
    },
    {
        header: '+/-',
        render: item => <TableCell> {item?.metrics?.plus_minus}</TableCell>,
        comparator: item => item?.metrics?.plus_minus
    },
    {
        header: 'Time on Ice',
        render: item => (
            <TableCell>
                <TimeFormatter value={item?.metrics?.toi} timeFormat={NUMBER_FORMATS.timeMmSs} />
            </TableCell>
        ),
        comparator: item => item?.metrics?.toi
    },
    {
        header: 'Shifts',
        render: item => <TableCell>{item?.metrics?.shifts} </TableCell>,
        comparator: item => item?.metrics?.shifts
    },
    {
        header: 'Shot attempts',
        render: item => <TableCell>{item?.metrics?.shot_attempts}</TableCell>,
        comparator: item => item?.metrics?.shot_attempts
    },
    {
        header: 'Shots on goal',
        render: item => <TableCell> {item?.metrics?.shots_on_goal} </TableCell>,
        comparator: item => item?.metrics?.shots_on_goal
    },
    {
        header: 'Shots on goal from danger zone',
        render: item => <TableCell> {item?.metrics?.shots_on_goal_danger_zone} </TableCell>,
        comparator: item => item?.metrics?.shots_on_goal_danger_zone
    },
    {
        header: 'Shots blocked',
        render: item => <TableCell>{item?.metrics?.shots_blocked} </TableCell>,
        comparator: item => item?.metrics?.shots_blocked
    },
    {
        header: 'Blocked shots',
        render: item => <TableCell>{item?.metrics?.blocked_shots_by} </TableCell>,
        comparator: item => item?.metrics?.blocked_shots_by
    },
    {
        header: 'Hit taken',
        render: item => <TableCell>{item?.metrics?.hit_taken} </TableCell>,
        comparator: item => item?.metrics?.hit_taken
    },
    {
        header: 'Hit drawn',
        render: item => <TableCell>{item?.metrics?.hit_drawn} </TableCell>,
        comparator: item => item?.metrics?.hit_drawn
    },
    {
        header: 'Face off won',
        render: item => <TableCell>{item?.metrics?.face_off_won} </TableCell>,
        comparator: item => item?.metrics?.face_off_won
    },
    {
        header: 'Face off lost',
        render: item => <TableCell>{item?.metrics?.face_off_lost} </TableCell>,
        comparator: item => item?.metrics?.face_off_lost
    },
    {
        header: 'Face off won, %',
        render: item => (
            <TableCell>
                <FracFormatter
                    dividend={item?.metrics?.face_off_won * 100}
                    divider={item?.metrics?.face_off_won + item?.metrics?.face_off_lost}
                    measure={percentage}
                    format={float1}
                />
            </TableCell>
        ),
        comparator: item =>
            numberComparator(
                strictDivision(item?.metrics?.face_off_won, item?.metrics?.face_off_won + item?.metrics?.face_off_lost)
            )
    },
    {
        header: 'On ice xG for',
        render: item => (
            <TableCell>
                <FracFormatter dividend={item?.metrics?.on_ice_xg_for} divider={100} format={float1} />
            </TableCell>
        ),
        comparator: item => numberComparator(strictDivision(item?.metrics?.on_ice_xg_for / 100))
    },
    {
        header: 'On ice xG against',
        render: item => (
            <TableCell>
                <FracFormatter dividend={item?.metrics?.on_ice_xg_against} divider={100} format={float1} />
            </TableCell>
        ),
        comparator: item => numberComparator(strictDivision(item?.metrics?.on_ice_xg_against / 100))
    },
    {
        header: 'On ice xG for per 20',
        render: item => (
            <TableCell>
                <FracFormatter dividend={item?.metrics?.on_ice_xg_for_20} divider={100} format={float1} />
            </TableCell>
        ),
        comparator: item => numberComparator(strictDivision(item?.metrics?.on_ice_xg_for_20 / 100))
    },
    {
        header: 'On ice xG against per 20',
        render: item => (
            <TableCell>
                <FracFormatter dividend={item?.metrics?.on_ice_xg_against_20} divider={100} format={float1} />{' '}
            </TableCell>
        ),
        comparator: item => numberComparator(strictDivision(item?.metrics?.on_ice_xg_against_20 / 100))
    },
    {
        header: 'Xg',
        render: item => (
            <TableCell>
                <FracFormatter dividend={item?.metrics?.xg} divider={100} format={float1} />{' '}
            </TableCell>
        ),
        comparator: item => numberComparator(strictDivision(item?.metrics?.xg / 100))
    },
    {
        header: 'Xg per 20',
        render: item => (
            <TableCell>
                <FracFormatter dividend={item?.metrics?.on_ice_xg_20} divider={100} format={float1} />
            </TableCell>
        ),
        comparator: item => numberComparator(strictDivision(item?.metrics?.on_ice_xg_20 / 100))
    }
];

export const ReportPPLinesColumns = isHome => [
    {
        header: '#',
        render: item => <TableCell>PP{item?.id}</TableCell>,
        comparator: item => item?.id
    },
    {
        header: 'Combination',
        render: item => (
            <TableCell color={isHome ? HOME_TEAM_COLOR : AWAY_TEAM_COLOR}>{item?.linePresentation}</TableCell>
        ),
        comparator: item => item?.linePresentation
    },
    {
        header: 'Time on Ice',
        render: item => (
            <TableCell>
                <TimeFormatter value={item?.toi} />
            </TableCell>
        ),
        comparator: item => item?.toi
    },
    {
        header: 'Shots',
        render: item => (
            <TableCell>
                <RelativeFormatter toi={item.toi}>
                    <QuantityFormatter
                        value={item.shotsOnGoal + item.shotsBlocked + item.shotsMissed}
                        format={float1}
                    />
                </RelativeFormatter>
            </TableCell>
        )
    },
    {
        header: 'GF',
        render: item => <TableCell>{item.gf ?? '-'}</TableCell>,
        comparator: item => item?.gf
    },
    {
        header: 'Scoring chances',
        render: item => (
            <TableCell>
                <RelativeFormatter toi={item.toi}>
                    <QuantityFormatter value={item.scoringChancesFor} format={float1} />
                </RelativeFormatter>
            </TableCell>
        )
    }
];

export const ReportPKLinesColumns = isHome => [
    {
        header: '#',
        render: item => <TableCell>PK{item?.id}</TableCell>,
        comparator: item => item?.id
    },
    {
        header: 'Combination',
        render: item => (
            <TableCell color={isHome ? HOME_TEAM_COLOR : AWAY_TEAM_COLOR}>{item?.linePresentation}</TableCell>
        ),
        comparator: item => item?.linePresentation
    },
    {
        header: 'Time on Ice',
        render: item => (
            <TableCell>
                <TimeFormatter value={item?.toi} />
            </TableCell>
        ),
        comparator: item => item?.toi
    },
    {
        header: 'Cross passes',
        render: item => (
            <TableCell>
                <RelativeFormatter toi={item.toi}>
                    <QuantityFormatter value={item?.crossIcePasses} format={float1} />
                </RelativeFormatter>
            </TableCell>
        ),
        comparator: item => rel(item.toi, item?.crossIcePasses)
    },
    {
        header: 'Shots against',
        render: item => (
            <TableCell>
                <RelativeFormatter toi={item.toi}>
                    <QuantityFormatter value={item.shotsAgainst} format={float1} />
                </RelativeFormatter>
            </TableCell>
        )
    },

    {
        header: 'Scoring chances',
        render: item => (
            <TableCell>
                <RelativeFormatter toi={item.toi}>
                    <QuantityFormatter value={item.scoringChancesAgainst} format={float1} />
                </RelativeFormatter>
            </TableCell>
        ),
        comparator: item => item?.scoringChancesAgainst
    },
    {
        header: 'GA',
        render: item => <TableCell>{item.goalsAgainst ?? '-'}</TableCell>,
        comparator: item => item?.goalsAgainst
    },
    {
        header: 'Rebound Recovery,%',
        render: item => (
            <TableCell>
                <FracFormatter
                    dividend={item.reboundRecoveriesFor}
                    divider={item.reboundRecoveriesFor + item.reboundRecoveriesAgainst}
                    measure={percentage}
                    format={float1}
                />
            </TableCell>
        ),
        comparator: item =>
            numberComparator(
                strictDivision(item.reboundRecoveriesFor, item.reboundRecoveriesFor + item.reboundRecoveriesAgainst)
            )
    }
];

export const ReportSHootingColumns = isHomeTeam => [
    {
        header: '',
        render: item => <TableCell>{item.player?.playerPosition}</TableCell>,
        comparator: item => item.player?.playerPosition
    },
    {
        header: '#',
        render: item => <TableCell>{item.player?.jersey}</TableCell>,
        comparator: item => item?.player?.jersey
    },
    {
        header: 'Players',
        render: item => (
            <TableCell color={isHomeTeam ? HOME_TEAM_COLOR : AWAY_TEAM_COLOR}>
                {item?.player?.firstNameEn ?? ''} {item?.player?.lastNameEn ?? ''}
            </TableCell>
        ),
        comparator: item => item?.player?.firstNameEn
    },
    {
        header: <FormattedMessage id="statistics.shooting.shotAttempts" />,
        render: item => (
            <RelativeFormatter toi={item?.metrics?.toi}>
                <AmountFormatter value={item?.metrics?.shot_attempts} format={NUMBER_FORMATS.number} />
            </RelativeFormatter>
        ),
        comparator: item => rel(item?.metrics?.toi, item?.metrics?.shot_attempts)
    },
    {
        header: <FormattedMessage id="statistics.shooting.shotsOnGoal" />,
        render: item => (
            <RelativeFormatter toi={item?.metrics?.toi}>
                <AmountFormatter value={item?.metrics?.shots_on_goal} format={NUMBER_FORMATS.number} />
            </RelativeFormatter>
        ),
        comparator: item => rel(item?.metrics?.toi, item?.metrics?.shots_on_goal)
    },
    {
        header: <FormattedMessage id="statistics.shooting.scoringChances" />,
        render: item => (
            <RelativeFormatter toi={item?.metrics?.toi}>
                <AmountFormatter value={item?.metrics?.scoring_chances} format={NUMBER_FORMATS.number} />
            </RelativeFormatter>
        ),
        comparator: item => rel(item?.metrics?.toi, item?.metrics?.scoring_chances)
    },
    {
        header: <FormattedMessage id="statistics.shooting.goals" />,
        render: item => (
            <RelativeFormatter toi={item?.metrics?.toi}>
                <AmountFormatter value={item?.metrics?.goals} format={NUMBER_FORMATS.number} />
            </RelativeFormatter>
        ),
        comparator: item => rel(item?.metrics?.toi, item?.metrics?.goals)
    },
    {
        header: <FormattedMessage id="statistics.shooting.shooting" />,
        render: item => (
            <FracFormatter
                dividend={item?.metrics?.goals * 100}
                divider={item?.metrics?.shots_on_goal}
                measure={percentage}
                format={float1}
            />
        ),
        comparator: item => numberComparator(strictDivision(item?.metrics?.goals / item?.metrics?.shots_on_goal))
    },
    {
        header: <FormattedMessage id="statistics.shooting.blockedShots" />,
        render: item => <AmountFormatter value={item?.metrics?.shots_blocked} format={NUMBER_FORMATS.int} />,
        comparator: item => numberComparator(item?.metrics?.shots_blocked)
    },
    {
        header: <FormattedMessage id="statistics.shooting.shotsMissed" />,
        render: item => <AmountFormatter value={item?.metrics?.shots_missed} format={NUMBER_FORMATS.int} />,
        comparator: item => numberComparator(item?.metrics?.shots_missed)
    }
];

export const ReportSkaterAndGoalScoredColumns = [
    {
        header: '',
        render: item => <TableCell>{item.label}</TableCell>,
        comparator: item => item?.label
    },
    {
        header: 'Total',
        render: item => <TableCell>{item?.total}</TableCell>,
        comparator: item => item?.total
    },
    {
        header: '1st period',
        render: item => <TableCell>{item.period1}</TableCell>,
        comparator: item => item?.period1
    },
    {
        header: '2nd period',
        render: item => <TableCell>{item?.period2}</TableCell>,
        comparator: item => item?.period2
    },
    {
        header: '3rd period',
        render: item => <TableCell>{item?.period3}</TableCell>,
        comparator: item => item?.period3
    }
];
