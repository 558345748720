import {
    AMOUNT_MEASURES,
    AmountFormatter,
    FracFormatter,
    NUMBER_FORMATS,
    numberComparator,
    QuantityFormatter,
    RelativeFormatter,
    TimeFormatter
} from '../../../../../components/number-formatter/number-formatter';
import FormattedMessage from '../../../../../components/formatted-message';
import styles from './compare-lines.module.css';
import React from 'react';
import { extractedRelativeLineGameScoreV2 } from '../helpers/compare-lines-helpers';

const { percentage, hundredths } = AMOUNT_MEASURES;
const { float1, number } = NUMBER_FORMATS;

export const columnsCompareLinesFullscreen = ({ relativeScale, linesColumns }) => [
    {
        header: '#',
        render: row => (
            <span>
                <QuantityFormatter value={row.index} />
                {row.type === 'FORWARDS' ? (
                    <FormattedMessage id="skaters.compareLines.F" />
                ) : (
                    <FormattedMessage id="skaters.compareLines.D" />
                )}
            </span>
        ),
        comparator: row => numberComparator(row.index)
    },
    ...linesColumns,
    {
        header: <FormattedMessage id="skaters.compareLines.combination" />,
        render: row => <span className={styles.playerFont}>{row.title}</span>,
        props: {
            align: 'left'
        }
    },
    {
        header: <FormattedMessage id="skaters.compareLines.toi" />,
        render: row => <TimeFormatter value={row.stat.toi} />,
        comparator: row => numberComparator(row.stat.toi)
    },
    {
        header: <FormattedMessage id="skaters.compareLines.possessionTime" />,
        render: row => (
            <FracFormatter
                dividend={row.stat.possessionTime.for}
                divider={row.stat.possessionTime.for + row.stat.possessionTime.against}
                measure={percentage}
                format={float1}
            />
        ),
        comparator: row =>
            numberComparator(
                row.stat.possessionTime.for / (row.stat.possessionTime.for + row.stat.possessionTime.against)
            ),
        tooltip: row => ({
            stats: [
                {
                    header: <FormattedMessage id="skaters.compareLines.possessionTimeFor" />,
                    value: (
                        <RelativeFormatter toi={row.stat.toi} duration={relativeScale}>
                            <TimeFormatter value={row.stat.possessionTime.for} />
                        </RelativeFormatter>
                    )
                },
                {
                    header: <FormattedMessage id="skaters.compareLines.possessionTimeAgainst" />,
                    value: (
                        <RelativeFormatter toi={row.stat.toi} duration={relativeScale}>
                            <TimeFormatter value={row.stat.possessionTime.against} />
                        </RelativeFormatter>
                    )
                }
            ]
        })
    },
    {
        header: <FormattedMessage id="skaters.compareLines.OZpuckTime" />,
        render: (row, i, j) => (
            <FracFormatter
                dividend={row.stat.puckTimeOZ.for}
                divider={row.stat.puckTimeOZ.for + row.stat.puckTimeOZ.against}
                measure={percentage}
                format={float1}
            />
        ),
        comparator: row =>
            numberComparator(row.stat.puckTimeOZ.for / (row.stat.puckTimeOZ.for + row.stat.puckTimeOZ.against)),
        tooltip: row => ({
            stats: [
                {
                    header: <FormattedMessage id="skaters.compareLines.puckTimeOZFor" />,
                    value: (
                        <RelativeFormatter toi={row.stat.toi} duration={relativeScale}>
                            <TimeFormatter value={row.stat.puckTimeOZ.for} />
                        </RelativeFormatter>
                    )
                },
                {
                    header: <FormattedMessage id="skaters.compareLines.puckTimeOZAgainst" />,
                    value: (
                        <RelativeFormatter toi={row.stat.toi} duration={relativeScale}>
                            <TimeFormatter value={row.stat.puckTimeOZ.against} />
                        </RelativeFormatter>
                    )
                }
            ]
        })
    },
    {
        header: <FormattedMessage id="skaters.compareLines.corsi" />,
        render: (row, i, j) => (
            <FracFormatter
                dividend={row.stat.shots.for}
                divider={row.stat.shots.for + row.stat.shots.against}
                measure={percentage}
                format={float1}
            />
        ),
        comparator: row => numberComparator(row.stat.shots.for / (row.stat.shots.for + row.stat.shots.against)),
        tooltip: row => ({
            stats: [
                {
                    header: <FormattedMessage id="skaters.compareLines.shotsFor" />,
                    value: (
                        <RelativeFormatter toi={row.stat.toi} duration={relativeScale}>
                            <AmountFormatter value={row.stat.shots.for} format={number} />
                        </RelativeFormatter>
                    )
                },
                {
                    header: <FormattedMessage id="skaters.compareLines.shotsAgainst" />,
                    value: (
                        <RelativeFormatter toi={row.stat.toi} duration={relativeScale}>
                            <AmountFormatter value={row.stat.shots.against} format={number} />
                        </RelativeFormatter>
                    )
                }
            ]
        })
    },
    {
        header: <FormattedMessage id="skaters.compareLines.onIceXg" />,
        render: (row, i, j) => (
            <FracFormatter
                dividend={row.stat.xg.for}
                divider={row.stat.xg.for + row.stat.xg.against}
                measure={percentage}
                format={float1}
            />
        ),
        comparator: row => numberComparator(row.stat.xg.for / (row.stat.xg.for + row.stat.xg.against)),
        tooltip: row => ({
            stats: [
                {
                    header: <FormattedMessage id="skaters.compareLines.xgFor" />,
                    value: (
                        <RelativeFormatter toi={row.stat.toi} duration={relativeScale}>
                            <AmountFormatter value={row.stat.xg.for} measure={hundredths} format={float1} />
                        </RelativeFormatter>
                    )
                },
                {
                    header: <FormattedMessage id="skaters.compareLines.xgAgainst" />,
                    value: (
                        <RelativeFormatter toi={row.stat.toi} duration={relativeScale}>
                            <AmountFormatter value={row.stat.xg.against} measure={hundredths} format={float1} />
                        </RelativeFormatter>
                    )
                }
            ]
        })
    },
    {
        header: <FormattedMessage id="skaters.compareLines.gameScore" />,
        render: (row, i, j) => (
            <span>{!!row.stat.gameScoreV2 ? extractedRelativeLineGameScoreV2(relativeScale, row) : '_'}</span>
        ),

        comparator: row => numberComparator(!!row.stat.gameScoreV2 ? row.stat.gameScoreV2 : 0),
        tooltip: row => ({
            stats: [
                {
                    header: <FormattedMessage id="skaters.compareLines.gameScore" />,
                    value: (
                        <span>
                            {!!row.stat.gameScoreV2 ? extractedRelativeLineGameScoreV2(relativeScale, row) : '_'}
                        </span>
                    )
                }
            ]
        })
    }
];
