import keyBy from 'lodash/keyBy';
import { entityTypes } from '../constants/game-score-v2-constants';

export const extractedCombineLines = ({ gameScoreData, data, isReport = false }) => {
    const gameScoreByGameId = keyBy(gameScoreData, 'gameId');

    return data
        .filter(({ playerIds }) => playerIds.length > 1)
        .map(lineStat => {
            const { stat } = lineStat;
            const { entityIds, entityId, gameId } = stat;
            const gameScoreOfGame = gameScoreByGameId[gameId];

            if (!gameScoreOfGame || !gameScoreOfGame.gameScores || gameScoreOfGame.gameScores.length === 0) {
                return lineStat;
            }

            const linesGameScore = gameScoreOfGame.gameScores.filter(
                ({ entityType }) => entityType === entityTypes.LINE
            );
            const lineGameScoreStat = isReport
                ? linesGameScore.filter(item => entityId === item.entityId)
                : linesGameScore.filter(item => entityIds.includes(item.entityId));

            let totalGameScoreV2 = 0;
            lineGameScoreStat.forEach(item => (totalGameScoreV2 += item.gameScoreV2));

            if (!!lineGameScoreStat) {
                return { ...lineStat, stat: { ...lineStat.stat, gameScoreV2: totalGameScoreV2 } };
            }

            return lineStat;
        });
};

export const extractedRelativeLineGameScoreV2 = (relativeScale, row) => {
    const proportion = !relativeScale ? 1 : row.stat.toi / relativeScale;
    return (row.stat.gameScoreV2 / proportion).toFixed(2);
};
