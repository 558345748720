import {
    checkPointInCircle,
    FACE_OFF_CIRCLE_RADIUS,
    faceOffCoordinates,
    faceOffZones
} from 'constants/face-offs-config';

export const extractEventsByParams = ({ events, teamId, filteredType, gameIds }) => {
    const faceOffEvents =
        events?.data?.filter(
            ({ eventType, gameId }) => eventType === filteredType && gameIds.some(el => el === gameId)
        ) || [];

    return faceOffEvents.reduce(
        (acc, curr) => {
            const { teamId: currentTeamId } = curr;

            return teamId === currentTeamId
                ? {
                      ...acc,
                      home: [...acc.home, curr]
                  }
                : {
                      ...acc,
                      away: [...acc.away, curr]
                  };
        },
        { home: [], away: [] }
    );
};

export const extractPointsConfig = ({ faceOffs, isHome = true }) => {
    const points = {};

    const groupedEvents = {};

    const side = isHome ? 'win' : 'lose';

    for (const faceOff of faceOffs) {
        for (const zoneKey of faceOffZones) {
            const isInCircle = checkPointInCircle({
                pointX: faceOff.x,
                pointY: faceOff.y,
                circle0x: faceOffCoordinates[zoneKey].centeredX,
                circle0y: faceOffCoordinates[zoneKey].centeredY,
                circleRadius: FACE_OFF_CIRCLE_RADIUS
            });

            if (!isInCircle) {
                continue;
            }

            if (!points[zoneKey]) {
                points[zoneKey] = {};
                points[zoneKey][side] = 1;
            } else {
                points[zoneKey][side] = points[zoneKey][side] + 1;
            }

            if (!groupedEvents?.[zoneKey]) {
                groupedEvents[zoneKey] = {};
            }

            const isOnSide = groupedEvents?.[zoneKey]?.[side];

            if (isOnSide) {
                groupedEvents[zoneKey][side] = [...groupedEvents[zoneKey][side], faceOff];
            } else {
                groupedEvents[zoneKey][side] = [faceOff];
            }
        }
    }

    return { points, groupedEvents };
};
export const getFaceOffTotal = ({ home, away }) => {
    const win = home.length;
    const lose = away.length;

    if (win === 0 && away === 0) {
        return {
            win: 0,
            lose: 0,
            rate: 0,
            good: false,
            equality: true
        };
    }

    const rate = home.length / (home.length + away.length);

    return {
        win,
        lose,
        rate,
        good: rate > 0.5,
        equality: rate === 0.5 || (win === 0 && lose === 0)
    };
};

export const getPoints = ({ home = [], away = [] }) => {
    const { points: homePoints = {} } = extractPointsConfig({ faceOffs: [...home] });

    const { points: awayPoints = {} } = extractPointsConfig({
        faceOffs: [...away],
        isHome: false
    });

    const points = {};

    for (const faceOffKey of faceOffZones) {
        if (!points[faceOffKey]) {
            points[faceOffKey] = { win: 0, lose: 0 };
        }

        points[faceOffKey] = {
            win: homePoints?.[faceOffKey]?.['win'] || 0,
            lose: awayPoints?.[faceOffKey]?.['lose'] || 0
        };
    }

    return { points };
};
