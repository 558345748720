import isFiniteNumber from 'lodash/isFinite';

const strictDivision = (a, b) => {
    return isFiniteNumber(a) && isFiniteNumber(b) ? a / b : NaN;
};

/**
 * count elements on which `predicate` returns `true`
 * (if predicate specified, otherwise consider that predicate always return true)
 */
export const countWhen = predicate => ({
    initialValue: 0,
    reduce: (accumulator, item) => (predicate && !predicate(item) ? accumulator : accumulator + 1)
});

/**
 * alias for countWhen (prefer to use it while calling without predicate)
 */
export const count = countWhen;

/**
 * sum values returned by `numberSelector`
 */
export const sum = numberSelector => ({
    initialValue: 0,
    reduce: (acc, item) => {
        const value = numberSelector(item);
        return isFiniteNumber(value) ? acc + value : acc;
    }
});

/**
 * `predicate` - optional parameter (if specified using to filter items)
 * average values returned by `numberSelector`
 */
export const avg = (numberSelector, predicate) => ({
    initialValue: { size: 0, sum: 0 },
    reduce: (acc, item) => {
        const value = numberSelector(item);
        const isExcluded = predicate && !predicate(item);
        return isFiniteNumber(value) && !isExcluded ? { size: acc.size + 1, sum: acc.sum + value } : acc;
    },
    after: acc => strictDivision(acc.sum, acc.size)
});

/**
 * `predicate` - optional parameter (if specified using to filter items)
 * max values returned by `numberSelector`
 */
export const max = (numberSelector, predicate) => ({
    initialValue: null,
    reduce: (acc, item) => {
        const value = numberSelector(item);
        const isExcluded = predicate && !predicate(item);
        return isFiniteNumber(value) && !isExcluded && (acc === null || value > acc) ? value : acc;
    }
});

/**
 * `predicate` - optional parameter (if specified using to filter items)
 * min values returned by `numberSelector`
 */
export const min = (numberSelector, predicate) => ({
    initialValue: null,
    reduce: (acc, item) => {
        const value = numberSelector(item);
        const isExcluded = predicate && !predicate(item);
        return isFiniteNumber(value) && !isExcluded && (acc === null || value < acc) ? value : acc;
    }
});

/**
 * `predicate` - optional parameter (if specified using to filter items)
 * count unique values, returned by valueSelector
 */
export const countUnique = (valueSelector, predicate) => ({
    initialValue: [],
    reduce: (acc, item) => {
        const value = valueSelector(item);
        const isExcluded = predicate && !predicate(item);
        return isExcluded ? acc : [...acc, value];
    },
    after: acc => new Set(acc).size
});
