import React, { Component } from 'react';
import styles from './pagination.module.css';
import cx from 'classnames';

const maxNearestPages = 10;
const firstDisplayOffset = parseInt(maxNearestPages / 2);

const sequenceFrom = (offset, length) => new Array(length).fill(0).map((d, index) => index + offset);

export default class Pagination extends Component {
    getNearestRange() {
        const { number, totalPages } = this.props;

        const start = Math.max(number - firstDisplayOffset, 0);
        const length = Math.max(Math.min(totalPages - start, maxNearestPages), 1);
        return { start, length };
    }

    getNearestPages() {
        const { start, length } = this.getNearestRange();
        return sequenceFrom(start, length);
    }

    setPageNumber = number => {
        if (!this.props.setPageNumber || this.props.processing) return;
        this.props.setPageNumber(number);
    };

    incrementPage = () => {
        this.setPageNumber(this.props.number + 1);
    };

    firstPage = () => {
        this.setPageNumber(0);
    };

    lastPage = () => {
        this.setPageNumber(this.props.totalPages - 1);
    };

    decrementPage = () => {
        this.setPageNumber(this.props.number - 1);
    };

    isInitialized() {
        return this.props.totalPages > 1 && this.props.number !== null;
    }

    renderFirstPageLink() {
        const { start } = this.getNearestRange();
        if (start === 0) return null;
        return (
            <div className={styles.pageLink} onClick={this.firstPage}>
                1
            </div>
        );
    }

    renderPrevPageLink() {
        if (this.props.number === 0) return null;
        return (
            <div className={styles.pageLink} onClick={this.decrementPage}>
                {'<<'}
            </div>
        );
    }

    renderNearestToCurrentPageLinks() {
        return this.getNearestPages().map(page => (
            <div
                className={cx(styles.pageLink, { [styles.activeLink]: page === this.props.number })}
                key={page}
                onClick={() => this.setPageNumber(page)}
            >
                {page + 1}
            </div>
        ));
    }

    renderNextPageLink() {
        if (this.props.totalPages - 1 === this.props.number) return null;
        return (
            <div className={styles.pageLink} onClick={this.incrementPage}>
                {'>>'}
            </div>
        );
    }

    renderLastPageLink() {
        const { start, length } = this.getNearestRange();

        if (start + length === this.props.totalPages) return null;
        return (
            <div className={styles.pageLink} onClick={this.lastPage}>
                {this.props.totalPages}
            </div>
        );
    }

    render() {
        if (!this.isInitialized()) return null;

        return (
            !this.props.processing && (
                <div className={styles.root}>
                    {this.renderFirstPageLink()}
                    {this.renderPrevPageLink()}
                    {this.renderNearestToCurrentPageLinks()}
                    {this.renderNextPageLink()}
                    {this.renderLastPageLink()}
                </div>
            )
        );
    }
}
