import { HockeyMapSpecialFilters, SpecialFilterControl } from './shot-location-styled-components';
import FormattedMessage from '../../../components/formatted-message';
import React from 'react';
import { JERSEYS } from './special-filters-constants';

export const SpecialFiltersControls = ({
    specialFilterTabs,
    activeSubFilters,
    handleSelectSubFilters,
    jerseysSwitchIsVisible,
    allJerseysShow,
    handleJerseyVisibility
}) => (
    <HockeyMapSpecialFilters>
        {specialFilterTabs.map(({ name }, tabKey) => (
            <SpecialFilterControl
                key={`tabKey_${tabKey}`}
                onClick={() => handleSelectSubFilters(name)}
                isActive={activeSubFilters.includes(name)}
            >
                <FormattedMessage id={`shotLocation.subFilters.${name}`} />
            </SpecialFilterControl>
        ))}
        {!!jerseysSwitchIsVisible && (
            <SpecialFilterControl key={`tabKey_jerseys`} onClick={handleJerseyVisibility} isActive={!!allJerseysShow}>
                <FormattedMessage id={`shotLocation.subFilters.${JERSEYS}`} />
            </SpecialFilterControl>
        )}
    </HockeyMapSpecialFilters>
);
