/* eslint-disable eqeqeq */
import { playerIcon } from './players';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fix, hover, unfix, unhover } from './players-module';
import cx from 'classnames';
import styles from './players-container.module.css';
import { againstPic } from '../../constants/logos';
import { addDragAndDropListeners } from '../../widgets/shot-map/drag-and-drop-listeners';
import { buildTranslation, getTranslationFromString } from '../../utils/shot-point/shot-point';

const DEFAULT_RADIUS_PX = 10;

export const playerLabelStyle = {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '12px',
    fontWeight: 600,
    fill: '#000',
    fillOpacity: 0.8,
    stroke: '#fff',
    strokeOpacity: 0.8,
    strokeWidth: 1.5,
    paintOrder: 'stroke',
    pointerEvents: 'none',
    userSelect: 'none'
};

function playerSvgIcon(position, props) {
    let attrs;

    switch (position) {
        case 'G':
            attrs = { ...props, x: -30, y: -30 };
            break;

        case 'D':
            attrs = { ...props, className: cx(props.className, styles.defenceman) };
            break;

        case 'F':
            attrs = { ...props, cx: 0, cy: 0 };
            break;

        default:
    }

    return playerIcon(position)(attrs);
}

const getShotClassName = (disabled, active, inUserTeam, transparentShot) => {
    if (!!transparentShot) {
        return styles.transparentShot;
    }

    if (!!disabled && !active) {
        return styles.disabled;
    }

    if (!!inUserTeam && !active) {
        return styles.for;
    }
    if (!inUserTeam && !active) {
        return styles.against;
    }
    if (!!inUserTeam && !!active) {
        return styles.forActive;
    }

    return styles.againstActive;
};

const Shot = ({
    r,
    homePlayersNumber,
    awayPlayersNumber,
    shotOnGoal,
    stroke,
    className,
    lowOpacity,
    highOpacity,
    goal,
    transparentShot
}) => (
    <circle
        r={r}
        strokeWidth={
            !!transparentShot ? '0px' : !!goal ? '0.15em' : homePlayersNumber === awayPlayersNumber ? '0px' : '1px'
        }
        stroke={!!transparentShot ? 'rgba(0,0,0,0)' : stroke}
        opacity={highOpacity || (shotOnGoal && !lowOpacity) ? 1 : 0.3}
        className={className}
    />
);

/**
 * @todo refactor or deprecate bad-designed component
 */

class Element extends Component {
    getTranslation = () => getTranslationFromString(this.element.getAttribute('transform'));
    setTranslation = position => {
        this.element.setAttribute('transform', buildTranslation(position));
    };
    onDrag = () => {
        this.props.setEditingId(this.props.id);
        this.cloneElement = this.element.cloneNode(true);
        this.element.parentElement.appendChild(this.cloneElement);
    };
    onDraw = position => {
        this.setTranslation(position);
    };
    onDrop = position => {
        this.props.onDrop(this.props.id, position);
        this.props.setEditingId(null);
        this.element.parentElement.removeChild(this.cloneElement);
    };

    componentDidMount() {
        this.update();
        if (this.props.editable) {
            this.removeDragAndDropListeners = addDragAndDropListeners(
                this.element,
                this.props.svgRef,
                this.getTranslation,
                this.onDrag,
                this.onDraw,
                this.onDrop
            );
        }
    }

    componentWillUnmount() {
        this.props.editable && this.removeDragAndDropListeners();
    }

    componentDidUpdate() {
        this.update();
    }

    onMouseEnter = event => {
        if (!('ontouchstart' in window)) {
            if (this.locked) return;
            const { onMouseEnter, hover, id, elementId } = this.props;
            this.locked = true;
            onMouseEnter && onMouseEnter(event);
            hover(elementId, id);
        }
    };
    onMouseLeave = event => {
        if (!('ontouchstart' in window)) {
            if (this.locked) return;
            const { onMouseLeave, unhover, id } = this.props;
            this.locked = true;
            onMouseLeave && onMouseLeave(event);
            unhover(id);
        }
    };
    onClick = event => {
        if (this.locked) return;
        const { onClick, fix, unfix, id, fixed } = this.props;
        this.locked = true;
        onClick && onClick(event);

        if (fixed) {
            unfix(id);
        } else {
            fix(id);
        }
    };

    update() {
        this.locked = false;
        this.element.removeEventListener('mouseenter', this.onMouseEnter);
        this.element.removeEventListener('mouseleave', this.onMouseLeave);
        this.element.removeEventListener('click', this.onClick);
        this.element.addEventListener('mouseenter', this.onMouseEnter);
        this.element.addEventListener('mouseleave', this.onMouseLeave);
        this.element.addEventListener('click', this.onClick);
    }

    render() {
        const { type } = this.props;

        if (type === 'player') {
            return this.renderPlayer();
        } else {
            return this.renderShot();
        }
    }

    renderShot() {
        const {
            active,
            disabled,
            inUserTeam,
            jersey,
            oneSide,
            reverted,
            transform,
            goal,
            r,
            homePlayersNumber,
            awayPlayersNumber,
            shotOnGoal,
            stroke,
            editingId,
            id,
            allJerseysShow,
            transparentShot
        } = this.props;

        const radius = isNaN(r) ? DEFAULT_RADIUS_PX : r;

        const jerseyText = (
            <text
                className={styles.jersey}
                fill="#fff"
                fontFamily="Open Sans"
                fontWeight={600}
                fontSize={radius + 1}
                transform={oneSide && (reverted ? 'rotate(-90)' : 'rotate(90)')}
                textAnchor="middle"
                alignmentBaseline="central"
            >
                {jersey}
            </text>
        );

        const lowOpacity = editingId && editingId !== id;
        const highOpacity = editingId === id;
        const shotProps = {
            r: radius,
            homePlayersNumber,
            awayPlayersNumber,
            shotOnGoal,
            stroke,
            lowOpacity,
            highOpacity,
            goal,
            transparentShot
        };

        const jerseyPresentation = allJerseysShow ? jerseyText : (active || !disabled) && goal && jerseyText;

        return (
            <g ref={c => (this.element = c)} transform={transform}>
                <Shot {...shotProps} className={getShotClassName(disabled, active, inUserTeam, transparentShot)} />
                {jerseyPresentation}
            </g>
        );
    }

    renderPlayer() {
        const {
            active,
            disabled,
            position,
            inUserTeam,
            jersey,
            transform,
            isSingleTeamView,
            teamLogo,
            jerseyShown
        } = this.props;
        const textYOffset = position === 'D' ? 8 : 0;
        const className = getShotClassName(disabled, active, inUserTeam);
        const secondary = !active && disabled;
        const playerIconClassNames = cx(className, styles.playerIcon, {
            [styles.playerIcon_secondary]: secondary
        });
        if (!isSingleTeamView)
            return (
                <g ref={c => (this.element = c)} transform={transform}>
                    <image href={teamLogo || againstPic} transform={'translate(-30, -30)'} width="60" height="60" />
                    {!!jerseyShown && (
                        <text
                            x={0}
                            y={30}
                            style={{ ...playerLabelStyle, fontSize: '32px' }}
                            textAnchor="middle"
                            alignmentBaseline="hanging"
                        >
                            {jersey}
                        </text>
                    )}
                </g>
            );
        return (
            <g ref={c => (this.element = c)} transform={transform}>
                {playerSvgIcon(position, {
                    className: playerIconClassNames
                })}

                <text
                    x="0"
                    y={textYOffset}
                    className={styles.jersey}
                    fill="#fff"
                    fontFamily="Open Sans"
                    fontWeight={600}
                    fontSize={32}
                    textAnchor="middle"
                    alignmentBaseline="central"
                >
                    {jersey}
                </text>
            </g>
        );
    }
}

function mapStateToProps(state) {
    return {
        highlighted: state.charts.highlightedPlayers
    };
}

/**
 * @todo refactor or deprecate bad-designed component
 */

class ChartContainer extends Component {
    state = { editingId: null };

    setEditingId = id => {
        this.setState({ editingId: id });
    };

    getHighlighted = () => {
        const { id, highlighted } = this.props;
        return highlighted[id] || {};
    };

    render() {
        const {
            data,
            type,
            hover,
            unhover,
            fix,
            unfix,
            svgRef,
            editable,
            onDropPoint,
            jerseyShown,
            allJerseysShow
        } = this.props;
        const inheritedProps = {
            type,
            hover,
            unhover,
            fix,
            unfix
        };
        const { id: highlightedId, fixed } = this.getHighlighted();

        const highlightedToEndComparator = (o1, o2) => {
            return Number(o1.elementId === highlightedId) - Number(o2.elementId === highlightedId);
        };

        const sortedElements = highlightedId ? [...(data ?? [])].sort(highlightedToEndComparator) : data;
        return (
            <g>
                {sortedElements.map(elem => (
                    <Element
                        {...elem}
                        {...inheritedProps}
                        key={elem.elementId}
                        active={elem.elementId === highlightedId}
                        fixed={fixed}
                        svgRef={svgRef}
                        editable={editable}
                        jerseyShown={jerseyShown}
                        onDrop={onDropPoint}
                        setEditingId={this.setEditingId}
                        editingId={this.state.editingId}
                        allJerseysShow={allJerseysShow}
                    />
                ))}
            </g>
        );
    }
}

ChartContainer = connect(
    mapStateToProps,
    {
        hover,
        unhover,
        fix,
        unfix
    }
)(ChartContainer);
export { ChartContainer };
