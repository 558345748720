import styled from 'styled-components/macro';
import { mobileMediaCondition } from '../../../../../../utils/helpers/device-utils';

export const InputSliderContainer = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    width: 500px;

    @media ${mobileMediaCondition} {
        width: 270px;
    }
`;

export const InputSliderLabel = styled.h3`
    width: 100px;

    text-transform: capitalize;

    @media ${mobileMediaCondition} {
        width: 70px;
    }
`;

export const InputSliderTrack = styled.div`
    width: 100%;
    height: 6px;
    margin: 10px 0;

    background: linear-gradient(to right, #ddd, #007bff, #ddd);
`;

export const InputSliderThumb = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;

    border-radius: 50%;

    color: #fff;
    font-size: 12px;

    background-color: #007bff;
    cursor: pointer;
`;
