import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { allGamePlayersWithProfilesSelector } from 'containers/widgets-stats/selectors/game-players';

import FaceOffMap from '../../face-off-map/face-off-map';
import { faceOffEventsSelector } from './selectors';
import { getAvailableByPlayersFaceOffs, getFaceOffMapPoints } from './utils';

const ReportFaceOffsByGames = ({ gameIds = [], hasPlayerFilter = true, playerId }) => {
    const [selectedIds, setSelectedIds] = useState([]);

    const { home: homeFaceOffs, away: awayFaceOffs } = useSelector(faceOffEventsSelector);
    const allGamePlayers = useSelector(allGamePlayersWithProfilesSelector);
    const { processing, data } = allGamePlayers;

    const isReady = Boolean(gameIds.length) && Boolean(allGamePlayers?.data?.length);

    useEffect(() => {
        if (!playerId) {
            return;
        }
        setSelectedIds([Number(playerId)]);
    }, [playerId]);

    const filteredPlayers = useMemo(() => {
        if (processing || data.length === 0 || (!selectedIds || selectedIds.length === 0)) {
            return [];
        }

        return data.filter(({ playerId }) => {
            if (playerId && selectedIds) {
                return selectedIds.some(id => playerId === id);
            } else return false;
        });
    }, [allGamePlayers, selectedIds]);

    const points = useMemo(() => {
        if (!filteredPlayers || filteredPlayers.length === 0) {
            return getFaceOffMapPoints({
                home: homeFaceOffs,
                away: awayFaceOffs
            });
        }

        const availableGamePlayerIds = filteredPlayers.map(({ gamePlayerId }) => gamePlayerId);

        return getFaceOffMapPoints({
            home: getAvailableByPlayersFaceOffs(homeFaceOffs, availableGamePlayerIds),
            away: getAvailableByPlayersFaceOffs(awayFaceOffs, availableGamePlayerIds)
        });
    }, [homeFaceOffs, awayFaceOffs, filteredPlayers]);

    const handlePlayersSelect = useCallback(e => {
        setSelectedIds(e);
    }, []);

    const sortedAllPlayers = useMemo(
        () =>
            [...(allGamePlayers?.data || [])].sort((el1, el2) => {
                if (!el1?.profile || !el2?.profile) {
                    return 0;
                }

                if (el1.profile.lastname && el2.profile.lastname) {
                    return el1.profile.lastname > el2.profile.lastname
                        ? 1
                        : el1.profile.lastname < el2.profile.lastname
                        ? -1
                        : 0;
                }

                return 0;
            }),
        [allGamePlayers]
    );

    return (
        <FaceOffMap
            hasPlayerFilter={hasPlayerFilter}
            processing={!isReady}
            data={points?.data}
            allPlayers={sortedAllPlayers}
            onPlayersSelect={handlePlayersSelect}
        />
    );
};

export default ReportFaceOffsByGames;
