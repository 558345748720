import React, { Component } from 'react';
import styles from './hockey-map.module.css';
import { ICE_RINK_SIZES } from '../../constants/ice-rink-settings';
/*  Формулы в стандартных декартовых координатах (в svg координатах нужно домножить на -1 и добавить x и  y offset)*/
// f(x) = ry/rx*sqrt(rx^2-x^2) - верхний правый эллипс x in [0, rx]
// f(x) = -ry/rx*sqrt(rx^2-x^2) - нижний верхний эллипс x in [0, rx]
// f(x) = ry/rx*sqrt(rx^2-x^2) - верхний левый эллипс x in [-rx, 0]
// f(x) = -ry/rx*sqrt(rx^2-x^2) - нижний левый эллипс x in [-rx, 0]

/*Формулы в SVG координатах*/
// f(x) = ry-ry/rx*sqrt(rx^2-(x-rx)^2) - верхний левый эллипс if x in [0, rx] else 0
// f(x) = height-ry+ ry/rx*sqrt(rx^2-(x-rx)^2) - нижний левый эллипс if x in [0, rx] else height
// f(x) = ry-ry/rx*sqrt(rx^2-(x+rx-width)^2) - верхний правй эллипс if x in [width-rx, width] else 0
// f(x) = height-ry+ ry/rx*sqrt(rx^2-(x+rx - width)^2) - нижний правый эллипс if x in [width-rx, width] else height

const topLeftEllipse = (rx, ry, width, height, x) => ry - (ry / rx) * Math.sqrt(rx * rx - (x - rx) * (x - rx));
const bottomLeftEllipse = (rx, ry, width, height, x) =>
    height - ry + (ry / rx) * Math.sqrt(rx * rx - (x - rx) * (x - rx));
const topRightEllipse = (rx, ry, width, height, x) =>
    ry - (ry / rx) * Math.sqrt(rx * rx - (x + rx - width) * (x + rx - width));
const bottomRightEllipse = (rx, ry, width, height, x) =>
    height - ry + (ry / rx) * Math.sqrt(rx * rx - (x + rx - width) * (x + rx - width));

export function topIntersect(rx, ry, width, height, x) {
    if (x < rx) {
        return topLeftEllipse(rx, ry, width, height, x);
    } else if (x >= rx && x <= width - rx) {
        return 0;
    } else {
        return topRightEllipse(rx, ry, width, height, x);
    }
}

export function bottomIntersect(rx, ry, width, height, x) {
    if (x < rx) {
        return bottomLeftEllipse(rx, ry, width, height, x);
    } else if (x >= rx && x <= width - rx) {
        return height;
    } else {
        return bottomRightEllipse(rx, ry, width, height, x);
    }
}

export function getBorderRy(height) {
    return (height * 80) / ICE_RINK_SIZES.height;
}

export function getBorderRx(width) {
    return (width * 80) / ICE_RINK_SIZES.width;
}

const gateX = 2650;
const zoneBorderX = 750;
const rinkWidth = 6000;

export function getGateLineOffset(width) {
    const gateOffset = rinkWidth / 2 - gateX;
    return (width * gateOffset) / rinkWidth;
}

export function getZoneBorderOffset(width) {
    return (width * zoneBorderX) / rinkWidth;
}

function getCircleOffset(height) {
    return (height * 7) / 31;
}

function getThrowZoneOffset(width) {
    return (width * 7) / 73;
}

function getGateSize(height) {
    return (height * 24) / 310;
}

const getTransformedSize = (horizontalSize, verticalSize) => ({
    width: verticalSize,
    height: horizontalSize / 2
});

const pointR = 4;
const signX = 8;
const signY = 4;
const gateWidth = 4;

// original:    (0, 0) x (730, 310)
// translate: (311, 1) x (1041, 311)
// rotate:      (1, 1) x (311, 731)
// viewBox:     (0, 0) x (312, 412)
const revertedTransform = 'translate(311,1) rotate(90)';

// original:    (0, 0) x (730, 310)
// translate: (1, 411) x (731, 721)
// rotate:   (1, -279) x (311, 411)
// viewBox:     (0, 0) x (312, 412)
const oneSideTransform = 'translate(1,411) rotate(-90)';

// original:    (0, 0) x (730, 310)
// translate:   (1, 1) x (731, 311)
// viewBox:     (0, 0) x (732, 312)
const basicTransform = 'translate(1,1)';

export default class HockeyMap extends Component {
    render() {
        const {
            children,
            oneSide,
            onClick,
            className,
            withGates = true,
            transparent,
            reverted,
            rinkStroke,
            rinkStrokeWidth,
            width: svgWidth,
            height: svgHeight,
            maxWidth: svgMaxWidth,
            maxHeight: svgMaxHeight,
            svgRef,
            withPadding = true
        } = this.props;

        const svgMaxSize =
            svgMaxWidth && svgMaxHeight && oneSide
                ? getTransformedSize(svgMaxWidth, svgMaxHeight)
                : { width: svgMaxWidth, height: svgMaxHeight };
        const svgStrictSize =
            svgWidth && svgHeight && oneSide
                ? getTransformedSize(svgWidth, svgHeight)
                : { width: svgWidth, height: svgHeight };

        const width = ICE_RINK_SIZES.width;
        const height = ICE_RINK_SIZES.height;
        const rx = getBorderRx(width);
        const ry = getBorderRy(height);
        const d = getGateLineOffset(width);
        const d2 = getZoneBorderOffset(width);
        const dy = getCircleOffset(height);
        const xz = getThrowZoneOffset(width);
        const gw = getGateSize(height);
        return (
            <svg
                style={{
                    width: '100%',
                    maxWidth: svgMaxSize.width,
                    maxHeight: svgMaxSize.height,
                    padding: withPadding ? '10px 15px 15px' : ''
                }}
                {...svgStrictSize}
                className={`${styles.map} ${className || ''}`}
                onClick={onClick}
                viewBox={oneSide ? '0 0 312 412' : '0 0 732 312'}
                preserveAspectRatio="xMidYMid meet"
                ref={svgRef}
            >
                <g transform={oneSide ? (reverted ? revertedTransform : oneSideTransform) : basicTransform}>
                    {/*Field rect*/}
                    <rect
                        x="0"
                        y="0"
                        width={width}
                        height={height}
                        rx={rx}
                        ry={ry}
                        style={{ opacity: transparent ? 0.3 : 1 }}
                        className={`${styles.field} ${!rinkStroke ? styles.rinkStroke : ''}`}
                        stroke={rinkStroke}
                        strokeWidth={rinkStrokeWidth || '2'}
                    />

                    {/*Center*/}
                    <g>
                        {/*Center circle*/}
                        <circle
                            cx={width / 2}
                            cy={height / 2}
                            r={height / 6}
                            style={{ opacity: transparent ? 0 : 1 }}
                            className={styles.centerCircle}
                        />
                        {/*Center line*/}
                        <line x1={width / 2} y1="0" x2={width / 2} y2={height} className={styles.line} fill="none" />
                        {/*Center point*/}
                        <circle cx={width / 2} cy={height / 2} r={pointR} className={styles.faceoffCircle} />
                        {/*Left center line*/}
                        <line
                            x1={width / 2 - d2}
                            y1="0"
                            x2={width / 2 - d2}
                            y2={height}
                            fill="none"
                            className={styles.line}
                        />
                        {/*Center top left point*/}
                        <circle
                            cx={width / 2 - d2 + 10}
                            cy={height / 2 - dy}
                            r={pointR}
                            className={styles.faceoffCircle}
                        />
                        {/*Center bottom left point*/}
                        <circle
                            cx={width / 2 - d2 + 10}
                            cy={height / 2 + dy}
                            r={pointR}
                            className={styles.faceoffCircle}
                        />
                    </g>

                    {/*Left side*/}
                    <g>
                        {/*Gate lines*/}
                        <line
                            x1={d}
                            y1={topIntersect(rx, ry, width, height, d)}
                            x2={d}
                            y2={bottomIntersect(rx, ry, width, height, d)}
                            className={styles.line}
                            fill="none"
                        />
                        {/*Left top circle*/}
                        <circle
                            cx={d + xz}
                            cy={height / 2 - dy}
                            r={height / 6}
                            style={{ opacity: transparent ? 0 : 1 }}
                            className={styles.centerCircle}
                        />
                        {/*Left top point*/}
                        <circle cx={d + xz} cy={height / 2 - dy} r={pointR} className={styles.faceoffCircle} />
                        {/*Left top sign left-top*/}
                        <rect
                            x={d + xz - signX * 3}
                            y={height / 2 - dy - signY * 3}
                            width={signX * 2}
                            height={signY * 2}
                            style={{ strokeDasharray: `0, ${2 * signX}, ${2 * signY}` }}
                            className={styles.rectDecoration}
                        />
                        {/*Left top sign left-bottom*/}
                        <rect
                            x={d + xz - signX * 3}
                            y={height / 2 - dy + signY}
                            width={signX * 2}
                            height={signY * 2}
                            style={{ strokeDasharray: `${2 * signX + 2 * signY}, ${2 * signX + 2 * signY}` }}
                            className={styles.rectDecoration}
                        />
                        {/*Left top sign right-bottom*/}
                        <rect
                            x={d + xz + signX}
                            y={height / 2 - dy + signY}
                            width={signX * 2}
                            height={signY * 2}
                            style={{
                                strokeDasharray: `${2 * signX}, ${2 * signX + 2 * signY},${2 * signX + 2 * signY}`
                            }}
                            className={styles.rectDecoration}
                        />
                        {/*Left top sign right-top*/}
                        <rect
                            x={d + xz + signX}
                            y={height / 2 - dy - signY * 3}
                            width={signX * 2}
                            height={signY * 2}
                            style={{ strokeDasharray: `0, ${2 * signX + 2 * signY}, ${2 * signX + 2 * signY}` }}
                            className={styles.rectDecoration}
                        />

                        {/*Left bottom circle*/}
                        <circle
                            cx={d + xz}
                            cy={height / 2 + dy}
                            r={height / 6}
                            style={{ opacity: transparent ? 0 : 1 }}
                            className={styles.centerCircle}
                        />
                        {/*Left bottom point*/}
                        <circle cx={d + xz} cy={height / 2 + dy} r={pointR} className={styles.faceoffCircle} />
                        {/*Left bottom sign left-top*/}
                        <rect
                            x={d + xz - signX * 3}
                            y={height / 2 + dy - signY * 3}
                            width={signX * 2}
                            height={signY * 2}
                            style={{ strokeDasharray: `0, ${2 * signX}, ${2 * signY}` }}
                            className={styles.rectDecoration}
                        />
                        {/*Left bottom sign left-bottom*/}
                        <rect
                            x={d + xz - signX * 3}
                            y={height / 2 + dy + signY}
                            width={signX * 2}
                            height={signY * 2}
                            style={{ strokeDasharray: `${2 * signX + 2 * signY}, ${2 * signX + 2 * signY}` }}
                            className={styles.rectDecoration}
                        />
                        {/*Left bottom sign right-bottom*/}
                        <rect
                            x={d + xz + signX}
                            y={height / 2 + dy + signY}
                            width={signX * 2}
                            height={signY * 2}
                            style={{
                                strokeDasharray: `${2 * signX}, ${2 * signX + 2 * signY},${2 * signX + 2 * signY}`
                            }}
                            className={styles.rectDecoration}
                        />
                        {/*Left bottom sign right-top*/}
                        <rect
                            x={d + xz + signX}
                            y={height / 2 + dy - signY * 3}
                            width={signX * 2}
                            height={signY * 2}
                            style={{ strokeDasharray: `0, ${2 * signX + 2 * signY}, ${2 * signX + 2 * signY}` }}
                            className={styles.rectDecoration}
                        />
                        {/*Left gates*/}
                        <circle
                            cx={d}
                            cy={height / 2}
                            r={gw}
                            style={{ strokeDasharray: `${(gw * Math.PI) / 2}, ${gw * Math.PI}, ${gw * Math.PI}` }}
                            className={styles.rectDecoration}
                        />
                    </g>

                    {/*Right side*/}
                    {
                        <g>
                            <line
                                x1={width - d}
                                y1={topIntersect(rx, ry, width, height, width - d)}
                                x2={width - d}
                                fill="none"
                                y2={bottomIntersect(rx, ry, width, height, width - d)}
                                className={styles.line}
                            />

                            {/*Right center line*/}
                            <line
                                x1={width / 2 + d2}
                                y1="0"
                                x2={width / 2 + d2}
                                y2={height}
                                className={styles.line}
                                fill="none"
                            />

                            {/*Center bottom right point*/}
                            <circle
                                cx={width / 2 + d2 - 10}
                                cy={height / 2 + dy}
                                r={pointR}
                                className={styles.faceoffCircle}
                            />
                            {/*Center top right point*/}
                            <circle
                                cx={width / 2 + d2 - 10}
                                cy={height / 2 - dy}
                                r={pointR}
                                className={styles.faceoffCircle}
                            />

                            {/*Right bottom circle*/}
                            <circle
                                cx={width - d - xz}
                                cy={height / 2 + dy}
                                r={height / 6}
                                style={{ opacity: transparent ? 0 : 1 }}
                                className={styles.centerCircle}
                            />
                            {/*Right bottom point*/}
                            <circle
                                cx={width - d - xz}
                                cy={height / 2 + dy}
                                r={pointR}
                                className={styles.faceoffCircle}
                            />
                            {/*Right bottom sign left-top*/}
                            <rect
                                x={width - d - xz - signX * 3}
                                y={height / 2 + dy - signY * 3}
                                width={signX * 2}
                                height={signY * 2}
                                style={{ strokeDasharray: `0, ${2 * signX}, ${2 * signY}` }}
                                className={styles.rectDecoration}
                            />
                            {/*Right bottom sign left-bottom*/}
                            <rect
                                x={width - d - xz - signX * 3}
                                y={height / 2 + dy + signY}
                                width={signX * 2}
                                height={signY * 2}
                                style={{ strokeDasharray: `${2 * signX + 2 * signY}, ${2 * signX + 2 * signY}` }}
                                className={styles.rectDecoration}
                            />
                            {/*Right bottom sign right-bottom*/}
                            <rect
                                x={width - d - xz + signX}
                                y={height / 2 + dy + signY}
                                width={signX * 2}
                                height={signY * 2}
                                style={{
                                    strokeDasharray: `${2 * signX}, ${2 * signX + 2 * signY},${2 * signX + 2 * signY}`
                                }}
                                className={styles.rectDecoration}
                            />
                            {/*Right bottom sign right-top*/}
                            <rect
                                x={width - d - xz + signX}
                                y={height / 2 + dy - signY * 3}
                                width={signX * 2}
                                height={signY * 2}
                                style={{ strokeDasharray: `0, ${2 * signX + 2 * signY}, ${2 * signX + 2 * signY}` }}
                                className={styles.rectDecoration}
                            />

                            {/*Right top circle*/}
                            <circle
                                cx={width - d - xz}
                                cy={height / 2 - dy}
                                r={height / 6}
                                style={{ opacity: transparent ? 0 : 1 }}
                                className={styles.centerCircle}
                            />
                            {/*Right top point*/}
                            <circle
                                cx={width - d - xz}
                                cy={height / 2 - dy}
                                r={pointR}
                                className={styles.faceoffCircle}
                            />
                            <rect
                                x={width - d - xz - signX * 3}
                                y={height / 2 - dy - signY * 3}
                                width={signX * 2}
                                height={signY * 2}
                                style={{ strokeDasharray: `0, ${2 * signX}, ${2 * signY}` }}
                                className={styles.rectDecoration}
                            />
                            {/*Left bottom sign left-bottom*/}
                            <rect
                                x={width - d - xz - signX * 3}
                                y={height / 2 - dy + signY}
                                width={signX * 2}
                                height={signY * 2}
                                style={{ strokeDasharray: `${2 * signX + 2 * signY}, ${2 * signX + 2 * signY}` }}
                                className={styles.rectDecoration}
                            />
                            {/*Left bottom sign right-bottom*/}
                            <rect
                                x={width - d - xz + signX}
                                y={height / 2 - dy + signY}
                                width={signX * 2}
                                height={signY * 2}
                                style={{
                                    strokeDasharray: `${2 * signX}, ${2 * signX + 2 * signY},${2 * signX + 2 * signY}`
                                }}
                                className={styles.rectDecoration}
                            />
                            {/*Left bottom sign right-top*/}
                            <rect
                                x={width - d - xz + signX}
                                y={height / 2 - dy - signY * 3}
                                width={signX * 2}
                                height={signY * 2}
                                style={{ strokeDasharray: `0, ${2 * signX + 2 * signY}, ${2 * signX + 2 * signY}` }}
                                className={styles.rectDecoration}
                            />

                            {withGates && (
                                <rect
                                    width={gateWidth}
                                    height={gw}
                                    x={d - gateWidth}
                                    y={height / 2 - gw / 2}
                                    className={styles.forGates}
                                />
                            )}

                            {/*Right gates*/}
                            <circle
                                cx={width - d}
                                cy={height / 2}
                                r={gw}
                                style={{ strokeDasharray: `0, ${(gw * Math.PI) / 2}, ${(gw * Math.PI) / 2}` }}
                                className={styles.rectDecoration}
                            />
                            {withGates && (
                                <rect
                                    width={gateWidth}
                                    height={gw}
                                    x={width - d}
                                    y={height / 2 - gw / 2}
                                    className={styles.againstGates}
                                />
                            )}
                        </g>
                    }
                    {children}
                </g>
            </svg>
        );
    }
}
