import {
    boundShotMapPosition,
    mousePositionToShotMap,
    getVectorsSub,
    getVectorsSum
} from '../../utils/shot-point/shot-point';

const getMousePosition = e => ({ x: e.clientX, y: e.clientY });

const disableUserSelection = () => {
    document.body.style.userSelect = 'none';
};

const enableUserSelection = () => {
    document.body.style.userSelect = 'unset';
};

/// todo: remove listener
export const addDragAndDropListeners = (element, svgRef, getPosition, onDrag, onDraw, onDrop) => {
    const mouseDownListener = e => {
        const initMouseWindowPosition = getMousePosition(e);
        const initMouseSvgPosition = mousePositionToShotMap(initMouseWindowPosition, svgRef);
        const initPointSvgPosition = getPosition();

        const getPointSvgPosition = e => {
            const actualMousePosition = getMousePosition(e);
            const actualMouseSvgPosition = mousePositionToShotMap(actualMousePosition, svgRef);
            const mouseSvgVector = getVectorsSub(actualMouseSvgPosition, initMouseSvgPosition);
            const actualPointSvgPosition = getVectorsSum(initPointSvgPosition, mouseSvgVector);
            const boundedPointSvgPosition = boundShotMapPosition(actualPointSvgPosition);
            return boundedPointSvgPosition;
        };

        disableUserSelection();
        onDrag && onDrag(getPointSvgPosition(e));

        const dropHandler = e => {
            document.body.removeEventListener('mousemove', moveHandler);
            document.body.removeEventListener('mouseup', dropHandler);
            enableUserSelection();
            onDrop && onDrop(getPointSvgPosition(e));
        };

        const moveHandler = e => {
            if (e.buttons === 1) {
                onDraw && onDraw(getPointSvgPosition(e));
            } else {
                dropHandler(e);
            }
        };

        document.body.addEventListener('mousemove', moveHandler);
        document.body.addEventListener('mouseup', dropHandler);
    };

    element.addEventListener('mousedown', mouseDownListener);

    return () => {
        element.removeEventListener('mousedown', mouseDownListener);
    };
};
