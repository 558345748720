/* eslint-disable eqeqeq */
import React from 'react';
import ServiceIcon from 'components/svg-icons/service-icon';

const Forward = props => <circle cx="32" cy="32" r="30" {...props} />;

const Goaltender = props => <rect height="60" width="60" {...props} />;

const DefensemanPath =
    'M 36.8501 2.94606L 63.3823 53.9694C 64.7753 56.6484 63.7329 59.9494 61.0539 61.3425C ' +
    '60.2749 61.7476 59.4097 61.9591 58.5316 61.9591L 5.46728 61.9591C 2.44778 61.9591 -1.30409e-05 59.5113 ' +
    '-1.30409e-05 56.4918C -1.30409e-05 55.6137 0.211502 54.7485 0.616618 53.9694L 27.1488 2.94606C 28.5418 ' +
    '0.267107 31.8428 -0.775305 34.5218 0.617752C 35.5187 1.13616 36.3317 1.94913 36.8501 2.94606Z';

const Defenseman = props => <path d={DefensemanPath} {...props} />;

const Undefined = props => <ServiceIcon height="60" width="60" {...props} />;

export function playerIcon(playerPosition) {
    switch (playerPosition) {
        case 'G':
            return Goaltender;
        case 'D':
            return Defenseman;
        case 'F':
            return Forward;
        default:
            return Undefined;
    }
}

const Player = ({ position, jersey, inUserTeam, className, height, width, style }) => {
    const fillcolor = !!inUserTeam ? 'var(--forColor)' : 'var(--againstColor)';

    const textYOffset = position === 'D' ? 8 : 0;

    return (
        <svg height={height} width={width} style={style} className={className} viewBox="0 0 64 64">
            {playerIcon(position)({ fill: fillcolor })}
            <text
                x="32"
                y={32 + textYOffset}
                fill="#fff"
                fontFamily="Open Sans"
                fontWeight={600}
                fontSize={32}
                textAnchor="middle"
                alignmentBaseline="central"
            >
                {jersey}
            </text>
        </svg>
    );
};

export default Player;
