import { activeGameSelector } from 'containers/widgets-stats/selectors/active-game';
import { shotsSelector } from 'containers/widgets-stats/selectors/shots';

import { createSelector } from 'reselect';
import { createShotPointsSelector } from 'widgets/shot-map/shot-location/shot-location-selector';
import { REPORT_NODE_IDS } from './shots-summary';

export const initializedReportStatsSelector = state => {
    return (
        (state.widgetsStats &&
            REPORT_NODE_IDS.filter(nodeId => state.widgetsStats[nodeId] && !state.widgetsStats[nodeId].processing)
                .length) ||
        0
    );
};

export const reportGameSelector = createSelector(
    state => state.statsFilter.teamIds,
    state => initializedReportStatsSelector(state) > 0 && activeGameSelector(state),
    (teams, game) => {
        if (!teams || !game) return { processing: true };
        return game;
    }
);

export const reportFullShotsSelector = createShotPointsSelector(shotsSelector, true);
