import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import uniqBy from 'lodash/uniqBy';

import StyledModal from 'components/modal/styled-modal';
import Button from 'components/button/button';
import { GET, POST } from 'constants/http-methods';
import { notify } from 'redux/modules/notifications';
import { reFetchVideoConfig } from 'redux/modules/video-config';
import * as endpoints from 'utils/api/endpoints';
import { useFetchData } from 'utils/hooks/use-fetch-data';

import DualPointerSlider from './components/profile/input-slider.js/input-slider';
import { FormContainer, ModelFooter } from './edit-video-player-modal-styled-components';

import styles from './edit-email-modal.module.css';

const DEFAULT_SETTINGS = [
    {
        playlistName: 'GOALS',
        leftBorder: -7,
        rightBorder: 5
    },
    {
        playlistName: 'FACEOFFS',
        leftBorder: -7,
        rightBorder: 5
    },
    {
        playlistName: 'SHOTS',
        leftBorder: -7,
        rightBorder: 5
    },
    {
        playlistName: 'OTHER',
        leftBorder: -7,
        rightBorder: 5
    },
    {
        playlistName: 'SAVES',
        leftBorder: -7,
        rightBorder: 5
    }
];

const EditVideoPlayerModal = ({ isOpen, onClose }) => {
    const [videoPlayerConfigs, setVideoPlayerConfigs] = useState(DEFAULT_SETTINGS);
    const [isSubmit, setIsSubmit] = useState(false);

    const dispatch = useDispatch();

    const videoConfigHandler = useCallback(
        data => {
            const uniqVideoSettingData = uniqBy(data?.sort((a, b) => a.id - b.id), 'playlistName');
            setVideoPlayerConfigs(uniqVideoSettingData?.length ? uniqVideoSettingData : DEFAULT_SETTINGS);
            dispatch(reFetchVideoConfig(false));
        },
        [dispatch]
    );

    useFetchData({
        method: GET,
        url: endpoints.GetVideoPlayerRangeSettings,
        responseHandler: videoConfigHandler,
        initialCondition: isOpen
    });

    const handleClose = useCallback(() => {
        dispatch(reFetchVideoConfig(true));

        onClose?.();
    }, [onClose]);

    const handleSettingsSubmit = useCallback(() => {
        setIsSubmit(true);
    }, []);

    const handleSettingsSave = useCallback(
        response => {
            if (response?.length) {
                dispatch(notify('Success'));
                handleClose();
            }

            setIsSubmit(false);
        },
        [handleClose]
    );

    const { processing } = useFetchData({
        method: POST,
        url: endpoints.UpdateVideoPlayerRangeSettings,
        payload: videoPlayerConfigs,
        responseHandler: handleSettingsSave,
        initialCondition: isSubmit
    });

    const handleSliderChange = useCallback((fieldName, updatedValues) => {
        setVideoPlayerConfigs(vPConfig =>
            vPConfig.map(vpConfigData => {
                const isUpdated = vpConfigData.playlistName === fieldName;

                return {
                    ...vpConfigData,
                    leftBorder: isUpdated ? updatedValues.left : vpConfigData.leftBorder,
                    rightBorder: isUpdated ? updatedValues.right : vpConfigData.rightBorder
                };
            })
        );
    }, []);

    return (
        <StyledModal
            contentCss={`border-radius: 8px; box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);`}
            isOpen={isOpen}
            disableScroll
            onRequestClose={handleClose}
        >
            <div className={styles.main}>
                <FormContainer>
                    {videoPlayerConfigs?.map(config => (
                        <DualPointerSlider
                            name={config.playlistName}
                            title={config.playlistName}
                            currentValue={[config.leftBorder, config.rightBorder]}
                            onChange={handleSliderChange}
                        />
                    ))}

                    <ModelFooter>
                        <Button
                            className={styles.save}
                            type="submit"
                            onClick={handleSettingsSubmit}
                            disabled={processing}
                            isLoading={processing}
                        >
                            <span className={styles.buttonText}>Save</span>
                        </Button>

                        <Button className={styles.cancel} onClick={handleClose}>
                            <span className={styles.buttonText}>Cancel</span>
                        </Button>
                    </ModelFooter>
                </FormContainer>
            </div>
        </StyledModal>
    );
};

export default EditVideoPlayerModal;
