import styled from 'styled-components/macro';

export const FullSizeOverFlowableWrapper = styled('section')`
    max-width: 100%;
    max-height: 100%;

    @media print {
        overflow: hidden;
    }
`;
