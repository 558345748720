import React, { useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';

import { columnsCompareLinesFullscreen } from 'pages/game-center/analytics/skaters/compare-lines/compare-lines-fullscreen-columns';

import { useFetchData } from 'utils/hooks/use-fetch-data';
import { GET } from 'constants/http-methods';
import * as endpoints from 'utils/api/endpoints';
import Icon from 'components/icon';

import DataTable from 'components/table-with-tooltip/index';
import {
    AWAY_TEAM_COLOR,
    HOME_TEAM_COLOR,
    ReportLeaderStatColumns,
    ReportPKLinesColumns,
    ReportPlayerMetricColumns,
    ReportPPLinesColumns,
    ReportSHootingColumns,
    ReportShotGoalColumns
} from '../table-column/reports-table-column';
import ShotsSummary from '../shots-summary';
import ReportSectionHeader from '../sections/report-section-header';
import {
    useFetchFaceOffPeriodAndStrength,
    useFetchLeaderStats,
    useFetchLineXg,
    useFetchPlayerLines,
    useFetchPlayersMetrics,
    useFetchShotsAndGoal
} from '../hooks/report-hook';
import TeamLinesSection from '../sections/team-lines-section';

import {
    BlocksTitle,
    LeaderTableBlock,
    ReportBlock,
    ReportBlockFaceOff,
    ReportContainer,
    ReportLeaderBlock,
    ReportSection,
    TableCell,
    TableGameHeader,
    TableGameTitle,
    TableTitle,
    TeamTitle
} from '../report.styled-components';

import styles from '../sections/section.module.css';

const RENDER_TIME_OUT = 2000;

const TeamNewReport = ({ gameId, teamId, isView }) => {
    const [isShotsSummaryRendered, setIsShotsSummaryRendered] = useState(false);

    const { processing: isGameDetailsLoading, data: gameDetail } = useFetchData({
        method: GET,
        url: endpoints.getReportGameDetails(gameId),
        initialCondition: !!gameId
    });

    const { processing: isShotsAndGoalsLoading, data: shotsAndGoal } = useFetchShotsAndGoal(gameId);

    const {
        processing: isFaceOffPeriodAndStrengthLoading,
        data: faceOffPeriodAndStrengthData
    } = useFetchFaceOffPeriodAndStrength(gameId);

    const { processing: isPlayersProfileLoading, data: playersProfile } = useFetchData({
        method: GET,
        url: endpoints.getReportPlayersProfile(gameId),

        initialCondition: !!gameId
    });

    const { processing: isLeaderStatsLoading, data: leaderStatsTableData } = useFetchLeaderStats(
        gameId,
        playersProfile
    );

    const { processing: isPlayersMetricsLoading, data: playersMetricsData } = useFetchPlayersMetrics(
        gameId,
        gameDetail,
        playersProfile
    );

    const { processing: isLinesXGLoading, data: linesXGData } = useFetchLineXg(gameId, gameDetail, playersProfile);

    const { processing: isHomePpLinesLoading, data: homePpLinesData } = useFetchPlayerLines(
        endpoints.ppStats,
        gameId,
        gameDetail?.homeTeam?.id || teamId || 0,
        gameDetail,
        playersProfile
    );

    const { processing: isAwayPpLinesLoading, data: awayPpLinesData } = useFetchPlayerLines(
        endpoints.ppStats,
        gameId,
        gameDetail?.awayTeam?.id || 0,
        gameDetail,
        playersProfile
    );

    const { processing: isHomePkLinesLoading, data: homePkLinesData } = useFetchPlayerLines(
        endpoints.pkStats,
        gameId,
        gameDetail?.homeTeam?.id || teamId || 0,
        gameDetail,
        playersProfile
    );

    const { processing: isAwayPkLinesLoading, data: awayPkLinesData } = useFetchPlayerLines(
        endpoints.pkStats,
        gameId,
        gameDetail?.awayTeam?.id || 0,
        gameDetail,
        playersProfile
    );

    const isLoading = useMemo(
        () =>
            isShotsAndGoalsLoading ||
            isFaceOffPeriodAndStrengthLoading ||
            isPlayersProfileLoading ||
            isLeaderStatsLoading ||
            isPlayersMetricsLoading ||
            isLinesXGLoading ||
            isHomePpLinesLoading ||
            isAwayPpLinesLoading ||
            isHomePkLinesLoading ||
            isAwayPkLinesLoading ||
            isGameDetailsLoading ||
            !isShotsSummaryRendered,
        [
            isShotsAndGoalsLoading,
            isFaceOffPeriodAndStrengthLoading,
            isPlayersProfileLoading,
            isLeaderStatsLoading,
            isPlayersMetricsLoading,
            isLinesXGLoading,
            isHomePpLinesLoading,
            isAwayPpLinesLoading,
            isHomePkLinesLoading,
            isAwayPkLinesLoading,
            isGameDetailsLoading,
            isShotsSummaryRendered
        ]
    );

    const renderLoadingRef = useRef();

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (isView) {
            return;
        }

        renderLoadingRef.current = setTimeout(() => {
            window.print();
        }, RENDER_TIME_OUT);

        return () => clearTimeout(renderLoadingRef.current);
    }, [isLoading, isView]);

    const handleGameSummaryLoading = val => {
        setIsShotsSummaryRendered(val);
    };
    const lineXgTableColumns = isHome =>
        columnsCompareLinesFullscreen({
            linesColumns: [],
            relativeScale: undefined,
            isReport: true,
            isHome
        });

    return (
        <ReportContainer>
            <div className={cx(styles.loading, { [styles.loadingHide]: isView && !isLoading })}>
                <Icon type="spinner" pulse wrapped={false} size={5} />
            </div>
            <div className={cx(styles.reportContent, { [styles.reportVisible]: isView && !isLoading })}>
                <ReportSection>
                    <div className="row">
                        <ReportBlock className="col-4">
                            <BlocksTitle>Shots and goals scored</BlocksTitle>

                            <DataTable
                                className="reportTable"
                                columns={ReportShotGoalColumns(gameDetail)}
                                data={shotsAndGoal}
                            />
                            <ReportBlockFaceOff>
                                <BlocksTitle>Face-Offs</BlocksTitle>
                                <DataTable
                                    className="reportTable"
                                    columns={ReportShotGoalColumns(gameDetail)}
                                    data={faceOffPeriodAndStrengthData}
                                />
                            </ReportBlockFaceOff>
                        </ReportBlock>
                        <div className="col-4">
                            <ShotsSummary markComponentReady={handleGameSummaryLoading} gameDetail={gameDetail} />
                        </div>
                        <ReportBlock className="col-4">
                            <BlocksTitle>Leaders</BlocksTitle>
                            <TableGameHeader>
                                <TableGameTitle>
                                    <img src={gameDetail?.homeTeam?.logo} width={20} alt="HOME" />
                                    <TableCell color="#3a66ab">{gameDetail?.homeTeam?.acronymEn ?? 'HOME'}</TableCell>
                                </TableGameTitle>
                                <TableGameTitle>
                                    <img src={gameDetail?.awayTeam?.logo} width={20} alt="HOME" />
                                    <TableCell color="#c55267">{gameDetail?.awayTeam?.acronymEn ?? 'AWAY'}</TableCell>
                                </TableGameTitle>
                            </TableGameHeader>

                            {leaderStatsTableData?.map((el, elKey) => (
                                <div key={elKey} className="reportTable">
                                    <TableTitle>{el?.label}</TableTitle>
                                    <ReportLeaderBlock className="reportTable">
                                        <LeaderTableBlock className="reportTable">
                                            <DataTable
                                                className="reportTable"
                                                columns={ReportLeaderStatColumns(true)}
                                                data={el?.home}
                                            />
                                        </LeaderTableBlock>
                                        <LeaderTableBlock className="reportTable">
                                            <DataTable
                                                className="reportTable"
                                                columns={ReportLeaderStatColumns(false)}
                                                data={el?.away}
                                            />
                                        </LeaderTableBlock>
                                    </ReportLeaderBlock>
                                </div>
                            ))}
                        </ReportBlock>
                    </div>
                </ReportSection>

                <ReportSectionHeader>Report</ReportSectionHeader>
                {[HOME_TEAM_COLOR, AWAY_TEAM_COLOR].map((teamColor, index) => (
                    <ReportSection key={index}>
                        <div className="row">
                            <div className="col-12">
                                <BlocksTitle>
                                    <div>
                                        Player stats -{' '}
                                        <TeamTitle color={teamColor}>
                                            {index === 0
                                                ? gameDetail?.homeTeam.nameEn || 'HOME'
                                                : gameDetail?.awayTeam.nameEn || 'AWAY'}
                                        </TeamTitle>
                                    </div>
                                    <img
                                        src={index === 0 ? gameDetail?.homeTeam.logo : gameDetail?.awayTeam.logo}
                                        width={30}
                                        alt={index === 0 ? 'HOME' : 'AWAY'}
                                    />
                                </BlocksTitle>
                                <DataTable
                                    className="reportTable"
                                    columns={ReportPlayerMetricColumns(index === 0)}
                                    data={index === 0 ? playersMetricsData.home : playersMetricsData.away}
                                />
                            </div>
                        </div>
                    </ReportSection>
                ))}

                <ReportSectionHeader>Report</ReportSectionHeader>
                <TeamLinesSection
                    team={gameDetail?.homeTeam?.nameEn}
                    logo={gameDetail?.homeTeam?.logo}
                    color={HOME_TEAM_COLOR}
                    defenseLines={linesXGData?.defencemen?.defencemenHome}
                    forwardLines={linesXGData?.forward?.forwardHome}
                    ppLines={homePpLinesData}
                    pkLines={homePkLinesData}
                    lineXgColumns={lineXgTableColumns(true)}
                    ppColumns={ReportPPLinesColumns(true)}
                    pkColumns={ReportPKLinesColumns(true)}
                />
                <ReportSectionHeader>Report</ReportSectionHeader>
                <TeamLinesSection
                    team={gameDetail?.awayTeam?.nameEn}
                    logo={gameDetail?.awayTeam?.logo}
                    color={AWAY_TEAM_COLOR}
                    defenseLines={linesXGData?.defencemen?.defencemenAway}
                    forwardLines={linesXGData?.forward?.forwardAway}
                    ppLines={awayPpLinesData}
                    pkLines={awayPkLinesData}
                    lineXgColumns={lineXgTableColumns(false)}
                    ppColumns={ReportPPLinesColumns(false)}
                    pkColumns={ReportPKLinesColumns(false)}
                />

                <ReportSectionHeader>Report</ReportSectionHeader>
                <ReportSection>
                    <div className="row">
                        <div className="col-12">
                            {[HOME_TEAM_COLOR, AWAY_TEAM_COLOR].map((teamColor, index) => (
                                <ReportSection key={index}>
                                    <BlocksTitle>
                                        <div>
                                            Shots stats -{' '}
                                            <TeamTitle color={teamColor}>
                                                {index === 0
                                                    ? gameDetail?.homeTeam.nameEn || 'HOME'
                                                    : gameDetail?.awayTeam.nameEn || 'AWAY'}
                                            </TeamTitle>
                                        </div>
                                        <img
                                            src={index === 0 ? gameDetail?.homeTeam.logo : gameDetail?.awayTeam.logo}
                                            width={30}
                                            alt={index === 0 ? 'HOME' : 'AWAY'}
                                        />
                                    </BlocksTitle>
                                    <DataTable
                                        className="reportTable"
                                        columns={ReportSHootingColumns(index === 0)}
                                        data={index === 0 ? playersMetricsData?.home : playersMetricsData?.away}
                                    />
                                </ReportSection>
                            ))}
                        </div>
                    </div>
                </ReportSection>
            </div>
        </ReportContainer>
    );
};

export default TeamNewReport;
