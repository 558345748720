import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import HockeyMap from 'components/hockey-map/hockey-map';
import { actions as tooltipsActions } from 'containers/tooltip/module';
import TooltipController from 'containers/tooltip/tooltip-controller';
import { ChartContainer } from 'components/svg-icons/players-container';
import { fromShotmapPoint, toShotmapPoint } from 'utils/shot-point/shot-point';
import Loading from 'components/loading/loading';
import { ICE_RINK_SIZES } from 'constants/ice-rink-settings';

import { getStStroke } from '../helpers/shot-map-helpers';

import { HockeyMapWrapper } from './shot-location-styled-components';
import { SpecialFiltersControls } from './special-filters-controls';
import {
    BLOCKED,
    DEFLECTED,
    GOAL,
    JERSEYS_SWITCH_IS_VISIBLE,
    MISS,
    SHOT_ON_GOAL,
    SPECIAL_FILTERS_IS_AVAILABLE,
    specialFilterTabs
} from './special-filters-constants';
import { shotXgBoundsSelector } from './shot-location-selector';

const PICTURE_SIZE = { ...ICE_RINK_SIZES };

const { showTooltip, hideTooltip } = tooltipsActions;

const FADE_TIMEOUT_DELAY = 3000;

const minR = 8;
const maxR = 12;

const circleR = (xg, minXG, maxXG) => (!xg ? minR : minR + ((xg - minXG) / (maxXG - minXG)) * (maxR - minR));

const initialState = {
    activeSubFilters: [GOAL, SHOT_ON_GOAL, BLOCKED, DEFLECTED, MISS],
    allJerseysShow: false,
    activeShot: null
};

class ShotMap extends Component {
    constructor(props) {
        super(props);
        const { showTooltip, hideTooltip } = props;
        this.tooltipController = new TooltipController();
        this.tooltipController.setRenderTooltip(this.renderTooltip);
        this.tooltipController.setMarker('shot');
        this.tooltipController.setShowTooltip(showTooltip);
        this.tooltipController.setHideTooltip(hideTooltip);
        this.tooltipMouseClickHandler = this.tooltipController.mouseClickHandler.bind(this, null, null);
        this.svgRef = React.createRef();
        this.state = { ...initialState };
    }

    componentWillUnmount() {
        this.tooltipController.unmountTooltip();
        this.setState({ ...initialState });
    }

    renderTooltip = shotPoint => {
        const { renderTooltip } = this.props;
        if (!renderTooltip) {
            return;
        }

        return renderTooltip(shotPoint);
    };

    pointEnter(d, i, event) {
        const { onPointEnter } = this.props;

        clearTimeout(this.fadeTimeout);
        this.tooltipController.mouseEnterHandler(d, i, event);
        onPointEnter?.(d, i, event);
        this.setState({ activeShot: i });
    }

    pointLeave(d, i, event) {
        const { onPointLeave } = this.props;

        this.setState({ activeShot: null });
        this.fadeTimeout = setTimeout(() => {
            this.tooltipController.mouseLeaveHandler(d, i);
            onPointLeave?.(d, i, event);
        }, FADE_TIMEOUT_DELAY);
    }

    onDropPoint = (id, shotMapPosition) => {
        const { oneSide, personalShot, onDropPoint } = this.props;

        const iceRinkPosition = fromShotmapPoint(shotMapPosition, {
            oneSide,
            width: PICTURE_SIZE.width,
            height: PICTURE_SIZE.height,
            personalShot
        });
        !!onDropPoint && onDropPoint(id, iceRinkPosition);
    };

    renderPoints() {
        const { shots, oneSide, reverted, personalShot, editable } = this.props;
        const {
            data: { minXg, maxXg }
        } = shotXgBoundsSelector(this.props);

        const { activeSubFilters, allJerseysShow, activeShot } = this.state;

        const filteredShots =
            shots?.data?.filter(shot => {
                let willBeShow = false;

                for (const filterName of activeSubFilters) {
                    const filterData = specialFilterTabs.find(({ name }) => name === filterName);

                    const { filterFunc } = filterData;

                    if (filterFunc(shot)) {
                        return true;
                    }
                }

                return willBeShow;
            }) || [];

        const shotsPointsData = filteredShots.map(shot => {
            const stStroke = getStStroke(shot, oneSide);
            const { x, y } = toShotmapPoint(shot, {
                oneSide,
                width: PICTURE_SIZE.width,
                height: PICTURE_SIZE.height,
                personalShot
            });
            const r = circleR(shot.xgValue, minXg, maxXg);

            const { inUserTeam } = shot;

            const teamShot = {
                ...shot,
                inUserTeam: personalShot || inUserTeam
            };

            return {
                ...teamShot,
                elementId: shot.shotId,
                transform: `translate(${x},${y})`,
                r,
                stroke: typeof inUserTeam !== 'boolean' ? 'rgba(0,0,0,0)' : stStroke,
                oneSide,
                reverted,
                onMouseEnter: this.pointEnter.bind(this, shot, shot.shotId),
                onMouseLeave: this.pointLeave.bind(this, shot, shot.shotId),
                onClick: this.tooltipController.mouseClickHandler.bind(this, teamShot, teamShot.shotId),
                transparentShot: !!activeShot && activeShot !== shot.id
            };
        });

        /*At svg first element will be render upper than second. We compare them by goal entity.*/
        const sortedByGoals = [...(shotsPointsData ?? [])].sort((dataA, dataB) => +dataA.goal - +dataB.goal);

        return (
            <ChartContainer
                onDropPoint={this.onDropPoint}
                editable={editable}
                svgRef={this.svgRef}
                type="shots"
                id="shotmap"
                data={sortedByGoals}
                allJerseysShow={allJerseysShow}
            />
        );
    }

    mouseEnterHandler = (d, i, event) => {
        this.props.showTooltip(`#shot-${i}`, event.target, this.renderTooltip(d));
    };

    mouseLeaveHandler = (d, i) => {
        this.props.hideTooltip(`#shot-${i}`);
    };

    handleSelectSubFilters = subFilterName => {
        const { activeSubFilters } = this.state;

        const updatedSubFilters = activeSubFilters.includes(subFilterName)
            ? activeSubFilters.filter(el => el !== subFilterName)
            : [...activeSubFilters, subFilterName];

        this.setState({ activeSubFilters: [...updatedSubFilters] });
    };

    handleJerseyVisibility = () => {
        const { allJerseysShow } = this.state;

        this.setState({ allJerseysShow: !allJerseysShow });
    };

    render() {
        const { activeSubFilters, allJerseysShow } = this.state;
        const {
            isReport,
            className,
            shots,
            oneSide,
            height,
            width,
            reverted,
            children,
            specialFiltersIsAvailable = SPECIAL_FILTERS_IS_AVAILABLE,
            jerseysSwitchIsVisible = JERSEYS_SWITCH_IS_VISIBLE
        } = this.props;

        const { processing = false } = shots || {};

        if (processing) {
            return <Loading placeholder />;
        }

        return (
            <HockeyMapWrapper>
                <HockeyMap
                    className={className}
                    oneSide={oneSide}
                    reverted={reverted}
                    maxHeight={height}
                    maxWidth={width}
                    onClick={this.tooltipMouseClickHandler}
                    svgRef={this.svgRef}
                >
                    {children}
                    {this.renderPoints()}
                </HockeyMap>
                {!!specialFiltersIsAvailable && !isReport && (
                    <SpecialFiltersControls
                        specialFilterTabs={specialFilterTabs}
                        activeSubFilters={activeSubFilters}
                        handleSelectSubFilters={this.handleSelectSubFilters}
                        jerseysSwitchIsVisible={jerseysSwitchIsVisible}
                        allJerseysShow={allJerseysShow}
                        handleJerseyVisibility={this.handleJerseyVisibility}
                    />
                )}
            </HockeyMapWrapper>
        );
    }
}

export default connect(
    null,
    {
        showTooltip,
        hideTooltip
    }
)(ShotMap);

ShotMap.propTypes = {
    showTooltip: PropTypes.func.isRequired,
    hideTooltip: PropTypes.func.isRequired,
    editable: PropTypes.bool
};

ShotMap.defaultProps = {
    height: PICTURE_SIZE.height,
    width: PICTURE_SIZE.width,
    editable: false
};
