/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { createSelector } from 'reselect';
import Table from '../../components/table/stateful-data-table';
import TooltipWrapper from '../../containers/tooltip/tooltip-wrapper';

export default class TableWithTooltip extends Component {
    mapDataToPropsSelector = createSelector(
        props => props.columns,
        props => props.tableContext,
        (columns, context) =>
            function mapDataToProps(data) {
                const tooltipProps = columns[data.index].tooltip;

                return tooltipProps && tooltipProps(data.row, data.index, null, context);
            }
    );

    ignoredColumnsSelector = createSelector(
        props => props.columns,
        columns => columns.map((col, index) => (col?.tooltip ? -1 : index)).filter(index => index !== -1)
    );

    columnsSelector = createSelector(
        props => props.columns,
        props => props.ignore || this.ignoredColumnsSelector(props),
        (columns, ignore) =>
            columns.map((column, index) => {
                if (ignore.indexOf(index) !== -1) {
                    return column;
                }
                const { render } = column;
                return {
                    ...column,
                    render: (row, i, j, context) => (
                        <div
                            key={`index_${index}_${row && row.id ? row.id : 'id'}`}
                            onClick={this.tooltipHandler('click', { row, index, context }, i)}
                            onMouseEnter={this.tooltipHandler('hover', { row, index, context }, i)}
                            onMouseLeave={this.tooltipHandler('leave', { row, index, context }, i)}
                        >
                            {render(row, i, j, context)}
                        </div>
                    )
                };
            })
    );

    tooltipHandler = (handlerType, data, i) => e => {
        if (!this.wrapper) return;
        const tooltip = this.wrapper;
        tooltip[handlerType](data, data.index, i, e);
    };

    outClick = (...args) => {
        const { onRowClick } = this.props;
        if (onRowClick) onRowClick(...args);
        if (!this.wrapper) return;
        const tooltip = this.wrapper;
        tooltip.out();
    };

    render() {
        // noinspection JSUnusedLocalSymbols (use like omit)
        const {
            mapDataToProps = this.mapDataToPropsSelector(this.props),
            ignore,
            columns,
            onRowClick,
            ...rest
        } = this.props;

        const wrappedColumns = this.columnsSelector(this.props);

        return (
            <TooltipWrapper ref={c => (this.wrapper = c)} mapDataToProps={mapDataToProps}>
                <Table onRowClick={this.outClick} columns={wrappedColumns} {...rest} />
            </TooltipWrapper>
        );
    }
}
