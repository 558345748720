import React, { Component } from 'react';
import TooltipController from './tooltip-controller';
import { connect } from 'react-redux';
import TooltipTemplate from './player-stat-content';
import { actions as tooltipsActions } from './module';
import { FullSizeOverFlowableWrapper } from '../../components/table/table-styled-components';

const { showTooltip, hideTooltip } = tooltipsActions;

let generateId;

(() => {
    let id = 0;

    generateId = () => `tooltip-id-${id++}`;
})();

class TooltipWrapper extends Component {
    UNSAFE_componentWillMount() {
        this.tooltipController = new TooltipController();
        this.tooltipController.setMarker(generateId());
        this.tooltipController.setShowTooltip(this.props.showTooltip);
        this.tooltipController.setHideTooltip(this.props.hideTooltip);
        this.tooltipController.setRenderTooltip((...args) => (
            <TooltipTemplate {...this.props.mapDataToProps(...args)} />
        ));
    }

    componentWillUnmount() {
        this.tooltipController.unmountTooltip();
    }

    click = (data, index, group, event) => {
        this.tooltipController.mouseClickHandler(data, `${group || ''}-${index}`, event);
    };
    out = () => {
        this.tooltipController.mouseClickHandler(null, null, null);
    };
    hover = (data, index, group, event) => {
        this.tooltipController.mouseEnterHandler(data, `${group || ''}-${index}`, event);
    };
    leave = (data, index, group) => {
        this.tooltipController.mouseLeaveHandler(data, `${group || ''}-${index}`);
    };

    render() {
        return <FullSizeOverFlowableWrapper>{this.props.children}</FullSizeOverFlowableWrapper>;
    }
}

TooltipWrapper = connect(
    null,
    {
        showTooltip,
        hideTooltip
    },
    null,
    { forwardRef: true }
)(TooltipWrapper);

export { TooltipWrapper as default };
