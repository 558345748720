import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import styles from './table.module.css';

const cx = classNames.bind(styles);

export const Th = ({ className, sorted, align = 'center', fixedWidth, dummyColumn, important, children, ...attrs }) => {
    const thClasses = cx(className, 'th', { [`align-${align}`]: true, important, fixedWidth, dummyColumn });
    return (
        <th {...attrs} className={thClasses} style={{ fontFamily: 'Open Sans, sans-serif' }}>
            {children}
        </th>
    );
};

export const ExternalTh = ({ className, sorted, children, ...rest }) => {
    const thClasses = cx(className, 'externalTh');

    return (
        <div {...rest} className={thClasses}>
            {children}
            {sorted === 'asc' && <span>&#9660;</span>}
            {sorted === 'desc' && <span>&#9650;</span>}
        </div>
    );
};

export const Td = ({ className, align = 'center', verticalCenter, important, ...attrs }) => {
    const tdClasses = cx(className, { [`align-${align}`]: true, verticalCenter: verticalCenter, important: important });
    return <td {...attrs} className={tdClasses} />;
};

export const Tr = ({ className, selected, hovered, disabled, innerRef, ...attrs }) => {
    const trClasses = cx(className, { selected, hovered, disabled });
    return <tr ref={innerRef} {...attrs} className={trClasses} />;
};

export default class Table extends PureComponent {
    render() {
        const { className, tableRef, ...attrs } = this.props;
        const tableClasses = cx(className, 'table');
        return <table {...attrs} ref={tableRef} className={tableClasses} />;
    }
}
